import { GET_ALL_HOSTEL_FEES_RECEIPT_INFO_BY_RECEIPT_NO } from "../../actions/masters/types";

const initialState = [];

export const hostelFeesReceiptInfo = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_HOSTEL_FEES_RECEIPT_INFO_BY_RECEIPT_NO:
            return Array.isArray(action.payload) ? action.payload : state;
        default:
            return state;
    }
};