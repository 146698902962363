import {
  GET_ALL_FLOOR_MST,
  GET_ALL_ASSET_MST,
  GET_ALL_AMENITIES_MST
} from "../../actions/hostel/types";

const initialState = [];

export const floorMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FLOOR_MST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
export const assetMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ASSET_MST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const amenitiesMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_AMENITIES_MST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
