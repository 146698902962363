export const UPDATE_DATA = "update_data";
export const GET_ALL_PAYROLL_HEAD = "get_all_payroll_head";
export const GET_ALL_PAYROLL_SLAB = "get_all_payroll_slab";
export const GET_ALL_LEAVE_TYPE_MASTER = "get_all_leave_type_master";
export const GET_USER_TYPE = "get_user_type";
export const GET_ALL_LEAVE_CONFIGURATION = "get_all_leave_configuration";
export const GET_ALL_PAYROLL_CATEGORY = "get_all_payroll_category";
export const GET_ALL_PAYROLL_PAY_TYPE = "get_all_payroll_pay_type";
export const GET_ALL_STAFF_ATTENDANCE = "get_all_staff_attendance";
export const GET_ALL_PAYROLL_SHIFT = "get_all_payroll_shift";
export const GET_PAYROLL_CONTENT_TYPE = "get_all_payroll_content_type";
export const GET_PAYROLL_CONTENT_USER_TYPE =
  "get_all_payroll_content_user_type";

export const GET_ALL_PAYROLL_FORMULA = "get_all_payroll_formula";
export const GET_ALL_PAYROLL_STRUCTURE = "get_all_payroll_structure";
export const GET_ALL_LEAVE_APPLICATION = "get_all_payroll_leave_application";
export const GET_STAFF_WEEK_OFF_MASTER = "get_staff_week_off_master";
export const GET_ALL_EMP_LIST_FOR_LEAVE_APPLICATION =
  "get_all_emp_list_for_leave_application";
export const GET_ALL_LEAVE_STATUS = "get_all_leave_status";
export const GET_STAFF_ATTENDANCE_IMPORT_HISTORY =
  "get_all_staff_attendance_import_history";
export const IMPORT_STAFF_ATTENDANCE_RESPONSES =
  "import_staff_attendance_response";
export const STAFF_ATTENDANCE_MONTHLY_REPORT_INFO =
  "get_staff_attendance_monthly_report_information";
