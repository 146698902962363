import { combineReducers } from "redux";

import {
  workTypeMasterInfo,
  compRqstPermitAllocationInfo,
  usersListForRqstPermit,
  requestPriorityInfo,
  requestSourcesList,
  compReqByUserTypes,
  requestStatusInfo,
  studentsInfoForReqMgmt,
  clientOrgInfoForReqMgmt,
  regstComplaintRequestMstInfo,
  compReqDepartmentInfo,
  allotedDeptCompRequestDetails,
  allotedDeptReqHistory,
  assignedReqToUsersInfo,
  updatedReqStatusHistoryByUsers
} from "./complaintRequestMgmt";

export default combineReducers({
  workTypeMasterInfo,
  compRqstPermitAllocationInfo,
  usersListForRqstPermit,
  requestPriorityInfo,
  requestSourcesList,
  compReqByUserTypes,
  requestStatusInfo,
  studentsInfoForReqMgmt,
  clientOrgInfoForReqMgmt,
  regstComplaintRequestMstInfo,
  compReqDepartmentInfo,
  allotedDeptCompRequestDetails,
  allotedDeptReqHistory,
  assignedReqToUsersInfo,
  updatedReqStatusHistoryByUsers
});
