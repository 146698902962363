import { GET_CLIENT_SETTINGS_SMS_PROVIDER } from "../../actions/configurations/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_SETTINGS_SMS_PROVIDER:
    //   return action.payload;
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
