import { GET_SUBJECT_ALLOTED_BY_FACULTYID } from "../../actions/attendance/types";

const initialState = [];

export const subjectAllotedByFacultyId = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBJECT_ALLOTED_BY_FACULTYID:
      return action.payload;
    default:
      return state;
  }
};
