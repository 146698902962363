import { combineReducers } from "redux";
import {
  mealTypeMaster,
  messMaster,
  penaltyConfiguration,
  messFeesConfiguration,
  messFeesTypes,
  studStaffInfo,
  acadYearInfo,
  feesDetails,
  messReceiptInfo,
  organization_Info
} from "./messMgmt";

export default combineReducers({
  mealTypeMaster,
  messMaster,
  penaltyConfiguration,
  messFeesConfiguration,
  messFeesTypes,
  studStaffInfo,
  acadYearInfo,
  feesDetails,
  messReceiptInfo,
  organization_Info
});
