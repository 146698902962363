import {
  GET_RegistrationReregistration,
  GET_STUDENTS_BY_USERID,
  GET_REGISTRATION_SUBJECT,
  GET_SEMESTERS_FOR_PROGRAM,
  GET_ELECTIVE_REGISTRATION_SUBJECT,
  GET_REGISTRATION_CONFIGURATION,
  GET_PARTIALREGISTRATION_SUBJECT,
  GET_STUDENT_SUBJECT_REGISTER_DATA_BY_SUBJECT_OR_STUDENT_WISE,
  GET_STUDENT_REGISTRATION_COLLECTION,
  GET_RE_REGISTRATION_SUBJECT
} from "../../actions/types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RegistrationReregistration:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function StudentInformationByUserId(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENTS_BY_USERID:
      return action.payload;
    default:
      return state;
  }
}

export function RegistrationSubjectInfo(state = initialState, action) {
  switch (action.type) {
    case GET_REGISTRATION_SUBJECT:
      return action.payload;
    default:
      return state;
  }
}

export function getRegistrationElectiveSubjectInfo(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ELECTIVE_REGISTRATION_SUBJECT:
      return action.payload;
    default:
      return state;
  }
}

export function getReRegistrationSubjectInfo(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_RE_REGISTRATION_SUBJECT:
      return action.payload;
    default:
      return state;
  }
}

export function RegistrationConfiguration(state = initialState, action) {
  switch (action.type) {
    case GET_REGISTRATION_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
}

export function PartialRegisterSubject(state = initialState, action) {
  switch (action.type) {
    case GET_PARTIALREGISTRATION_SUBJECT:
      return action.payload;
    default:
      return state;
  }
}

export function getStudentSubjectRegisterDataBySubjectOrStudentWise(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_REGISTER_DATA_BY_SUBJECT_OR_STUDENT_WISE:
      return action.payload;
    default:
      return state;
  }
}

//Created By : Bindesh Prajapati
export function getStudentRegistrationCollection(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_REGISTRATION_COLLECTION:
      return action.payload;
    default:
      return state;
  }
}

export function getAllSemestersOfUser(state = initialState, action) {
  switch (action.type) {
    case GET_SEMESTERS_FOR_PROGRAM:
      return action.payload;
    default:
      return state;
  }
}
