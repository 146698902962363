import { combineReducers } from "redux";
import {
  allotedSubjectToFacultyAssgmt,
  facultyInfoForAssgmt,
  assignmentMstEntryInfo,
  assignmentInfoForStudents,
  studInfoByIdAssgnmt,
  studentAssignmtAnswersInfo,
  assignmentStatusInfo,
  assignmtReportInfoBySubjWise,
  assignmtDataByFacultySubjId,
  studAssignmtAnsHistoryInfo
} from "./assignmentMaster";

export default combineReducers({
  allotedSubjectToFacultyAssgmt,
  facultyInfoForAssgmt,
  assignmentMstEntryInfo,
  assignmentInfoForStudents,
  studInfoByIdAssgnmt,
  studentAssignmtAnswersInfo,
  assignmentStatusInfo,
  assignmtReportInfoBySubjWise,
  assignmtDataByFacultySubjId,
  studAssignmtAnsHistoryInfo
});
