export const UPDATE_DATA = "update_data";
export const GET_ALL_COMMON = "get_all_common";
export const GET_ALL_SYLLABUS = "get_all_syllabus";
export const GET_ALL_SECTION = "get_all_section";
export const GET_ALL_ADMISSION_FORM_LIST = "get_all_admission_form_list;";
export const GET_COUNTRY_STATE_CITY = "get_country_state_city";
export const GET_USERDETAILS_BY_USERID = "get_userdetails_by_userid";
export const GET_QUALIFICATION_CONFIG_BY_FORMID =
  "get_qualification_config_by_formid";
export const GET_ALL_DOCUMENTCHECKLIST_BY_FORMID =
  "get_all_documentchecklist_by_formid";
export const GET_ALL_FORM_CONFIGURATION_BY_FORMID =
  "get_all_form_configuration_by_formid";
export const GET_ALL_SUBJECTGROUP = "get_all_subjectgroup";

export const GET_ALL_ACHIEVEMENTS = "get_all_achievements";
export const GET_ALL_PROFILE_LANGUAGES = "get_all_profile_languages";
export const GET_ACHIEVEMENTS_BY_STUDENTID = "get_achievements_by_studentid";
export const GET_LANGUAGES_BY_STUDENTID = "get_languages_by_studentid";
export const GET_ALL_QUOTA = "get_all_quota";
export const GET_ALL_ADMISSION_PROGRAMS = "get_all_admission_programs";

export const GET_EXPERIENCEDETAILS_BY_STUDENTID =
  "get_experiencedetails_by_studentid";
export const GET_OTHERDETAILS_BY_STUDENTID = "get_otherdetails_by_studentid";
export const GET_RESEARCHDETAILS_BY_STUDENTID =
  "get_researchdetails_by_studentid";
export const GET_WORKINGDETAILS_BY_STUDENTID =
  "get_workingdetails_by_studentid";
export const GET_ALL_ACADEMICQUOTA = "get_all_academicquota";
export const GET_VERIFYDETAILS_BY_STUDENTID = "get_verifydetails_by_studentid";
export const GET_TOTALFORMFEES_BY_STUDENTID = "get_totalformfees_by_studentid";

export const USER_REGISTRATION_DATA = "user_registration_data";

export const GET_ALL_APPLYTHROUGH = "get_all_applythrough";
export const GET_ALL_BOARD = "get_all_board";
export const GET_ALL_MARKSTYPE = "get_all_markstype";

export const GET_ACPC_DETAILS_BY_STUDENTID = "get_acpc_details_by_studentid";
export const GET_DOCUMENT_VERIFICATION_STATUS_BY_STUDENT =
  "get_document_verification_status_by_student";
export const GET_COUNSELLING_STUDENT_BY_ID = "get_counselling_student_by_id";
