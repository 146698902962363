import { GET_USER_TYPE } from "../../actions/types";

const initialState = [];

export const getUserType = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_TYPE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
