import { FACULTY_ATTENDANCE_OVERVIEW_DATA } from "../../actions/masters/types";

const initialState = [];

export const facultyAttendanceOverviewData = (state = initialState, action) => {
  switch (action.type) {
    case FACULTY_ATTENDANCE_OVERVIEW_DATA:
      return action.payload;
    default:
      return state;
  }
};
