import * as AttTypes from "../../actions/attendance/types";

const initialState = {
  holidays:[]
};

export const getattendanceDates = (state = initialState, action) => {
  switch (action.type) {
    case AttTypes.GET_MONTH_DATES:
      return action.payload;
    default:
      return state;
  }
};

export const admitCardEligibilityDataByStudent = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AttTypes.GET_ADMITCARDELIGIBILITY_DATA_BYSTUDENT:
      const { data } = action;
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};

export const admitCardEligibilityData = (state = initialState, action) => {
  switch (action.type) {
    case AttTypes.GET_ADMITCARDELIGIBILITY_DATA:
      const { data } = action;
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};

export const admitCardEligibilityTranDataByMaster = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AttTypes.GET_ADMITCARDELIGIBILITY_TRANDATA_BYMASTER:
      const { data } = action;
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};

export const calendarSummaryDetails = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AttTypes.GET_ATTENDANCE_SUMMARY_CALENDAR:
      const { data } = action;
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};
