import { combineReducers } from "redux";
import {
  membershipTypeMaster,
  publicationTypeMaster,
  onlineJobRegstMemberInfo,
  jobAppPersonalInfo,
  jobAppPresentAddressInfo,
  jobAppPermanentAddressInfo,
  jobAppLanguagesKnownInfo,
  jobAppFamilyInfo,
  jobAppQualificationInfo,
  jobAppWorkExperienceInfo,
  jobAppRelativesInfo,
  jobAppCouncilRegstInfo,
  jobAppMembershipInfo,
  jobAppAwardsInfo,
  jobAppPublicationsInfo,
  jobInterviewStatusList,
  jobAppNotificationInfo
} from "./onlineJobApplication";

export default combineReducers({
  membershipTypeMaster,
  publicationTypeMaster,
  onlineJobRegstMemberInfo,
  jobAppPersonalInfo,
  jobAppPresentAddressInfo,
  jobAppPermanentAddressInfo,
  jobAppLanguagesKnownInfo,
  jobAppFamilyInfo,
  jobAppQualificationInfo,
  jobAppWorkExperienceInfo,
  jobAppRelativesInfo,
  jobAppCouncilRegstInfo,
  jobAppMembershipInfo,
  jobAppAwardsInfo,
  jobAppPublicationsInfo,
  jobInterviewStatusList,
  jobAppNotificationInfo
});
