import {
  GET_ALL_HOSTEL_MST_INFO,
  GET_ALL_HOSTEL_TYPES,
  GET_PENALTY_CONFIG_INFO,
  GET_USERS_FOR_HOSTEL_MST
} from "../../actions/hostel/types";

const initialState = [];

export const hostelMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HOSTEL_MST_INFO:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const hostelTypes = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HOSTEL_TYPES:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const penaltyConfigInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_PENALTY_CONFIG_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const usersInfoForHostelMst = function(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_FOR_HOSTEL_MST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
