import { GET_COUNTRYSTATECITY } from "../../actions/commons/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRYSTATECITY:
      return action.payload;
    default:
      return state;
  }
}
