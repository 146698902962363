import {
  GET_ALL_OTHER_FEES_CONFIG_INFO,
  GET_ALL_FEES_HEAD_TYPE_INFO
} from "../../actions/masters/types";

const initialState = [];

export const otherFeesConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_OTHER_FEES_CONFIG_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const feesHeadTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FEES_HEAD_TYPE_INFO:
      return action.payload;
    default:
      return state;
  }
};
