import { GET_ACADEMIC_YEAR } from "../../actions/global/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACADEMIC_YEAR:
      return action.payload;
    default:
      return state;
  }
}
