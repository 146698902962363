export const UPDATE_DATA = "update_data";
export const GET_ALL_WORK_TYPE_MST_FOR_REQUEST_MGMT =
  "get_all_work_type_master_for_request_mgmt";
export const GET_ALL_COMPLAINT_REQUEST_PERMISSION_ALLOCATION_INFO =
  "get_all_compliant_request_permission_allocation_info";
export const GET_ALL_USERS_LIST_FOR_RQST_PERMISSION =
  "get_all_users_list_for_request_permission";
export const GET_ALL_REQUEST_PRIORITY_INFO = "get_all_request_priority_info";
export const GET_ALL_COMPLAINT_REQUEST_BY_USER_TYPES =
  "get_all_request_by_user_types";
export const GET_ALL_REQUEST_SOURCES_LIST = "get_all_request_sources_list";
export const GET_ALL_REQUEST_STATUS_LIST = "get_all_request_status_list";
export const GET_ALL_STUDENT_INFO_FOR_REQUEST_MGMT =
  "get_all_student_info_for_request_mgmt";
export const GET_ALL_CLIENT_ORG_INFO_FOR_REQUEST_MGMT =
  "get_all_client_org_info_for_request_mgmt";
export const GET_ALL_REGISTERED_COMPLAINTS_REQUESTS_INFO =
  "get_all_registered_complaints_requests_information";
export const GET_DEPARTMENT_INFO_FOR_RQST_MGMT =
  "get_all_allocated_department_info_for_request_mgmt";
export const GET_ALLOTED_DEPT_COMPLAINTS_REQUESTS =
  "get_all_alloted_department_complaints_requests";
export const GET_ALLOTED_DEPT_RQST_HISTORY =
  "get_alloted_department_rqst_history";
export const GET_ALL_ASSIGNED_COMP_RQST_TO_USERS_INFO =
  "get_all_assigned_complaint_request_to_users_info";
export const GET_ALL_UPDATED_REQUEST_STATUS_HISTORY_BY_USERS =
  "get_all_updated_request_status_history_by_users";
