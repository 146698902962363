import { GET_SUBJECTIVE_REMARKS } from "../../actions/exams/types";

const initialState = [];

export const getSubjectiveRemarks = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECTIVE_REMARKS:
      return action.payload;
    default:
      return state;
  }
};
