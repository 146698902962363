import {
  GET_ID_EXAM_MASTER,
  GET_ID_EXAM_BY_SEMESTER_TYPE,
  GET_ID_PAPER_CREATION,
  GET_ID_SUBJECT_EXAMPAPER_OF_PAPER_MARKS,
  GET_ALL_STUDENTS_FOR_MARKS_ENTRY,
  GET_ALL_GRADE_FOR_SPCASE,
} from "../../actions/exams/types";

const initialState = [];

/*ID Exam master - page */
export const getIdExamMasterData = function (state = initialState, action) {
  switch (action.type) {
    case GET_ID_EXAM_MASTER:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

/*ID Paper master - page */
export const getIdExamDataBySemester = function (state = initialState, action) {
  switch (action.type) {
    case GET_ID_EXAM_BY_SEMESTER_TYPE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getAllIdPaperData = function (state = initialState, action) {
  switch (action.type) {
    case GET_ID_PAPER_CREATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const deletePaperFromGrid = function (state = 0, action) {
  switch (action.type) {
    case "deletePaperFromGrid":
      return action.payload;
    default:
      return state;
  }
};

/*ID Paper Marks Entry */
export const getAllDropDownDataOfIDPaperMarks = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ID_SUBJECT_EXAMPAPER_OF_PAPER_MARKS:
      return Array.isArray(action.payload) ? action.payload : [];
    default:
      return state;
  }
};

export const getAllStudentsForIDMarksEntry = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_STUDENTS_FOR_MARKS_ENTRY:
      return Array.isArray(action.payload) ? action.payload : [];
    default:
      return state;
  }
};

export const getAllGradeForSPCase = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_GRADE_FOR_SPCASE:
      return Array.isArray(action.payload) ? action.payload : [];
    default:
      return state;
  }
};
