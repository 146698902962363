import { GET_ALL_REPORTCARD_RESULT } from "../../actions/exams/types";

const initialState = [];

export const getAllReportCardData = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REPORTCARD_RESULT:
      return action.payload;
    default:
      return state;
  }
};
