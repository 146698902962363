import {
  GET_ALL_OTHER_FEES_TRANSACTION_INFO,
  GET_ALL_OTHER_FEES_TRANSACTION_PAYMENT_HISTORY_BY_STUD_ID
} from "../../actions/masters/types";

const initialState = [];

export const otherFeesTransactionInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_OTHER_FEES_TRANSACTION_INFO:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const otherFeesTransactionPaymentHistoryByStudIdInfo = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_ALL_OTHER_FEES_TRANSACTION_PAYMENT_HISTORY_BY_STUD_ID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
