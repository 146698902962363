import { GET_SUBJECTGROUP_BY_SUBJECTGROUPID } from "../../actions/types";
const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECTGROUP_BY_SUBJECTGROUPID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
