import {
  GET_EXAM_BY_COMBINATION_OR_EXAMTYPE,
  GET_PAPER_BY_COMBINATION_OR_EXAMTYPE_OR_EXAMID,
  GET_DATA_BY_COMBINATION_FOR_LOCK_OR_UNLOCK
} from "../../actions/exams/types";

const initialState = [];

export const getExamByCombinationOrExamType = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_EXAM_BY_COMBINATION_OR_EXAMTYPE:
      return action.payload;
    default:
      return state;
  }
};

export const getPaperByCombinationOrExamTypeOrExamId = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_PAPER_BY_COMBINATION_OR_EXAMTYPE_OR_EXAMID:
      return action.payload;
    default:
      return state;
  }
};

export const getDataByCombinationForLockOrUnlock = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_DATA_BY_COMBINATION_FOR_LOCK_OR_UNLOCK:
      return action.payload;
    default:
      return state;
  }
};
