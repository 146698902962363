import {
  GET_ALL_GENDER_LIST_FOR_FORM,
  GET_ALL_COUNTRY_STATE_CITY,
  GET_ORGWISE_PROGRAMS,
  GET_APPLIED_STUDENT_DETAILS
} from "../../actions/types";

const initialState = [];

export const gender_List = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GENDER_LIST_FOR_FORM:
      return action.payload;
    default:
      return state;
  }
};

export const countriesStatesCities = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUNTRY_STATE_CITY:
      return action.payload;
    default:
      return state;
  }
};

export const orgWisePrograms = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGWISE_PROGRAMS:
      return action.payload;
    default:
      return state;
  }
};

export const convocationFormDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLIED_STUDENT_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
