export const GET_ALL_STUDENTDOCUMENT = "get_all_studentdocument";
export const GET_ALL_DOCUMENTTYPE = "get_all_documenttype";
export const GET_STUDENT_WEEK_OFF_MASTER = "get_student_week_off_master";
export const GET_STUDENT_BATCH_MASTER = "get_student_batch_master";
export const GET_STUDENT_BATCH_ALLOCATION = "get_student_batch_allocation";
export const GET_STUDENT_BATCH_SUBJECT_WISE = "get_student_batch_subject_wise";
export const GET_ALL_STUDENT_FAMILY_DETAILS = "get_all_student_family_details";
export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const GET_ALL_STUDENT_CONTACT_INFO = "get_all_student_contact_info";
export const GET_STUDENT_PREVIOUS_SCHOOL_INFO =
  "get_student_previous_school_info";
export const GET_STUDENT_ACHIEVEMENTS = "get_student_achievements";
export const GET_ALL_LANGUAGES = "get_all_languages";
export const GET_STUDENT_KNOWN_LANGUAGES = "get_student_known_languages";
export const GET_ALL_ACHIEVEMENTS = "get_all_achievements";
export const YEAR_END_PROCESS_STUDENT_RESPONSE =
  "year_end_process_student_master";
export const GET_STUDENT_FEES_CODE = "get_student_fees_code";
export const GET_STUDENT_STATUS_HISTORY = "get_student_status_history";
export const GET_STUDENT_ACADEMICDETAILS_BY_ENROLLMENTNO =
  "GET_STUDENT_ACADEMICDETAILS_BY_ENROLLMENTNO";
export const GET_STUDENTS_FORIDCARD = "get_students_forIdCard";
