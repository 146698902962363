export const GET_MONTH_DATES = "get_month_dates";
export const GET_SUBJECT_ALLOTED_BY_FACULTYID =
  "get_subject_alloted_by_facultyid";
export const GET_SUBJECT_ALLOTED_BY_MSTID = "get_subject_alloted_by_mstid";
export const GET_SUBJECTS_ALLOTED_TO_PROGRAMS =
  "get_subjects_alloted_to_programs";
export const GET_ID_SUBJECTS_ALLOTED_TO_PROGRAMS =
  "GET_ID_SUBJECTS_ALLOTED_TO_PROGRAMS";
export const UPDATE_DATA = "update_data";
export const GET_STUDENTS_BY_DIVID = "get_students_by_divid";
export const GET_ATTENDANCE_BY_DATE_WISE = "get_attendance_by_date_wise";
export const TIME_OVERLAP = "time_overlap";
export const GET_HOLIDAY_AGAINST_DATE = "get_holiday_against_date";
export const GET_ATTENDANCE_LOCKING_CONFIG = "get_attendance_locking_config";
export const GET_WEEKOFF_AGAINST_DATE = "get_weekoff_against_date";
export const GET_SEMESTERDATE_BY_ACADTRANID = "get_semesterdate_by_acadtranid";
export const GET_MONTHS_BY_SEMID = "get_months_by_semid";
export const GET_MONTHS_BY_SEMID_ID = "GET_MONTHS_BY_SEMID_ID";
export const GET_ATTENDANCE_REPORT_BY_COMBINATION =
  "get_attendance_report_by_combination";

export const GET_ATTENDANCE_REPORT_BY_COMBINATION_SUBJECTWISE =
  "GET_ATTENDANCE_REPORT_BY_COMBINATION_SUBJECTWISE";

export const GET_IDSUBJECT_WITH_FACULTY_BY_SEMTYPE =
  "get_idsubject_with_faculty_by_semtype";
export const GET_IDSUBJECT_FACULTY_DETAIL_BY_MSTID =
  "get_idsubject_faculty_detail_by_mstid";
export const GET_IDSUBJECT_ATTENDANCE_BY_DATE_WISE =
  "get_idsubject_attendance_by_date_wise";
export const GET_STUDENTS_BY_COMBINATION = "get_students_by_combination";
export const ID_SUBJECT_TIME_OVERLAP = "id_subject_time_overlap";

export const GET_FACULTY_LECTURE_ATTENDANCE_REPORT =
  "get_faculty_lecture_attendance_report";

export const GET_CONSOLIDATED_ATTENDANCE_REPORT_BY_COMBINATION =
  "GET_CONSOLIDATED_ATTENDANCE_REPORT_BY_COMBINATION";

export const GET_SUMMARY_ATTENDANCE_REPORT = "GET_SUMMARY_ATTENDANCE_REPORT";

export const GET_STUDENT_EXAM_ELIGIBILITY_REPORT =
  "GET_STUDENT_EXAM_ELIGIBILITY_REPORT";

export const GET_STUDENTS_BY_ORG = "GET_STUDENTS_BY_ORG";

export const GET_SUBJECT_LECTURE_ATTENDANCE_REPORT =
  "get_subject_lecture_attendance_report";

export const GET_FACULTY_LECTURES_SUMMARY_REPORT =
  "get_faculty_lectures_summary_report";

export const GET_SUBJECT_LECTURES_SUMMARY_REPORT =
  "get_subject_lectures_summary_report";

export const GET_ACADEMIC_YEAR_STARTDATE_ENDDATE =
  "get_academic_year_startdate_enddate";

export const GET_ADMITCARDELIGIBILITY_DATA_BYSTUDENT =
  "GET_ADMITCARDELIGIBILITY_DATA_BYSTUDENT";

export const GET_ADMITCARDELIGIBILITY_DATA = "GET_ADMITCARDELIGIBILITY_DATA";

export const GET_ADMITCARDELIGIBILITY_TRANDATA_BYMASTER =
  "GET_ADMITCARDELIGIBILITY_TRANDATA_BYMASTER";

export const GET_ATTENDANCE_SUMMARY_CALENDAR =
  "GET_ATTENDANCE_SUMMARY_CALENDAR";

export const GET_ALL_ACAD_COMB_BY_ACYR_ORGID =
  "get_all_academic_comb_by_acadYr_orgId";
export const GET_ALL_CLASS_FACULTY_INFO = "get_all_faculty_info";
export const GET_ALLOTED_ACADCOMB_TO_FACULTY_INFO =
  "get_all_alloted_acadcomb_to_faculty_info";
export const GET_ALLOTED_CLASS_FACULTY_INFO_BY_MST_ID =
  "get_alloted_class_facult_info_by_MstId";
export const GET_SCHOOL_STUD_ATTENDANCE_INFO_BY_DATE_WISE =
  "get_all_school_stud_attendance_info_by_date_wise";
export const GET_SCHOOL_STUD_LIST_BY_DIV_DATE_WISE =
  "get_school_stud_list_by_div_date_wise";
export const GET_SCHOOL_STUD_ATT_SUMMARY_FOR_CALENDER =
  "get_school_student_attendance_summary_for_calender";
export const GET_SCHOOL_STUD_LEAVE_TYPE_MST_INFO =
  "get_school_stud_leave_type_mst_info";
export const GET_SCHOOL_STUD_ATT_REPORT_BY_MONTH_WISE =
  "get_school_student_attendance_report_by_month_wise";
export const IMPORT_SCHOOL_STUD_ATTENDANCE_SUMMARY_RESPONSE =
  "import_school_stud_attendance_summary_response";
export const IMPORT_STUD_PREV_SCHOOL_ATTENDANCE_RESPONSE =
  "import_stud_previous_school_attendance_response";
export const GET_SCHOOL_STUD_ATTEN_IMPORT_HISTORY =
  "get_school_stud_attendance_import_history";
export const GET_STUD_PREV_SCHOOL_ATTEN_IMPORT_HISTORY =
  "get_stud_previous_school_attendance_import_history";
export const GET_ALL_UPLOADED_SCHOOL_ATT_SUMMARY_INFO =
  "get_all_uploaded_school_att_summary_info";
export const GET_ALL_UPLOADED_PREVIOUS_SCHOOL_ATT_SUMM =
  "get_all_uploaded_previous_school_attendance_summary";
