import {
  GET_ALLOTED_ACADCOMB_SUBJECTS_TO_FACULTY_FOR_ASSNMT_INFO,
  GET_ALL_FACULTY_INFO_FOR_ASSGNMT_MST,
  GET_ALL_ASSIGNMENT_MASTER_INFO,
  GET_ASSIGNMENT_DETAILS_GIVEN_TO_STUDENTS,
  GET_STUDENT_DETAILS_FOR_ASSIGNMT,
  GET_ALL_STUDENT_ASSIGNMENT_ANSWERS_DETAILS,
  GET_ALL_ASSIGNMENT_STATUS_INFO,
  GET_ALL_ASSIGNMENT_REPORT_INFO_BY_SUBJECT_STATUS_WISE,
  GET_ASSIGNMT_DATA_BY_FACULTY_SUBJECT_ID,
  GET_STUDENT_ASSIGNMT_ANSWERS_HISTORY_INFO
} from "../../actions/types";

const initialState = [];

export const allotedSubjectToFacultyAssgmt = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOTED_ACADCOMB_SUBJECTS_TO_FACULTY_FOR_ASSNMT_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const facultyInfoForAssgmt = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FACULTY_INFO_FOR_ASSGNMT_MST:
      return action.payload;
    default:
      return state;
  }
};

export const assignmentMstEntryInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ASSIGNMENT_MASTER_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const assignmentInfoForStudents = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGNMENT_DETAILS_GIVEN_TO_STUDENTS:
      return action.payload;
    default:
      return state;
  }
};

export const studInfoByIdAssgnmt = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_DETAILS_FOR_ASSIGNMT:
      return action.payload;
    default:
      return state;
  }
};

export const studentAssignmtAnswersInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENT_ASSIGNMENT_ANSWERS_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const assignmentStatusInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ASSIGNMENT_STATUS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const assignmtReportInfoBySubjWise = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ASSIGNMENT_REPORT_INFO_BY_SUBJECT_STATUS_WISE:
      return action.payload;
    default:
      return state;
  }
};

export const assignmtDataByFacultySubjId = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGNMT_DATA_BY_FACULTY_SUBJECT_ID:
      return action.payload;
    default:
      return state;
  }
};

export const studAssignmtAnsHistoryInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_ASSIGNMT_ANSWERS_HISTORY_INFO:
      return action.payload;
    default:
      return state;
  }
};
