import { GET_SCHOLASTIC_COSCHOLASTIC_ACTIVITY_MASTER } from "../../actions/types";

let initialState = [];

export const getScholasticCoScholasticActivityMaster = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SCHOLASTIC_COSCHOLASTIC_ACTIVITY_MASTER:
      return action.payload;
    default:
      return state;
  }
};
