import axios from "axios";
import {
  FETCH_USER,
  LOGOUT,
  CHECK_TOKEN,
  UPDATE_DATA,
  //FORGET_PASSWORD,
  USER_DETAIL_OTP_FORGOTPASS,
} from "./types";
import setAuthorizationToken from "../utils/setAuthorizationToken";
//import jwt_decode from "jwt-decode";
import notification from "../utils/notification";
const keys = require("../config/keys");

export const logOutCurrentUser = function (dispatch) {
  delete axios.defaults.headers.common["token"];
  const res = {};
  res.redirectTo = true;
  res.logOut = true;
  res.redirectToApplication = false;
  dispatch({ type: LOGOUT, payload: res });
};

export const fetchUser = (objUserDetails) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/users/api/login/",
    data: {
      UserName: objUserDetails.username,
      Password: objUserDetails.password,
      RememberMe: objUserDetails.rememberMe,
    },
  })
    .then((res) => {
      let auth = {};
      if (res.data.userDetails) {
        // console.log("res.data.userDetails", res.data.userDetails);
        if (res.data.userDetails[0].IsActive) {
          try {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userId", res.data.userDetails[0].Id);
            localStorage.setItem("userName", res.data.userDetails[0].UserName);
            localStorage.setItem(
              "clientCode",
              res.data.userDetails[0].ClientCode
            );
            localStorage.setItem("orgId", res.data.userDetails[0].orgId);
            localStorage.setItem("IsLoggedIn", true);

            setAuthorizationToken(res.data.token);
            // console.log(res.data.userDetails);
            auth.userId = res.data.userDetails[0].Id;
            auth.userName = res.data.userDetails[0].UserName;
            auth.userType = res.data.userDetails[0].UserTypeId;
            auth.clientCode = res.data.userDetails[0].ClientCode;
            auth.instituteTypeId = res.data.userDetails[0].instituteTypeId;
            auth.clientId = res.data.userDetails[0].ClientId;
            auth.orgId = res.data.userDetails[0].OrgId;
            auth.ayId = res.data.userDetails[0].ayId;
            auth.ayName = res.data.userDetails[0].ayName;
            auth.educationBodyId = res.data.userDetails[0].educationBodyId;
            auth.startDate = res.data.userDetails[0].startDate;
            auth.endDate = res.data.userDetails[0].endDate;
            auth.organizationName = res.data.userDetails[0].organizationName;
            auth.organizationLogo = res.data.userDetails[0].organizationLogo;
            auth.parentOrganization =
              res.data.userDetails[0].parentOrganization;
            auth.parentOrgId = res.data.userDetails[0].parentOrgId;
            auth.stdOrUserMstId = res.data.userDetails[0].stdOrUserMstId;
            auth.admissionDomain = res.data.userDetails[0].admissionDomain;

            auth.userfullName = res.data.userDetails[0].userfullName;
            auth.userTypeName = res.data.userDetails[0].userTypeName;
            auth.userProfilepic = res.data.userDetails[0].userProfilepic;
            auth.orgIsClientAdmin = res.data.userDetails[0].orgIsClientAdmin;
            auth.orgIds = res.data.userDetails[0].orgIds;

            auth.showLoader = false;
            auth.logOut = false;
            auth.redirectToLogin = false;
            auth.redirectToApplication = true;
            auth.userData = res.data.userData[0];
            auth.message = "Welcome user";
            dispatch({ type: FETCH_USER, payload: auth });
            //createHistory.push('/applicationform');
          } catch (exc) {
            console.log("exception", exc);
          }
          // notification("success", "Login", "Successfully Login");
        } else if (!res.data.userDetails[0].IsActive) {
          auth.showLoader = false;
          auth.message = "Access Denied";
          dispatch({ type: FETCH_USER, payload: auth });
        }
      } else {
        auth.showLoader = false;
        auth.message = "Invalid username or password";
        dispatch({ type: FETCH_USER, payload: auth });
        notification("error", "Invalid", "Check your Username & Password");
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};

export const checkUserToken = (objTokenDetails, userType) => async (
  dispatch
) => {
  setAuthorizationToken(objTokenDetails);
  await axios({
    method: "get",
    headers: { token: objTokenDetails },
    url: keys.ServicePath + "/users/api/chkToken",
  })
    .then((res) => {
      // console.log("checkUserToken", res.data.data);
      //const token = jwt_decode(objTokenDetails);
      if (userType === res.data.data.usertypeid || userType === 0) {
        const auth = {};
        auth.userId = res.data.data.id;
        auth.userName = res.data.data.userName;
        auth.userType = res.data.data.usertypeid;
        auth.orgId = res.data.data.orgId;
        auth.clientCode = res.data.data.clientCode;
        auth.instituteTypeId = res.data.data.instituteTypeId;
        auth.clientId = res.data.data.clientId;
        auth.ayId = res.data.data.ayId;
        auth.ayName = res.data.data.ayName;
        auth.educationBodyId = res.data.data.educationBodyId;
        auth.startDate = res.data.data.startDate;
        auth.endDate = res.data.data.endDate;
        auth.stdOrUserMstId = res.data.data.stdOrUserMstId;
        auth.admissionDomain = res.data.data.admissionDomain;

        auth.userfullName = res.data.data.userfullName;
        auth.userTypeName = res.data.data.userTypeName;
        auth.userProfilepic = res.data.data.userProfilepic;
        auth.orgIsClientAdmin = res.data.data.orgIsClientAdmin;
        auth.orgIds = res.data.data.orgIds;

        auth.showLoader = false;
        auth.logOut = false;
        auth.logIn = true;
        auth.redirectToLogin = false;
        auth.redirectToApplication = true;
        auth.organizationName = res.data.data.organizationName;
        auth.organizationLogo = res.data.data.organizationLogo;
        auth.parentOrganization = res.data.data.parentOrganization;
        auth.parentOrgId = res.data.data.parentOrgId;
        auth.userData = res.data.data.userData[0];
        dispatch({ type: CHECK_TOKEN, payload: auth });
      } else {
        logOutCurrentUser(dispatch);
      }
    })
    .catch((e) => {
      logOutCurrentUser(dispatch);
      //console.log("catch error", JSON.stringify(e));
    });
};

export const logout = () => async (dispatch) => {
  window.sessionStorage.clear();
  window.localStorage.clear();
  logOutCurrentUser(dispatch);

  // Added By Pinal Patel 15-May-2019 As Redux store is not updated properly
  window.location.reload();

  // setTimeout(() => {
  //   window.location.reload();
  // }, 50);
};

// export const forgotPassword = data => async dispatch => {
//   // console.log(data);
//   await axios({
//     method: "post",
//     url: keys.ServicePath + "/users/api/forgatpssword",
//     data: data
//   })
//     .then(res => {
//       if (res.data.success) {
//         try {
//           // const msg = {
//           //   event: "success",
//           //   action: "edit",
//           //   page: "forgotPassword",
//           //   data: res.data.data,
//           //   success: true
//           // };
//           dispatch({ type: FORGET_PASSWORD, payload: res.data.data });
//         } catch (exc) {
//           console.log("exception", exc);
//         }
//       } else {
//         const msg = {
//           event: "error",
//           page: "forgotPassword",
//           data: res.data.data,
//           success: false
//         };
//         dispatch({ type: UPDATE_DATA, payload: msg });
//       }
//     })
//     .catch(e => {
//       console.log("catch error", JSON.stringify(e));
//     });
// };

export const forgotPasswordSendOTP = (data) => async (dispatch) => {
  let isOtpSent = false;
  await axios({
    method: "post",
    url: keys.ServicePath + "/users/api/forgatpssword",
    data: data,
  })
    .then((res) => {
      if (res.data.success) {
        try {
          isOtpSent = res.data.isOtpSent;
          const msg = {
            event: "success",
            // page: "forgotPassword",
            data: res.data.data,
            // action: "add",
            success: true,
            ...res.data,
          };
          dispatch({ type: UPDATE_DATA, payload: msg });
          dispatch({
            type: USER_DETAIL_OTP_FORGOTPASS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      } else {
        notification("error", "Invalid", "Check your Username");
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  return isOtpSent;
};

export const SignUp = (data) => async (dispatch) => {
  let isOtpSent = false;
  await axios({
    method: "post",
    url: keys.ServicePath + "/users/api/signup",
    data: data,
  })
    .then((res) => {
      if (res.data.success) {
        try {
          isOtpSent = res.data.isOtpSent;
          const msg = {
            event: "success",
            // page: "forgotPassword",
            data: res.data.data,
            // action: "add",
            success: true,
            ...res.data,
          };
          dispatch({ type: UPDATE_DATA, payload: msg });
          dispatch({
            type: USER_DETAIL_OTP_FORGOTPASS,
            payload: res.data.data,
          });
        } catch (exc) {
          console.log("exception", exc);
        }
      } else {
        notification("error", "Invalid", "Check your Username");
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  return isOtpSent;
};
export const VerifyOTP = (data) => async (dispatch) => {
  let isOtpVerify = false;
  let isError = false;
  let errorMsg = "";
  await axios({
    method: "post",
    url: keys.ServicePath + "/users/api/verifyotp",
    data: data,
  })
    .then(async (res) => {
      if (res.data.success) {
        try {
          let msg;
          console.log(res.data);
          if (res.data.isOtpVerify) {
            const userCreation1 = {
              //registrationNumber: this.state.registrationNumber,
              ClientName: res.data.data.institutename,
              ClientContactNo: res.data.data.phone,
              ClientEmail: res.data.data.email,
              RegDate: new Date(),
              ClientContactPersonName: "",
              ClientContactPersonNo: "",
              ClientContactPersonEmail: "",
              CountryId: 101,
              StateId: 10,
              CityId: 10,
              Pincode: "",
              ExpiryDate: new Date(),
              Address: "",
              Website: "",
              clientlogo: undefined,
              clientFileName: undefined,
              clientAgreement: undefined,
              clientAgrmfileName: undefined,
              UserName: res.data.data.institutename.toLowerCase(),
              Password: "demo@123",
              IsActive: 1,
              Reason: "",
              IsDelete: false,
              CreatedBy: -1,
              UpdatedBy: -1,
              UserTypeId: 2,
              ClientId: 0,
            };

            await axios({
              method: "post",
              url: keys.ServicePath + "/users/api/createclientwithdatabase",
              data: userCreation1,
            }).then((res) => {
              console.log(res);
            });

            isOtpVerify = true;
            msg = {
              event: "success",
              data: res.data.data,
              success: true,
              ...res.data,
            };
          } else {
            isOtpVerify = false;
            isError = true;
            errorMsg = "OTP Is Invalid";
            msg = {
              event: "error",
              data: res.data.data,
              success: true,
              ...res.data,
            };
          }
          dispatch({ type: UPDATE_DATA, payload: msg });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  return { isOtpVerify, isError, errorMsg };
};

export const checkVerifyOtpResetPassword = (data) => async (dispatch) => {
  let isOtpVerify = false;
  let isError = false;
  let errorMsg = "";
  await axios({
    method: "post",
    url: keys.ServicePath + "/users/api/CheckVerifyOtpResetPassword",
    data: data,
  })
    .then((res) => {
      if (res.data.success) {
        try {
          let msg;
          if (res.data.isOtpVerify) {
            isOtpVerify = true;
            msg = {
              event: "success",
              data: res.data.data,
              success: true,
              ...res.data,
            };
          } else {
            isOtpVerify = false;
            isError = true;
            errorMsg = "OTP Is Invalid";
            msg = {
              event: "error",
              data: res.data.data,
              success: true,
              ...res.data,
            };
          }
          dispatch({ type: UPDATE_DATA, payload: msg });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
  return { isOtpVerify, isError, errorMsg };
};

export const resendOtpDetails = (data) => async (dispatch) => {
  await axios({
    method: "post",
    url: keys.ServicePath + "/users/api/ResendOtpData",
    data: data,
  })
    .then((res) => {
      if (res.data.success) {
        try {
          var msg = {
            event: "success",
            data: res.data.data,
            success: true,
            ...res.data,
          };
          dispatch({ type: UPDATE_DATA, payload: msg });
        } catch (exc) {
          console.log("exception", exc);
        }
      }
    })
    .catch((e) => {
      console.log("catch error", JSON.stringify(e));
    });
};
