import {
  GET_LETTER_CONTENTS,
  GET_ALL_LETTER_LABELS_DATA,
  GET_STUD_ATTEND_FOR_LETTER,
  GET_STUDPARENTSTAFF_INFO,
  GET_LETTER_CONTENT_HISTORY_INFO
} from "../../actions/masters/types";

const initialState = [];

export const letterContentInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_LETTER_CONTENTS:
      return action.payload;
    default:
      return state;
  }
};

export const letterLabels = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LETTER_LABELS_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const studAttendanceForLetter = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUD_ATTEND_FOR_LETTER:
      return action.payload;
    default:
      return state;
  }
};

export const studParentStaffInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDPARENTSTAFF_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const letterContentHistoryDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_LETTER_CONTENT_HISTORY_INFO:
      return action.payload;
    default:
      return state;
  }
};
