import { combineReducers } from "redux";
import subjectLinking from "./subjectLinking";
import { listElectiveSubjectGroup } from "./electivesubjectgroup";
import {
  subjectGroupMaster,
  subjectGroupTran,
  getSubjectBySubGrpCombinationId,
  getSubjGroupMstTranByComb
} from "./subjectGroup";
import idSubjectChoiceConfiguration from "./idSubjectChoiceConfiguration";
export default combineReducers({
  subjectLinking,
  listElectiveSubjectGroup,
  subjectGroupMaster,
  subjectGroupTran,
  getSubjectBySubGrpCombinationId,
  idSubjectChoiceConfiguration,
  getSubjGroupMstTranByComb
});
