import { GET_DIVISION, GET_ACADCOMB_DIVISION } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DIVISION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export const acadCombinationDivision = function(state = initialState, action) {
  switch (action.type) {
    case GET_ACADCOMB_DIVISION:
      return action.payload;
    default:
      return state;
  }
};
