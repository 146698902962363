import { GET_ALL_PAYROLL_SHIFT } from "../../actions/types";

const initialState = [];

export const getAllPayrollShift = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PAYROLL_SHIFT:
      return action.payload;
    default:
      return state;
  }
};
