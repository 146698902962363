import {
  GET_ALL_WORK_TYPE_MST_FOR_REQUEST_MGMT,
  GET_ALL_COMPLAINT_REQUEST_PERMISSION_ALLOCATION_INFO,
  GET_ALL_USERS_LIST_FOR_RQST_PERMISSION,
  GET_ALL_REQUEST_PRIORITY_INFO,
  GET_ALL_REQUEST_SOURCES_LIST,
  GET_ALL_COMPLAINT_REQUEST_BY_USER_TYPES,
  GET_ALL_REQUEST_STATUS_LIST,
  GET_ALL_STUDENT_INFO_FOR_REQUEST_MGMT,
  GET_ALL_CLIENT_ORG_INFO_FOR_REQUEST_MGMT,
  GET_ALL_REGISTERED_COMPLAINTS_REQUESTS_INFO,
  GET_DEPARTMENT_INFO_FOR_RQST_MGMT,
  GET_ALLOTED_DEPT_COMPLAINTS_REQUESTS,
  GET_ALLOTED_DEPT_RQST_HISTORY,
  GET_ALL_ASSIGNED_COMP_RQST_TO_USERS_INFO,
  GET_ALL_UPDATED_REQUEST_STATUS_HISTORY_BY_USERS
} from "../../actions/types";

const initialState = [];

export const workTypeMasterInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WORK_TYPE_MST_FOR_REQUEST_MGMT:
      return action.payload;
    default:
      return state;
  }
};

export const compRqstPermitAllocationInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPLAINT_REQUEST_PERMISSION_ALLOCATION_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const usersListForRqstPermit = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_LIST_FOR_RQST_PERMISSION:
      return action.payload;
    default:
      return state;
  }
};

export const requestPriorityInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQUEST_PRIORITY_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const requestSourcesList = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQUEST_SOURCES_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const compReqByUserTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPLAINT_REQUEST_BY_USER_TYPES:
      return action.payload;
    default:
      return state;
  }
};

export const requestStatusInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQUEST_STATUS_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const studentsInfoForReqMgmt = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENT_INFO_FOR_REQUEST_MGMT:
      return action.payload;
    default:
      return state;
  }
};

export const clientOrgInfoForReqMgmt = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLIENT_ORG_INFO_FOR_REQUEST_MGMT:
      return action.payload;
    default:
      return state;
  }
};

export const regstComplaintRequestMstInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REGISTERED_COMPLAINTS_REQUESTS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const compReqDepartmentInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENT_INFO_FOR_RQST_MGMT:
      return action.payload;
    default:
      return state;
  }
};

export const allotedDeptCompRequestDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOTED_DEPT_COMPLAINTS_REQUESTS:
      return action.payload;
    default:
      return state;
  }
};

export const allotedDeptReqHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOTED_DEPT_RQST_HISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const assignedReqToUsersInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ASSIGNED_COMP_RQST_TO_USERS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const updatedReqStatusHistoryByUsers = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_ALL_UPDATED_REQUEST_STATUS_HISTORY_BY_USERS:
      return action.payload;
    default:
      return state;
  }
};
