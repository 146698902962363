import { GET_SYSTEM_LANGUAGE_CONFIG } from "../../actions/configurations/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SYSTEM_LANGUAGE_CONFIG:
      return action.payload || state;
    default:
      return state;
  }
}
