import { GET_ADMISSION_STUDENT_LIST } from "../../actions/Accounts/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ADMISSION_STUDENT_LIST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
