import {
  GET_PASSOUT_STUDENT_SUBJECT_LIST,
  GET_PASSOUT_STUDENT_GRADE_LIST,
  ACADEMIC_ERP_DATA
} from "../../actions/exams/types";

const initialState = {};

export const getPassOutStudentSubjectList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_PASSOUT_STUDENT_SUBJECT_LIST:
      return action.payload;
    default:
      return state;
  }
};
export const getPassOutStudentGradeList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_PASSOUT_STUDENT_GRADE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getAcademicERPData = function(state = initialState, action) {
  switch (action.type) {
    case ACADEMIC_ERP_DATA:
      // console.log(action.payload);
      return action.payload;
    default:
      return state;
  }
};
