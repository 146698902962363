import { combineReducers } from "redux";
import {
  hostelMaster,
  hostelTypes,
  penaltyConfigInfo,
  usersInfoForHostelMst
} from "./hostelMaster";
import { floorMaster, assetMaster, amenitiesMaster } from "./floorMaster";
import { roomTypeMaster } from "./roomTypeMaster";
import { roomOccupencyMaster } from "./roomOccupencyMaster";
import {
  hostelFeesConfiguration,
  durationForHostel
} from "./hostelFeesConfiguration";
import { hostelConfigurationMaster } from "./hostelConfiguration";
import {
  hostelAllotmentMaster,
  usersListForHostelAllotment,
  usersListForHostelRenewal,
  penaltyDataForRenewal
} from "./hostelAllotment";
import {
  usersListForHostelFeesTransaction,
  hostelFeesTransactionInfo,
  hostelFeesPaymentHistoryByStudStaffId
} from "./hostelFeesTransaction";

export default combineReducers({
  hostelMaster,
  hostelTypes,
  floorMaster,
  roomTypeMaster,
  roomOccupencyMaster,
  assetMaster,
  getAmenitiesMaster: amenitiesMaster,
  hostelFeesConfiguration,
  durationForHostel,
  hostelConfigurationMaster,
  hostelAllotmentMaster,
  usersListForHostelAllotment,
  usersListForHostelFeesTransaction,
  hostelFeesTransactionInfo,
  hostelFeesPaymentHistoryByStudStaffId,
  penaltyConfigInfo,
  usersListForHostelRenewal,
  penaltyDataForRenewal,
  usersInfoForHostelMst
});
