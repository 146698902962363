export const GET_All_COURSE_DETAILS = "get_all_course_details";
export const GET_ALL_ADMISSION_CONFIG_LIST = "get_all_admission_config_list";
export const GET_ALL_ADMISSION_CONFIG_LIST_BY_ID =
  "get_all_admission_config_list_by_id";
export const UPDATE_DATA = "update_data";
export const GET_ACHIVEMENTCATEGORY_LIST = "get_achivementcategory_list";
export const GET_ALL_ADMISSION_QUAL_CONFIG = "get_all_admission_qual_config";
export const GET_ALL_ADMISSION_QUAL_MASTER = "get_all_admission_qual_master";
export const GET_ALL_ADMISSION_MERIT_CONFIG = "get_all_admission_merit_config";
export const GET_MERIT_CONFIG_LIST_BY_ID = "get_merit_config_list_by_id";
export const GET_ALL_ADMISSION_QUOTA = "get_all_admission_quota";
export const GET_QUALIFICATIONCONFIGURATION_LIST =
  "get_qualificationconfiguration_list";
export const GET_ALL_QUAL_CONFIG_LIST_BY_ID = "get_all_qual_config_list_by_id";
export const GET_SECTION = "get_section";
export const GET_COURSE_BY_SECTIONID = "get_course_by_sectionid";
export const GET_ALL_INQUIRYFOLLOWUP_DETAIL = "get_all_inquiryfollowup_datail";
export const GET_ALL_ENQUIRYFOLLOWUPENTRY_DETAIL =
  "get_all_enquiryfollowupentry_detail";
export const GET_ALL_ENQUIRYFOLLOWUPENTRY_DETAIL_BYID =
  "get_all_enquiryfollowupentry_deteil_byid";
export const GET_ALL_STATUSMASTER_DETAIL = "get_all_statusmaster_details";
export const GET_ALL_ADMISSION_FORMGROUP_LIST =
  "get_all_admission_formgroup_list";
export const GET_ALL_SUBJECT_LIST = "get_all_subject_list";
export const GET_ALL_ADMISSION_QUAL_MASTER_LIST =
  "get_all_admission_qual_master_list";
export const GET_QUALIFICATIONCONFIGURATION = "get_qualificationconfiguration";
export const GET_ADMISSIONQUALIFICATIONSUBJECT =
  "get_admissionqualificationsubject";
export const GET_ADMISSIONQUOTAMASTER_LIST = "get_admissionQuotaMaster_list";
export const GET_ALLACADEMICYAER_LIST = "get_allacademicyear_list";
export const GET_ADMISSION_INFO_BY_FORMGROUP =
  "get_admission_info_by_formgroup";
export const GET_All_SEATAVAILABLE = "get_all_seatavailable";
export const GET_ALL_COUNSELLING_STAGES = "get_all_counselling_stages";
export const GET_ALL_ENQUIRY = "get_all_enquiry";
export const GET_ALL_ADVERTISEMENT = "get_all_advertisement";
export const SEND_CRAIDENCIAL_BY_ENQUIRYID = "send_craidencial_by_enquiryid";
export const GET_SECTIONLIST_IN_FORMSECTION =
  "get_admissionFromSectionConfig_section_list";
export const INSERT_SECTIONLIST_IN_ADMISSION_FORM_SECTION_CONFIG =
  "insert_admissionFromSectionConfig_section_list";
export const GET_ALL_ADMISSION_FORM_DETAILS =
  "get_admissionFromSectionConfig_form_detail";
export const GET_FORMDOCUMENTCHECKLISTCONFIGURATION_LIST =
  "get_formdocumentchecklistconfiguration_list";

export const GET_COMMON_ENTRANCE_TEST = "get_common_entrance_test";
export const GET_QUALIFICATION_BY_FORMGROUP_ID =
  "get_qualification_by_formgroup_id";
export const GET_COURSES_BY_FORMGROUPID = "get_courses_by_formgroupid";
export const GET_TOTALAPPLIEDSTDS_BY_COURSEID =
  "get_totalappliedstds_by_courseid";
export const GET_SEATQUOTA_BY_COURSEID = "get_seatquota_by_courseid";
export const GET_CET_MASTER = "get_cet_master";
export const GET_ALL_ADMISSION_BOARD = "get_all_admission_board";
export const GET_All_GRACE_TYPE_MASTER = "get_all_grace_type_master";
export const GET_STUDENTS_BY_FORMGROUP_ID_AND_COURSEID =
  "get_students_by_formgroupid_and_courseid";
export const GET_STUDENT_DETAIL_LIST = "get_student_detail_list";
export const GET_FORMGROUP_DETAIL_LIST = "get_formgroup_detail_list";
export const GET_COURSE_DETAIL_LIST = "get_course_detail_list";
export const GET_DOCUMENT_DETAIL_LIST = "get_document_detail_list";
export const GET_QUOTA_DETAIL_LIST = "get_quota_detail_list";
export const GET_ADMISSION_DETAIL_LIST = "get_admission_detail_list";
export const GET_ALL_DOCUMENT_VERIFY_MASTER = "get_all_document_verify_master";
export const GET_DOCUMENT_VERIFY_DETAIL = "get_document_verify_detail";
export const GET_COURSE_MASTER = "get_course_master";
export const GET_ADMISSION_STUDENT_PROGRAM_WISE =
  "get_admission_student_program_wise";
export const GET_AVAILABLE_APPROVED_SEATS_AND_APPLIED_APPLICANTS =
  "get_available_approved_seats_and_applied_applicants";
export const GET_ADMISSION_MERIT_APPLICATION =
  "get_admission_merit_application";
export const GET_All_STAGES_OF_COUNSELLING = "get_all_stages_Of_Counselling";
export const GET_ALL_COUNSELLING_ALLOCATION_DATA =
  "get_all_counselling_allocation_data";
export const GET_FORMGROUP_PROGRAM = "get_formgroup_program";
export const GET_ALL_FORMGROUP_PROGRAM = "get_all_formgroup_program";
export const GET_STUDENT_LIST_FOR_NOTIFICATION =
  "get_student_list_for_notification";
export const GET_COUNSEL_LIST = "get_counsel_list";
export const GET_COUNSEL_NOTIF_HISTORY = "get_counsel_notif_history";
export const GET_COUNSEL_DETAIL = "get_counsel_detail";
export const GET_ADMISSION_TYPE = "get_admission_type";
export const GET_PROGRAM_BY_ACYEARID = "get_program_by_acyearid";
export const GET_ALL_COUNSELLING_MASTER_DETAILS =
  "get_all_counselling_master_detail";
export const GET_ALL_STUDENT_OF_COUNSELLER = "get_all_student_Of_Counseller";
export const GET_ALL_PROGRAM_OF_COUNSELLER = "get_all_program_Of_Counseller";
export const GET_STUDENT_DOCUMENT = "get_student_document";
export const GET_STUDENT_QUOTA = "get_student_quota";
export const GET_STUDENT_SUBJECTGROUP = "get_student_subjectgroup";
export const GET_STUDENT_BY_COURSEID = "get_student_by_courseid";
export const GET_PROGRAM_ALLOT_ENROLLNO = "get_program_allot_enrollno";
export const GET_PROGRAM_ALLOT_ENROLLNO_NEW = "get_program_allot_enrollno_new";
export const GET_ALL_COUNSELLING_STUDENT = "get_all_counselling_student";
export const GET_ALL_COUNSELLING_STUDENT_BY_STUDENT_ID =
  "get_all_counselling_student_by_student_id";
export const OVERALADMISSIONREPORTDATA = "overal_admission_report_data";
/*export const OVERALADMISSIONREPORTDATA = "OveralAdmissionReportData"; */
export const GET_CANCELLED_TERMFEES_REPORT = "get_CancelledTermFeesReport";
