import { combineReducers } from "redux";
import { getScholasticCoScholasticActivityMaster } from "./activityMaster";
import { getScholasticCoScholasticGroupActivityMaster } from "./groupActivityMaster";
import { getScholasticCoScholasticGroupSubjectIndicator } from "./subjectIndicator";
import {
  getCoScholasticReportData,
  getCoScholasticReportHeader
} from "./coScholasticReport";
import {
  scholasticDefinedRemarksInfo,
  scholtcDefinedRemarksImportHistory,
  importDefinedRemarksResponse,
  stud_reportCardRemarkInfo,
  reportCardMstInfo
} from "./defineRemarks";

export default combineReducers({
  getScholasticCoScholasticActivityMaster,
  getScholasticCoScholasticGroupActivityMaster,
  getScholasticCoScholasticGroupSubjectIndicator,
  getCoScholasticReportData,
  getCoScholasticReportHeader,
  scholasticDefinedRemarksInfo,
  scholtcDefinedRemarksImportHistory,
  importDefinedRemarksResponse,
  stud_reportCardRemarkInfo,
  reportCardMstInfo
});
