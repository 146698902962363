import { combineReducers } from "redux";
import allStudentDocument from "./getStudentDocument";
import allDocumentType from "./getAllDocumentType";
import studentWeekOffMaster from "./getStudentWeekOffMaster";
import studentBatchMaster from "./getAllStudentBatchMaster";
import studentBatchAllocation from "./getAllStudentBatchAllocation";
import studentBatchSubjectWise from "./getAllStudentBatchSubjectWise";
import {
  studentDetailsbyid,
  studentAcademicDetailsByEnrollmentNo
} from "./StudentDetailsById";
import studentFamilyDetails from "./getStudentFamilyDetails";
import studentContactInfo from "./getAllStudentContactInfo";
import studentPreviousSchoolInfo from "./getStudentPreviousSchoolInfo";
import studentAchievements from "./getAllStudentAchievements";
import studentKnownLanguages from "./getAllStudentKnownLanguages";
import studentFeesCode from "./getStudentFeesCode";
import studentStatusHistory from "./getStudentStatusHistory";
import studentsForIdCard from "./getStudentsForIdCard";

export default combineReducers({
  allStudentDocument,
  allDocumentType,
  studentWeekOffMaster,
  studentBatchMaster,
  studentBatchAllocation,
  studentBatchSubjectWise,
  studentDetailsbyid,
  studentFamilyDetails,
  studentContactInfo,
  studentPreviousSchoolInfo,
  studentAchievements,
  studentKnownLanguages,
  studentFeesCode,
  studentStatusHistory,
  studentAcademicDetailsByEnrollmentNo,
  studentsForIdCard
});
