import { combineReducers } from "redux";

import { vehicleTypeMaster } from "./vehicleTypeMaster";
import { vehicleDocumentTypeMaster } from "./vehicleDocumentTypeMaster";
import { vehicleMaster, fuelTypeMaster } from "./vehicleMaster";
import { pickupPointMaster } from "./pickupPointMaster";

export default combineReducers({
  vehicleTypeMaster,
  vehicleDocumentTypeMaster,
  vehicleMaster,
  fuelTypeMaster,
  pickupPointMaster
});
