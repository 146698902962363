import { GET_ACPC_DETAILS_BY_STUDENTID } from "../../../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACPC_DETAILS_BY_STUDENTID:
      return action.payload;
    default:
      return state;
  }
}
