import {
  GET_EXAM_MASTER,
  GET_EXAM_BY_PROGRAMCODE,
  GET_ALL_ATKT_EXAM_MST,
  GET_ALL_EXAM_MARKSHEET_CONFIGURATION,
  GET_ALL_STUDENTS_FOR_EXAM_ELIGIBILITY_INFO
} from "../../actions/exams/types";

const initialState = [];

export const examMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_EXAM_MASTER:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getExamByProgramCode = function(state = initialState, action) {
  switch (action.type) {
    case GET_EXAM_BY_PROGRAMCODE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const atktExamMstInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ATKT_EXAM_MST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const marksheetConfigInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EXAM_MARKSHEET_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
};

export const studExamEligibilityInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_STUDENTS_FOR_EXAM_ELIGIBILITY_INFO:
      return action.payload;
    default:
      return state;
  }
};
