export const UPDATE_DATA = "update_data";
export const GET_ALL_ISR_TYPES_OF_ACTIVITIES_INFO =
  "get_all_isr_types_of_Activities_information";
export const GET_ALL_ISR_TYPES_OF_PARTICIPANTS_DETAILS =
  "get_all_isr_types_of_participants_details";
export const GET_ALL_ISR_MEMBERS_INVOLVED_IN_ACTIVITY_DETAILS =
  "get_all_isr_members_involved_in_activity_details";
export const GET_ALL_ISR_FACULTY_PARTICIPANTS_DETAILS =
  "get_all_isr_faculty_participants_details";
export const GET_ALL_ISR_ACTIVITIES_DETAILS = "get_all_isr_activities_details";
export const GET_ALL_ISR_ACT_HUMAN_RESOURCES_COST_DETAILS =
  "get_all_isr_act_human_resources_cost_details";
