import { GET_STUDENT_ACAD_DETAIL_BY_STDID } from "../../actions/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_ACAD_DETAIL_BY_STDID:
      return action.payload;
    default:
      return state;
  }
}
