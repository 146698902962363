export const UPDATE_DATA = "update_data";
export const GET_ALL_FACULTY_INFO_FOR_ASSGNMT_MST =
  "get_all_faculty_info_for_assgnmt_mst";
export const GET_ALLOTED_ACADCOMB_SUBJECTS_TO_FACULTY_FOR_ASSNMT_INFO =
  "get_alloted_acadCombSubjects_to_faculty_assgnmt_mst_info";
export const GET_ALL_ASSIGNMENT_MASTER_INFO = "get_all_assignment_master_info";
export const GET_ASSIGNMENT_DETAILS_GIVEN_TO_STUDENTS =
  "get_all_assignment_details_given_to_students";
export const GET_STUDENT_DETAILS_FOR_ASSIGNMT =
  "get_student_details_for_assignment";
export const GET_ALL_STUDENT_ASSIGNMENT_ANSWERS_DETAILS =
  "get_all_student_assignment_answers_details";
export const GET_ALL_ASSIGNMENT_STATUS_INFO = "get_all_assignment_status_info";
export const GET_ALL_ASSIGNMENT_REPORT_INFO_BY_SUBJECT_STATUS_WISE =
  "get_all_assignment_report_info_by_subject_status_wise";
export const GET_ASSIGNMT_DATA_BY_FACULTY_SUBJECT_ID =
  "get_assignment_data_by_faculty_subject_id";
export const GET_STUDENT_ASSIGNMT_ANSWERS_HISTORY_INFO = "get_all_student_assignment_answers_history_info";