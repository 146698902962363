import {
  GET_SUBJECT_EXAM_PAPER,
  GET_STUDENT_MARKS_DETAIL,
  GET_STUDENT_MARKS_HEADER_DETAIL,
  GET_GRADING_DETAIL_LIST,
  GET_SUBJECT_CONFIG_DETAIL,
  GET_STUDENT_GRADE_DETAIL,
  GET_PROGRAM_GRADE_DETAIL,
  GET_PARAMETER_LIST,
  GET_EXAM_GRADE_SUBJECT_LIST,
  GET_STUDENT_MARKS_DETAIL_COUNT,
  GET_CGP_SUBJECT_LIST_CREDIT,
  GET_STUDENT_SEMESTER_LIST,
  GET_STUDENT_SUBJECT_GRADE_LIST,
  GET_STUDENT_SUBJECT_LIST,
  GET_SEMESTER_PASS_FAIL_SUMMARY,
  GET_PASS_FAIL_ANALYSIS_DETAIL,
  GET_STUDENT_MARKS_GRADE_COUNT,
  GET_STUDENT_MARKS_GRADE_DETAIL_LIST,
  GET_STUDENT_SUBJECT_DETAIL_LIST,
  GET_STUDENT_SUBJECT_EXAMTYPE_COUNT,
  GET_STUDENT_MARKS_EXAMTYPE_LIST,
  GET_STUDENT_SUBJECT_EXAMTYPE_LIST,
  GET_STUDENT_SUBJECT_EXAMTYPE_TOTALMARKS,
  GET_EXAM_PARAMETER,
  GET_EXAM_PARAMETER_LIST,
  GET_ACADEMIC_SUBJECT_LIST,
  GET_STUDENT_TRANSCRIPT_GRADE_LIST,
  GET_STUDENT_MARKS_DETAIL_REPORT,
  GET_STUDENT_MARKS_DETAIL_REPORT_FORMATTED,
  GET_ODDEVEN_EXAMTYPE_COUNT,
  GET_ODDEVEN_EXAMTYPE_LIST,
  GET_ODDEVEN_EXAMTYPE_HEADER,
  GET_ODDEVEN_EXAMTYPES,
  GET_STUDENT_SUBJECT_GROUP_LIST,
  GET_SEMESTER_MARKSHEET,
  GET_SIGN_DETAIL,
  GET_FEEDBACK_LIST,
  GET_SUBJECT_FEEDBACK_LIST,
  GET_SUBJECT_LIST
} from "../../actions/exams/types";

const initialState = {
  StudentMarksDetailReport :[]
};

export const getSubjectExamPaper = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_EXAM_PAPER:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentMarksDetailCount = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_MARKS_DETAIL_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentMarksDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_MARKS_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getSubjectConfigDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_CONFIG_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentMarksHeaderDetail = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_MARKS_HEADER_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getGradingDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_GRADING_DETAIL_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getParameterList = function(state = initialState, action) {
  switch (action.type) {
    case GET_PARAMETER_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentGradeDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_GRADE_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getProgramGradeDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAM_GRADE_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getCGPSubjectCreditList = function(state = initialState, action) {
  switch (action.type) {
    case GET_CGP_SUBJECT_LIST_CREDIT:
      return action.payload;
    default:
      return state;
  }
};

export const getSubList = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getFeedBackList = function (state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACK_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getSubjectFeedBackList = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_FEEDBACK_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getExamGradeSubjectList = function(state = initialState, action) {
  switch (action.type) {
    case GET_EXAM_GRADE_SUBJECT_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSemesterList = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_SEMESTER_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSubjectGradeList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_GRADE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentTranscriptGradeList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_TRANSCRIPT_GRADE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSubjectWiseList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getSemesterPassFailSummary = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_SEMESTER_PASS_FAIL_SUMMARY:
      return action.payload;
    default:
      return state;
  }
};

export const getPassFailAnalysisDetail = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_PASS_FAIL_ANALYSIS_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentMarksGradeCount = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_MARKS_GRADE_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentMarksGradeDetailList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_MARKS_GRADE_DETAIL_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSubjectDetailList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_DETAIL_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSubjectExamTypeCount = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_EXAMTYPE_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentMarksExamTypeList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_MARKS_EXAMTYPE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSubjectExamTypeList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_EXAMTYPE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSubjectExamTypeTotalMarks = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_EXAMTYPE_TOTALMARKS:
      return action.payload;
    default:
      return state;
  }
};

export const getExamParameter = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_EXAM_PARAMETER:
      return action.payload;
    default:
      return state;
  }
};
export const getExamParameterList = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_EXAM_PARAMETER_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getAcademicSubjectList = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ACADEMIC_SUBJECT_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const StudentMarksDetailReport = function (
  state = initialState.StudentMarksDetailReport,
  action
) {
  
  switch (action.type) {
    case GET_STUDENT_MARKS_DETAIL_REPORT:
      return [...action.payload];
    default:
      return [...state];
  }
};

export const formatted_StudentMarksDetailReport = function (
  state = initialState.StudentMarksDetailReport,
  action
) {
   switch (action.type) {
    case GET_STUDENT_MARKS_DETAIL_REPORT_FORMATTED:
      return [...action.payload];
    default:
      return [...state];
  }
};

  export const getOddEvenExamTypeCount = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ODDEVEN_EXAMTYPE_COUNT:
        return action.payload;
      default:
        return state;
    }
  };

  export const getOddEvenExamTypeList = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ODDEVEN_EXAMTYPE_LIST:
        return action.payload;
      default:
        return state;
    }
  };

  export const getOddEvenExamTypeHeader = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ODDEVEN_EXAMTYPE_HEADER:
        return action.payload;
      default:
        return state;
    }
  };

  export const getOddEvenExamTypes = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ODDEVEN_EXAMTYPES:
        return action.payload;
      default:
        return state;
    }
  };

  export const getStudentSubjectGroupList = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_STUDENT_SUBJECT_GROUP_LIST:
        return action.payload;
      default:
        return state;
    }
  };

  export const getSemesterMarksheet = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_SEMESTER_MARKSHEET:
        return action.payload;
      default:
        return state;
    }
};

export const getSignDetail = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_SIGN_DETAIL:
        return action.payload;
      default:
        return state;
    }
};
  