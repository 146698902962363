import {
  GET_DEFINED_REMARKS_CRITERIA_INFO,
  GET_DEFINED_REMARKS_CRITERIA_IMPORT_HISTORY,
  IMPORT_DEFINED_REMARKS_CRITERIA_RESPONSE,
  GET_ALL_STUDENTS_REPORT_CARD_REMARK_INFO,
  GET_ALL_REPORT_CARD_MST_INFO
} from "../../actions/types";

let initialState = [];

export const scholasticDefinedRemarksInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEFINED_REMARKS_CRITERIA_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const scholtcDefinedRemarksImportHistory = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_DEFINED_REMARKS_CRITERIA_IMPORT_HISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const importDefinedRemarksResponse = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_DEFINED_REMARKS_CRITERIA_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

export const stud_reportCardRemarkInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENTS_REPORT_CARD_REMARK_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const reportCardMstInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REPORT_CARD_MST_INFO:
      return action.payload;
    default:
      return state;
  }
};
