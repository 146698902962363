import { combineReducers } from "redux";
import mainMenu from "./mainMenu";
import common from "./getCommonAll";
import changeAcademicYear from "./changeAcademicYear";
import systemConfig from "./systemConfig";
export default combineReducers({
  mainMenu,
  common,
  changeAcademicYear,
  systemConfig
});
