export const UPDATE_DATA = "update_data";
export const GET_ONLINE_ADMISSION_COURSE_INFO =
  "get_all_online_admission_course_info";
export const GET_ONLINE_ADM_SECTION_INFO = "get_online_admission_section_info";
export const GET_ADMISSION_CONFIGURATION_DETAILS2 =
  "get_all_admission_configuration_details";
export const GET_ONLINE_ADMISSION_FORM_DETAILS_BY_ID =
  "get_all_online_admission_form_details_by_id";
export const GET_ONLINE_ADMISSION_DOCUMENT_INFO_BY_ID =
  "get_all_online_admission_document_info_by_id";
export const GET_ONLINE_ADMISSION_PROGRAM_INFO_BY_ID =
  "get_all_online_admission_program_info_by_id";
export const GET_ONLINE_ADMISSION_APPLICANT_REGISTERED_MEMBER_INFO =
  "get_online_admission_applicant_registered_member_info";
export const GET_ONLINE_ADMISSION_NOTIFICATIONS_HISTORY_INFO =
  "get_all_online_admission_notifications_history_info";
export const GET_ADM_PROGRAM_APPLIED_THROUGH_MST_INFO =
  "get_all_admission_program_applied_through_mst_info";
export const GET_ACAD_COMBINATION_LIST_FOR_ONLINE_ADMISSION =
  "get_all_academic_combination_list_for_online_admission";
export const GET_STUDENT_DETAILS_FOR_ONLINE_ADM =
  "get_student_details_for_online_admission";
