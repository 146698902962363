import { combineReducers } from "redux";
import systemConfig from "./systemConfig";
import update from "./updateData";
import sectionMaster from "./getAllSection";
import syllabusMaster from "./getAllSyllabus";
import admissionFormList from "./getAllAdmissionFormList";
import common from "./getCommonAll";
import countryStateCity from "./getCountryStateCity";
import userDetails from "./getUserDetailsByUserId";
import qualificationConfigList from "./getQualificationConfigurationByFormId";
import achievementList from "./getAllAchievements";
import profileLanguageList from "./getAllProfileLanguages";
import achievementByStudent from "./getAchivementsByStudentId";
import languageKnownByStudent from "./getProfileLanguagesByStudentId";
import documentChecklist from "./getAllDocumentChecklistByFormId";
import quotaList from "./getAllQuota";
import admissionProgram from "./getAllAdmissionProgram";
import admissionFormConfiguration from "./getAllAdmissionFormConfigurationByFormId";
import admissionSubjectGroupList from "./getAllAdmissionSubjectGroupByFormId";
import admissionExperienceDetails from "./getExperienceDetailsByStudentId";
import admissionOtherDetails from "./getOtherDetailsByStudentId";
import admissionResearchDetails from "./getResearchDetailsByStudentId";
import admissionWorkingDetails from "./getWorkingInstituteDetailsByStudentId";
import academicQuotaList from "./getAcademicQuotaDetailsByStudentId";
import admissionVerifyDetails from "./getFormVerifyDetailsByStudentId";
import admissionTotalFormFees from "./getAdmissionTotalFormFeesByStudentId";
import userRegistrationDetails from "./userRegistrationDetails";
import admissionApplyThrough from "./getAllApplyThroughList";
import admissionBoard from "./getAllBoardList";
import admissionMarksType from "./getAllMarksTypeList";
import admissionACPCdetails from "./getACPCdetailsByStudentId";
import admissionCounsellingDetail from "./getAllCounsellingDetailByStudentId";
import admissionDocumentVerificationStatus from "./getDocumentVerificationStatusByStudentId";
export default combineReducers({
  systemConfig,
  update,
  sectionMaster,
  syllabusMaster,
  admissionFormList,
  common,
  countryStateCity,
  userDetails,
  qualificationConfigList,
  achievementList,
  profileLanguageList,
  achievementByStudent,
  languageKnownByStudent,
  documentChecklist,
  quotaList,
  admissionProgram,
  admissionFormConfiguration,
  admissionSubjectGroupList,
  admissionExperienceDetails,
  admissionOtherDetails,
  admissionResearchDetails,
  admissionWorkingDetails,
  academicQuotaList,
  admissionVerifyDetails,
  admissionTotalFormFees,
  userRegistrationDetails,
  admissionApplyThrough,
  admissionBoard,
  admissionMarksType,
  admissionACPCdetails,
  admissionCounsellingDetail,
  admissionDocumentVerificationStatus
});
