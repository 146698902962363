import {
  GET_ALL_MEAL_TYPE_MST,
  GET_ALL_MESS_MASTER_INFO,
  GET_ALL_PENALTY_CONFIGURATION,
  GET_ALL_MESS_FEES_CONFIGURATION,
  GET_ALL_MESS_FEES_TYPES,
  GET_STUD_STAFF_INFO,
  GET_ACAD_YEAR_DETAILS,
  GET_ALL_FEES_DETAILS,
  GET_RECEIPT_INFO,
  GET_ORG_DETAILS_MESS
} from "../../actions/types";

const initialState = [];

export const mealTypeMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MEAL_TYPE_MST:
      return action.payload;
    default:
      return state;
  }
};

export const messMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MESS_MASTER_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const penaltyConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PENALTY_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
};

export const messFeesConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MESS_FEES_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
};

export const messFeesTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MESS_FEES_TYPES:
      return action.payload;
    default:
      return state;
  }
};

export const studStaffInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUD_STAFF_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const acadYearInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACAD_YEAR_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const feesDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FEES_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const messReceiptInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECEIPT_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const organization_Info = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORG_DETAILS_MESS:
      return action.payload;
    default:
      return state;
  }
};
