import { GET_ALL_STUDENT_FAMILY_DETAILS } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_STUDENT_FAMILY_DETAILS:
      return action.payload;
    default:
      return state;
  }
}
