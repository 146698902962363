import { combineReducers } from "redux";
//import mediumConfig from "./mediumConfig";
import sectionConfig from "./sectionConfig";
import syllabusConfig from "./syllabusConfig";
import streamConfig from "./streamConfig";
import yearConfig from "./yearConfig";
import semesterConfig from "./semesterConfig";
import shiftConfig from "./shiftConfig";
import divisionConfig from "./divisionConfig";
import getAcCombinationById from "./academicCombination";
import semesterDatebyAcYearId from "./semesterDatebyAcYearId";
import { acadCombByAcadId } from "./academicCombination";
import academicAllProgramcode from "./programcodeEdit";

export default combineReducers({
  //mediumConfig,
  sectionConfig,
  syllabusConfig,
  streamConfig,
  yearConfig,
  semesterConfig,
  shiftConfig,
  divisionConfig,
  getAcCombinationById,
  semesterDatebyAcYearId,
  acadCombByAcadId,
  academicAllProgramcode
});
