export * from "./masters/types";
export * from "./acConfig/types";
export * from "./userControls/types";
export * from "./dashboard/types";
export * from "./student/types";
export * from "./fees/types";
export * from "./address/types";
export * from "./subjectAllotment/types";
export * from "./attendance/types";
export * from "./subject/types";
export * from "./exams/types";
export * from "./masters/Admissions/types";
export * from "./Accounts/types";
export * from "./acadDetails/types";
export * from "./masters/Admissions/studentAdmission/types";
export * from "./Payroll/type";
export * from "./EmployeeManagement/types";
export * from "./hostel/types";
export * from "./document/types";
export * from "./transportation/types";
export * from "./reports/types";
export * from "./mess/types";
export * from "./establishment/types";
export * from "./alumni/types";
export * from "./jobPortal/types";
export * from "./requestMgmt/types";
export * from "./isrMgmt/types";
export * from "./assignment/types";
export * from "./onlineAdmission/types";
export const FETCH_USER = "fetch_user";
export const LOGOUT = "logout";
export const FETCH_PROGRAMS = "fetch_programs";
export const FETCH_APPLICATIONLIST = "fetch_applicationlist";
export const CHECK_TOKEN = "check_token";
export const UPDATE_DATA = "update_data";
export const GET_MAIN_MENU = "get_main_menu";
export const GET_ALL_COMMON = "get_all_common";
export const GET_ORG_DETAILS = "get_org_details";
export const GET_AGGRID_CONFIG = "get_aggrid_config";
export const GET_CLIENT_HEADER_IMAGE = "get_client_header_image";
export const GET_STUDENT_SUBJECT_REGISTER_DATA_BY_SUBJECT_OR_STUDENT_WISE =
  "student_subject_register_data_by_subject_or_student_wise";
export const PAYMENT = "PAYMENT";
export const GET_STUDENT_REGISTRATION_COLLECTION =
  "get_student_registration_collection";
export * from "./ScholasticAndCoScholastic/types";
