import { GET_STUDENTS_BY_DIVID } from "../../actions/attendance/types";

const initialState = [];

export const studentsByDivId = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENTS_BY_DIVID:
      return action.payload;
    default:
      return state;
  }
};
