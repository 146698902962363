import {
  GET_ONLINE_ADMISSION_COURSE_INFO,
  GET_ONLINE_ADM_SECTION_INFO,
  GET_ADMISSION_CONFIGURATION_DETAILS2,
  GET_ONLINE_ADMISSION_FORM_DETAILS_BY_ID,
  GET_ONLINE_ADMISSION_PROGRAM_INFO_BY_ID,
  GET_ONLINE_ADMISSION_DOCUMENT_INFO_BY_ID,
  GET_ONLINE_ADMISSION_APPLICANT_REGISTERED_MEMBER_INFO,
  GET_ONLINE_ADMISSION_NOTIFICATIONS_HISTORY_INFO,
  GET_ADM_PROGRAM_APPLIED_THROUGH_MST_INFO,
  GET_ACAD_COMBINATION_LIST_FOR_ONLINE_ADMISSION,
  GET_STUDENT_DETAILS_FOR_ONLINE_ADM
} from "../../actions/types";

const initialState = [];

export const onlineAdmCourseInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ADMISSION_COURSE_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const onlineAdmSectionInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ADM_SECTION_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const onlineAdmissionConfigInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMISSION_CONFIGURATION_DETAILS2:
      return action.payload;
    default:
      return state;
  }
};

export const onlineAdmFormDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ADMISSION_FORM_DETAILS_BY_ID:
      return action.payload;
    default:
      return state;
  }
};

export const onlineAdmProgramDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ADMISSION_PROGRAM_INFO_BY_ID:
      return action.payload;
    default:
      return state;
  }
};

export const onlineAdmDocumentDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ADMISSION_DOCUMENT_INFO_BY_ID:
      return action.payload;
    default:
      return state;
  }
};

export const onlineAdmRegstMemberInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ADMISSION_APPLICANT_REGISTERED_MEMBER_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const onlineAdmNotificationInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ONLINE_ADMISSION_NOTIFICATIONS_HISTORY_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const admProgAppliedThroughInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADM_PROGRAM_APPLIED_THROUGH_MST_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const acadCombListForOnlineAdm = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACAD_COMBINATION_LIST_FOR_ONLINE_ADMISSION:
      return action.payload;
    default:
      return state;
  }
};

export const studInfoForOnlineAdm = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_DETAILS_FOR_ONLINE_ADM:
      return action.payload;
    default:
      return state;
  }
};
