import {
  GET_STUDENT_SUBJECT_GRADE,
  GET_REREG_STUDENT_SUBJECT_GRADE,
  GET_STUDENT_SEMESTER_DETAIL,
  GET_SUBJECT_RE_EXAM_LIST,
  GET_STUDENT_RE_EXAM_LIST,
  GET_Student_SUBJECT_RE_EXAM_LIST,
  GET_REEXAM_REGISTRATION_CONFIGURATION_INFO
} from "../../actions/exams/types";

const initialState = {};

export const getStudentSubjectGrade = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_SUBJECT_GRADE:
      return action.payload;
    default:
      return state;
  }
};

export const getReRegStudentSubjectGrade = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_REREG_STUDENT_SUBJECT_GRADE:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSemesterDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_SEMESTER_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getSubjectReExamList = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_RE_EXAM_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentReExamList = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_RE_EXAM_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const getStudentSubjectReExamList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_Student_SUBJECT_RE_EXAM_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const reExamRegstConfigurationInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_REEXAM_REGISTRATION_CONFIGURATION_INFO:
      return action.payload;
    default:
      return state;
  }
};
