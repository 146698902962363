import { GET_ALL_ADMISSION_FORM_DETAILS } from "../../../actions/masters/Admissions/types";

const initialState = {};

/* REDUCER FOR  ADMISSION FORM SECTION CONFIG  */

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ADMISSION_FORM_DETAILS:
      return action.payload;
    default:
      return state;
  }
}
