import {
  GET_COURSES_BY_FORMGROUPID,
  GET_TOTALAPPLIEDSTDS_BY_COURSEID,
  GET_SEATQUOTA_BY_COURSEID,
  GET_CET_MASTER
} from "../../../actions/masters/Admissions/types";

const initialState = [];
const initialState1 = {};
export function getCoursesByFormGroupId(state = initialState, action) {
  switch (action.type) {
    case GET_COURSES_BY_FORMGROUPID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getTotalAppliedStdsByCourseId(state = initialState1, action) {
  switch (action.type) {
    case GET_TOTALAPPLIEDSTDS_BY_COURSEID:
      return action.payload;
    default:
      return state;
  }
}

export function getSeatsQuotaByCourseId(state = initialState, action) {
  switch (action.type) {
    case GET_SEATQUOTA_BY_COURSEID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getCETMaster(state = initialState, action) {
  switch (action.type) {
    case GET_CET_MASTER:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
