import { combineReducers } from "redux";
//import mediumConfig from "./mediumConfig";
import totalFeesByOrgId from "./totalFeesByOrgId";
import getTodaysNotice from "./getTodaysNotice";
import { dashboardreports } from "./dashboard";
import totalUsersDetails from "./getTotalUsers";

export default combineReducers({
  totalFeesByOrgId,
  getTodaysNotice,
  dashboardreports,
  totalUsersDetails
});
