import { GET_SECTIONLIST_IN_FORMSECTION } from "../../../actions/masters/Admissions/types";

const initialState = [];

/* REDUCER FOR  ADMISSION FORM SECTION CONFIG  */

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SECTIONLIST_IN_FORMSECTION:
      // console.log(
      //   "actionCreater",
      //   GET_SECTIONLIST_IN_FORMSECTION,
      //   action.payload
      // );
      return action.payload;
    default:
      return state;
  }
}
