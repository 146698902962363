import {
  GET_ALL_SUBJECT_GROUP,
  GET_ALL_SUBJECT_GROUP_TRAN,
  GET_ALL_SUBJECT_BY_SUBGRP_COMBINATIONID,
  GET_ALL_SUBJ_GROUP_MST_TRAN_BY_COMB_ID
} from "../../actions/types";

const initialState = [];

export const subjectGroupMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBJECT_GROUP:
      return action.payload;
    default:
      return state;
  }
};

export const subjectGroupTran = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SUBJECT_GROUP_TRAN:
      return action.payload;
    default:
      return state;
  }
};

export const getSubjectBySubGrpCombinationId = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_SUBJECT_BY_SUBGRP_COMBINATIONID:
      return action.payload;
    default:
      return state;
  }
};

export const getSubjGroupMstTranByComb = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_SUBJ_GROUP_MST_TRAN_BY_COMB_ID:
      return action.payload;
    default:
      return state;
  }
};
