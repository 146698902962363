import { GET_FORMGROUP_DETAIL_LIST } from "../../../actions/masters/Admissions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FORMGROUP_DETAIL_LIST:
      // console.log("FormGrou Detail list Called");
      return Array.isArray(action.payload) ? action.payload : state;
    // case GET_COURSE_DETAIL_LIST:
    //   console.log("Course Detail List Called");
    //   return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

// const initialState1 = [];

// export default function (state = initialState1, action) {
//   switch (action.type) {
//     case GET_COURSE_DETAIL_LIST:
//       console.log("Course Detail list Called");
//       return Array.isArray(action.payload) ? action.payload : state;
//     default:
//       return state;
//   }
// }

// const initialState2 = [];

// export default function (state = initialState2, action) {
//   switch (action.type) {
//     case GET_DOCUMENT_DETAIL_LIST:
//       console.log("Document Detial list Called");
//       return Array.isArray(action.payload) ? action.payload : state;
//     default:
//       return state;
//   }
// }
