import {
  GET_ALL_USERS_LIST_FOR_HOSTEL_FEES_TRANSACTION,
  GET_ALL_HOSTEL_FEES_TRANSACTION,
  HOSTEL_FEES_PAYMENT_HISTORY_BY_STUD_STAFF_ID
} from "../../actions/hostel/types";

const initialState = [];

export const usersListForHostelFeesTransaction = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_ALL_USERS_LIST_FOR_HOSTEL_FEES_TRANSACTION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const hostelFeesTransactionInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HOSTEL_FEES_TRANSACTION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const hostelFeesPaymentHistoryByStudStaffId = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case HOSTEL_FEES_PAYMENT_HISTORY_BY_STUD_STAFF_ID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
