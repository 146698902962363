export const ACADEMIC_ERP_DATA = "ACADEMIC_ERP_DATA";
export const UPDATE_DATA = "update_data";
export const GET_PAPERMASTER_INFO = "GET_PAPERMASTER_INFO";
export const GET_EXAM_MASTER = "get_exam_master";
export const GET_ALL_REEXAM = "get_all_reexam";
export const GET_ALL_EXAM_TYPE = "get_all_exam_type";
export const GET_ALL_EXAM_TYPE_DETAIL = "get_all_exam_type_detail";
export const GET_EXAMMASTER_INFO = "GET_EXAMMASTER_INFO";
export const GET_MARKSENTRY_INF0 = "GET_MARKSENTRY_INF0";
export const GET_PAPERAGAINSTEXAMID = "GET_PAPERAGAINSTEXAMID";
export const GET_STUDENTAGAINSTPAPER = "GET_STUDENTAGAINSTPAPER";
export const GET_ID_EXAM_MASTER = "get_id_exam_master";
export const GET_ID_EXAM_BY_SEMESTER_TYPE = "get_id_exam_by_semester_type";
export const GET_ID_PAPER_CREATION = "get_id_paper_creation";
export const GET_MARKS_CONVERSION_EXAM_LIST = "get_marks_conversion_exam_list";
export const GET_MARKS_CONVERSION_SUBJECT_LIST =
  "get_marks_conversion_subject_list";
export const GET_ID_SUBJECT_EXAMPAPER_OF_PAPER_MARKS =
  "get_id_subjects_exampaper_of_paper_marks";
export const GET_ALL_STUDENTS_FOR_MARKS_ENTRY =
  "get_all_students_for_marks_entry";
export const GET_ALL_GRADE_FOR_SPCASE = "get_all_grade_for_spcase";
export const GET_REEXAM_LIST = "get_reexam_list";
export const GET_ALL_WITHOUT_REEXAM_TYPE = "get_all_without_reexam_type";
export const GET_ALL_WITH_REEXAM_TYPE = "get_all_with_reexam_type";
export const GET_SUBJECT_BY_COMBINATION = "get_subject_by_combination";
export const GET_ALL_SPATIALCASE = "get_all_spatialCase";
export const GET_EXAM_BY_COMBINATION = "get_exam_by_combination";
export const GET_ALL_MARKS_CONVERSION_SETTING_LIST =
  "get_all_marks_conversion_setting_list";
export const GET_ALL_PAYROLL_GRADING = "get_all_payroll_grading";
export const GET_ALL_PAYROLL_PROGRAM_GRADING =
  "get_all_payroll_program_grading";
export const GET_ALL_PAYROLL_GRADES_FOR_PROGRAMS =
  "get_all_payroll_grades_for_program";
export const GET_EXAM_WEIGHTAGE_CONFIGURATION =
  "get_exam_weightage_configuration";
export const GET_EXAM_BY_COMBINATION_OR_EXAMTYPE =
  "get_exam_by_combination_or_examtype";
export const GET_PAPER_BY_COMBINATION_OR_EXAMTYPE_OR_EXAMID =
  "get_paper_by_combination_or_examtype_or_examid";
export const GET_DATA_BY_COMBINATION_FOR_LOCK_OR_UNLOCK =
  "get_data_by_combination_for_lock_or_unlock";
export const GET_SUBJECT_EXAM_PAPER = "get_subject_exam_paper";
export const GET_STUDENT_MARKS_DETAIL = "get_student_marks_detail";
export const GET_STUDENT_MARKS_HEADER_DETAIL =
  "get_student_marks_header_detail";
export const GET_GRADING_DETAIL_LIST = "get_grading_detail_list";
export const GET_PARAMETER_LIST = "get_parameter_list";
export const GET_SUBJECT_CONFIG_DETAIL = "get_subject_config_detail";
export const GET_GRADE_PARAMETER_INFO = "get_grade_parameter_info";
export const GET_ALL_SUBJECTS_FOR_GRADE_PARAMETER =
  "get_all_subjects_for_grade_parameter";
export const GET_STUDENT_SUBJECT_GRADE = "get_student_subject_grade";
export const GET_REREG_STUDENT_SUBJECT_GRADE =
  "get_rereg_student_subject_grade";
export const GET_SUBJECTIVE_REMARKS = "get_subjective_remarks";
export const GET_ALL_REPORTCARD_RESULT = "get_all_reportCard_result";
export const GET_STUDENT_GRADE_DETAIL = "get_student_grade_detail";
export const GET_PROGRAM_GRADE_DETAIL = "get_program_grade_detail";
export const GET_EXAM_BY_PROGRAMCODE = "get_exam_by_programcode";
export const GET_EXAM_GRADE_SUBJECT_LIST = "get_exam_grade_subject_list";
export const GET_STUDENT_MARKS_DETAIL_COUNT = "get_student_marks_detail_count";
export const GET_CGP_SUBJECT_LIST_CREDIT = "get_cgp_subject_list_credit";
export const GET_PASSOUT_STUDENT_SUBJECT_LIST =
  "get_passout_student_subject_list";
export const GET_PASSOUT_STUDENT_GRADE_LIST = "get_passout_student_grade_list";
export const GET_STUDENT_SEMESTER_LIST = "get_student_semester_list";
export const GET_STUDENT_SUBJECT_GRADE_LIST = "get_student_subject_grade_list";
export const GET_STUDENT_SUBJECT_LIST = "get_student_subject_list";
export const GET_SEMESTER_PASS_FAIL_SUMMARY = "get_semester_pass_fail_summery";
export const GET_PASS_FAIL_ANALYSIS_DETAIL = "get_pass_fail_analysis_detail";
export const GET_STUDENT_MARKS_GRADE_COUNT = "get_student_marks_grade_count";
export const GET_STUDENT_MARKS_GRADE_DETAIL_LIST =
  "get_student_marks_grade_detail_list";
export const GET_STUDENT_SUBJECT_DETAIL_LIST =
  "get_student_subject_detail_list";
export const GET_STUDENT_SUBJECT_EXAMTYPE_COUNT =
  "get_student_subject_examtype_count";
export const GET_STUDENT_MARKS_EXAMTYPE_LIST =
  "get_student_marks_examtype_list";
export const GET_STUDENT_SUBJECT_EXAMTYPE_LIST =
  "get_student_subject_examtype_list";
export const GET_STUDENT_SUBJECT_EXAMTYPE_TOTALMARKS =
  "get_student_subject_examtype_totalmarks";
export const GET_EXAM_PARAMETER = "get_exam_parameter";
export const GET_ID_SEMESTERTYPE = "get_id_semestertype";
export const GET_ID_SUBJECT = "get_id_subject";
export const GET_ID_PAPER = "get_id_paper";
export const SEMESTER_TYPE = "semester_type";
export const PAPER_LIST_OF_EXAM = "paper_list_of_exam";
export const GET_ID_STUDENT_MARKS_DETAIL_COUNT =
  "get_id_student_marks_detail_count";
export const GET_ID_STUDENT_MARKS_DETAIL = "get_id_student_marks_detail";
export const GET_ID_STUDENT_MARKS_HEADER_DETAIL =
  "get_id_student_marks_header_detail";
export const GET_ID_PROGRAM_GRADE_LIST = "get_id_program_grade_list";
export const GET_EXAM_PARAMETER_LIST = "get_exam_parameter_list";
export const GET_ACADEMIC_SUBJECT_LIST = "get_academic_subject_list";
export const GET_STUDENT_TRANSCRIPT_GRADE_LIST =
  "get_student_transcript_grade_list";

export const GET_STUDENT_MARKS_DETAIL_REPORT =
  "GET_STUDENT_MARKS_DETAIL_REPORT";
export const GET_STUDENT_MARKS_DETAIL_REPORT_FORMATTED =
  "GET_STUDENT_MARKS_DETAIL_REPORT_FORMATTED";
export const GET_ALL_PROGRAM_GRADING_POLICY_DETAILS =
  "get_all_program_grading_policy_details";
export const GET_ODDEVEN_EXAMTYPE_COUNT = "get_oddeven_examtype_count";
export const GET_ODDEVEN_EXAMTYPE_LIST = "get_oddeven_examtype_list";
export const GET_ODDEVEN_EXAMTYPE_HEADER = "get_oddeven_examtype_header";
export const GET_ODDEVEN_EXAMTYPES = "get_oddeven_examtypes";
export const GET_STUDENT_SEMESTER_DETAIL = "get_student_semester_detail";
export const GET_STUDENT_SUBJECT_GROUP_LIST = "get_student_subject_group_list";
export const GET_SUBJECT_RE_EXAM_LIST = "get_subject_re_exam_list";
export const GET_STUDENT_RE_EXAM_LIST = "get_student_re_exam_list";
export const GET_Student_SUBJECT_RE_EXAM_LIST =
  "get_student_subject_re_exam_list";
export const GET_SEMESTER_GRADE_REPORT = "get_semester_grade_report";
export const GET_SEMESTER_GRADE_BACKLOG = "get_semester_grade_backlog";
export const GET_SEMESTER_RECORD_COUNT = "get_semester_record_count";
export const GET_STUDENT_BACKLOG = "get_student_backlog";
export const GET_BACKLOG_HEADER_DETAIL = "get_backlog_header_detail";
export const GET_SEMESTER_STUDENT_MARKS_DETAIL = "get_semester_marks_detail";
export const GET_ALL_ATKT_EXAM_MST = "get_all_atkt_exam_mst_info";
export const GET_RE_REGISTERED_SUBJECTS_FOR_ATKT_PAPERS =
  "get_all_reRegistered_subjects_for_atkt_papers";
export const GET_SEMESTER_MARKSHEET = "GET_SEMESTER_MARKSHEET";
export const GET_ATKT_EXAM_MST_DETAILS = "get_all_atkt_exam_master_details";
export const GET_ATKT_EXAM_PAPER_INFO = "get_all_atkt_exam_paper_info";
export const GET_REEXAM_REGISTRATION_CONFIGURATION_INFO =
  "get_all_reExam_registration_configuration_info";
export const GET_ATKT_SUBJECT_EXAM_PAPER = "get_atkt_subject_exam_paper";
export const GET_ATKT_STUDENT_MARKS_DETAIL_COUNT =
  "get_atkt_student_marks_detail_count";
export const GET_ATKT_STUDENT_MARKS_DETAIL = "get_atkt_student_marks_detail";
export const GET_ATKT_STUDENT_MARKS_HEADER_DETAIL =
  "get_atkt_student_marks_header_detail";
export const GET_ATKT_GRADING_DETAIL_LIST = "get_atkt_grading_detail_list";
export const GET_ATKT_PARAMETER_LIST = "get_atkt_parameter_list";
export const GET_ATKT_EXAM_PARAMETER_LIST = "get_atkt_exam_parameter_list";
export const GET_ATKT_STUDENT_LIST = "get_atkt_student_list";
export const GET_ATKT_SEMESTER_GRADE_REPORT = "get_atkt_semester_grade_report";
export const GET_ATKT_SEMESTER_GRADE_BACKLOG =
  "get_atkt_semester_grade_backlog";
export const GET_ATKT_SEMESTER_RECORD_COUNT = "get_atkt_semester_record_count";
export const GET_ATKT_STUDENT_BACKLOG = "get_atkt_student_backlog";
export const GET_ATKT_BACKLOG_HEADER_DETAIL = "get_atkt_backlog_header_detail";
export const GET_ATKT_SEMESTER_STUDENT_MARKS_DETAIL =
  "get_atkt_semester_student_marks_detail";
export const GET_ATKT_STUDENT_DETAIL_GRADE_REPORT =
  "get_atkt_student_detail_grade_report";
export const GET_STUDENT_DETAIL_GRADE_REPORT =
  "get_student_detail_grade_report";
export const GET_STUDENT_EXAM_SEATNO = "get_student_exam_seatno";
export const GET_ALL_EXAM_MARKSHEET_CONFIGURATION =
  "get_all_exam_marksheet_configuration";
export const GET_ALL_STUDENTS_FOR_EXAM_ELIGIBILITY_INFO =
  "get_all_students_for_exam_eligibility_info";
export const GET_SIGN_DETAIL = "get_sign_detail";
export const GET_FEEDBACK_LIST = "get_feedback_list";
export const GET_SUBJECT_FEEDBACK_LIST = "get_subject_feedback_list";
export const GET_SUBJECT_LIST = "get_subject_list";