import { GET_BOOK_RETURNS, GET_BOOK_RETURNS_BY_SEARCH } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_BOOK_RETURNS:
      return Array.isArray(action.payload) ? action.payload : state;
    case GET_BOOK_RETURNS_BY_SEARCH:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
