import { GET_STUDENT_DETAILS, GET_STUDENT_ACADEMICDETAILS_BY_ENROLLMENTNO } from "../../actions/types";

const initialState = [];

export const studentDetailsbyid = function (state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_DETAILS:
      return action.payload;
    default:
      return state;
  }
}

export const studentAcademicDetailsByEnrollmentNo = function (state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_ACADEMICDETAILS_BY_ENROLLMENTNO:
      return action.payload;
    default:
      return state;
  }
}
