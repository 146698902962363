import {
  GET_ALL_EMPLOYEE_ACADEMIC_DETAILS,
  GET_ALL_ORGANIZATION_BY_USERID,
  GET_ALL_EMPLOYEE_ACADEMIC_TITLE,
  GET_ALL_EMPLOYEE_ACADEMIC_REPORT
} from "../../actions/types";

const initialState = [];

export const allOrganizationListByUserId = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_ORGANIZATION_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const employeeAcademicConfiguration = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_EMPLOYEE_ACADEMIC_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const allEmployeeAcademicTitle = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EMPLOYEE_ACADEMIC_TITLE:
      return action.payload;
    default:
      return state;
  }
};

export const allEmployeeAcademicReport = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_EMPLOYEE_ACADEMIC_REPORT:
      return action.payload;
    default:
      return state;
  }
};
