import { GET_LATE_FEES_CONFIG_BY_ACADTRANID } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LATE_FEES_CONFIG_BY_ACADTRANID:
      return action.payload;
    default:
      return state;
  }
}
