import { combineReducers } from "redux";
import {
  allOrganizationUserList,
  allOrganizationStudentList,
  allCountryList,
  physicalChallengeCategoryList,
  employeePersonalInfo,
  employeeContactInfo,
  employeeFamilyInfo,
  relationList,
  employeeQualificationsDetails,
  qualificationTypeList,
  employeeExperienceDetails,
  employeeDocumentDetails,
  documentTypeList,
  employeeVisaDetails,
  employeeBankDetails,
  allLanguageList,
  employeeLanguageDetails,
  appointmentTypeList,
  broadDisciplineCategoryList,
  broadDisciplineGroupList,
  employeeBasicDetails,
  employeeAcadeBasicDetails,
  userImportHistory,
  importUserResponse,
  staffListForIcard,
  deptDesgn_ByOrgIdUserTypeId
} from "./EmployeePersonalDetails";

import {
  employeeAcademicConfiguration,
  allOrganizationListByUserId,
  allEmployeeAcademicTitle,
  allEmployeeAcademicReport
} from "./EmployeeAcademicDetails";
import { employeeAcadConfiguration } from "./employeeAcadConfiguration";

export default combineReducers({
  allOrganizationUserList,
  allOrganizationStudentList,
  allCountryList,
  physicalChallengeCategoryList,
  employeePersonalInfo,
  employeeContactInfo,
  employeeFamilyInfo,
  relationList,
  employeeQualificationsDetails,
  qualificationTypeList,
  employeeExperienceDetails,
  employeeDocumentDetails,
  documentTypeList,
  employeeVisaDetails,
  employeeBankDetails,
  allLanguageList,
  employeeLanguageDetails,
  appointmentTypeList,
  broadDisciplineCategoryList,
  broadDisciplineGroupList,
  employeeBasicDetails,
  employeeAcadeBasicDetails,

  employeeAcademicConfiguration,
  employeeAcadConfiguration,
  userImportHistory,
  importUserResponse,
  allOrganizationListByUserId,
  allEmployeeAcademicTitle,
  allEmployeeAcademicReport,
  staffListForIcard,
  deptDesgn_ByOrgIdUserTypeId
});
