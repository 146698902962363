import { GET_ACADEMICYEAR_DETAILS } from "../../actions/types";

const initialState = [];

export const academicYearDetailsById = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACADEMICYEAR_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
