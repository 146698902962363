import {
  GET_ATTENDANCE_REPORT_BY_COMBINATION,
  GET_FACULTY_LECTURE_ATTENDANCE_REPORT,
  GET_CONSOLIDATED_ATTENDANCE_REPORT_BY_COMBINATION,
  GET_SUMMARY_ATTENDANCE_REPORT,
  GET_STUDENT_EXAM_ELIGIBILITY_REPORT,
  GET_SUBJECT_LECTURE_ATTENDANCE_REPORT,
  GET_FACULTY_LECTURES_SUMMARY_REPORT,
  GET_SUBJECT_LECTURES_SUMMARY_REPORT,
  GET_ACADEMIC_YEAR_STARTDATE_ENDDATE,
  GET_ATTENDANCE_REPORT_BY_COMBINATION_SUBJECTWISE
} from "../../actions/attendance/types";

const initialState = [];

export const attendanceReportByCombinationSubjectwise = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_ATTENDANCE_REPORT_BY_COMBINATION_SUBJECTWISE:
      return action.payload;
    default:
      return state;
  }
};

export const attendanceReportByCombination = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_REPORT_BY_COMBINATION:
      return action.payload;
    default:
      return state;
  }
};

export const consolidatedAttendanceReportByCombination = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_CONSOLIDATED_ATTENDANCE_REPORT_BY_COMBINATION:
      const { data } = action;
      return {
        ...state,
        data
      };
    default:
      return state;
  }
};

export const attendanceSummaryReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUMMARY_ATTENDANCE_REPORT:
      const { data } = action;
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};

export const studentExamEligibility = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_EXAM_ELIGIBILITY_REPORT:
      const { data } = action;
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};

export const facultyLectureAttendanceReport = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_FACULTY_LECTURE_ATTENDANCE_REPORT:
      return action.payload;
    default:
      return state;
  }
};

export const subjectLectureAttendanceReport = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SUBJECT_LECTURE_ATTENDANCE_REPORT:
      return action.payload;
    default:
      return state;
  }
};

export const allfacultyLecturesSummaryReport = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_FACULTY_LECTURES_SUMMARY_REPORT:
      return action.payload;
    default:
      return state;
  }
};

export const allSubjectLecturesSummaryReport = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SUBJECT_LECTURES_SUMMARY_REPORT:
      return action.payload;
    default:
      return state;
  }
};

export const academicYearStartDateEndDate = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACADEMIC_YEAR_STARTDATE_ENDDATE:
      return action.payload;
    default:
      return state;
  }
};
