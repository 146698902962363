import { combineReducers } from "redux";
import {
  isrTypesOfActivityInfo,
  isrTypesOfParticipantsInfo,
  isrMemberInActivityInfo,
  isrFacultyParticipantsInfo,
  isrActivityMstInfo,
  isrAct_HumanResourceCostInfo
} from "./isrMaster";

export default combineReducers({
  isrTypesOfActivityInfo,
  isrTypesOfParticipantsInfo,
  isrMemberInActivityInfo,
  isrFacultyParticipantsInfo,
  isrActivityMstInfo,
  isrAct_HumanResourceCostInfo
});
