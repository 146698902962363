import * as actionTypes from "../../actions/document/types";

const initialState = {
  list_content_type: [],
  contentSharing: [],
  shared_file_details: [],
  shared_file_details_parent: [],
};

export const document = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONTENT_TYPE_LIST:
      return {
        ...state,
        list_content_type: [...action.payload],
      };
    case actionTypes.GET_ALL_CONTENT_SHARING_INFO:
      return {
        ...state,
        contentSharing: Array.isArray(action.payload)
          ? action.payload
          : state.contentSharing,
      };
    case actionTypes.SHARED_FILE_DETAILS:
      return {
        ...state,
        shared_file_details: action.payload,
      };
    case actionTypes.SHARED_FILE_DETAILS_PARENT_TYPE:
      return {
        ...state,
        shared_file_details_parent: action.payload,
      };
    default:
      return state;
  }
};
