import { combineReducers } from "redux";
//import mediumConfig from "./mediumConfig";
import acCombination from "./acCombination";
import acCombinationDetail from "./selectedAcCombination";
import acCombinationForYearEnd from "./acCombinationForYearEnd";
import {getAllAcademicCombination,getAllAcademicCombinationTran} from "./academicCombincation";
export default combineReducers({
  acCombination,
  acCombinationDetail,
  acCombinationForYearEnd,
  getAllAcademicCombination,
  getAllAcademicCombinationTran
});
