import { GET_ALL_PAYROLL_HEAD } from "../../actions/types";

const initialState = [];

export const getAllPayrollHead = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PAYROLL_HEAD:
      return action.payload;
    default:
      return state;
  }
};
