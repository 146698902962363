import {
  GET_ALL_EXAM_TYPE,
  GET_ALL_EXAM_TYPE_DETAIL,
  GET_ALL_WITHOUT_REEXAM_TYPE,
  GET_ALL_WITH_REEXAM_TYPE
} from "../../actions/exams/types";

const initialState = [];

export const examType = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EXAM_TYPE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const examTypeDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EXAM_TYPE_DETAIL:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const withoutReExamTypeDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_WITHOUT_REEXAM_TYPE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const withReExamTypeDetail = function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_WITH_REEXAM_TYPE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
