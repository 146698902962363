import {
  GET_MEMBERSHIP_TYPE_MASTER,
  GET_PUBLICATION_TYPE_MASTER,
  GET_ALL_ONLINE_JOB_APPLICANT_REGISTERED_MEMBERS_INFO,
  GET_JOB_APP_PERSONAL_INFO,
  GET_JOB_APP_PRESENT_ADDRESS_INFO,
  GET_JOB_APP_PERMANENT_ADDRESS_INFO,
  GET_JOB_APP_LANGUAGE_KNOWN_INFO,
  GET_JOB_APP_FAMILY_INFO,
  GET_JOB_APP_QUALIFICATION_INFO,
  GET_JOB_APP_WORK_EXPERIENCE_INFO,
  GET_JOB_APP_RELATIVES_INFO,
  GET_JOB_APP_COUNCIL_REGISTRATION_INFO,
  GET_JOB_APP_MEMBERSHIP_INFO,
  GET_JOB_APP_AWARDS_RECOGNITIONS_INFO,
  GET_JOB_APP_PUBLICATIONS_INFO,
  GET_JOB_INTERVIEW_APPLICANT_STATUS_LIST,
  GET_JOB_APP_NOTIFICATIONS_HISTORY_INFO
} from "../../actions/types";

const initialState = [];

export const membershipTypeMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBERSHIP_TYPE_MASTER:
      return action.payload;
    default:
      return state;
  }
};

export const publicationTypeMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_PUBLICATION_TYPE_MASTER:
      return action.payload;
    default:
      return state;
  }
};

export const jobInterviewStatusList = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_INTERVIEW_APPLICANT_STATUS_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const onlineJobRegstMemberInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ONLINE_JOB_APPLICANT_REGISTERED_MEMBERS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppPersonalInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_PERSONAL_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppPresentAddressInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_PRESENT_ADDRESS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppPermanentAddressInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_PERMANENT_ADDRESS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppLanguagesKnownInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_LANGUAGE_KNOWN_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppFamilyInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_FAMILY_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppQualificationInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_QUALIFICATION_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppWorkExperienceInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_WORK_EXPERIENCE_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppRelativesInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_RELATIVES_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppCouncilRegstInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_COUNCIL_REGISTRATION_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppMembershipInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_MEMBERSHIP_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppAwardsInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_AWARDS_RECOGNITIONS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppPublicationsInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_PUBLICATIONS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const jobAppNotificationInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_APP_NOTIFICATIONS_HISTORY_INFO:
      return action.payload;
    default:
      return state;
  }
};
