import { GET_ALL_HOSTEL_CONFIGURATION } from "../../actions/hostel/types";

const initialState = [];

export const hostelConfigurationMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HOSTEL_CONFIGURATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
