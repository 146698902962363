import { GET_ORGANIZATION_DETAILS } from "../../actions/Accounts/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATION_DETAILS:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
