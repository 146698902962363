import { GET_DASHBOARD_REPORTS } from "../../actions/types";

const initialState = {};

export const dashboardreports = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_REPORTS:
      //return action.payload;
      // console.log("Dashboard data", action.payload);
      return Object.assign({}, state, { holidayList: action.payload });
    default:
      return state;
  }
};
