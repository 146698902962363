import { GET_ALL_LEAVE_STATUS } from "../../actions/types";

const initialState = [];

export const leaveStatus = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LEAVE_STATUS:
      return action.payload;
    default:
      return state;
  }
};
