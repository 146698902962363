import {
  GET_GRADE_PARAMETER_INFO,
  GET_ALL_SUBJECTS_FOR_GRADE_PARAMETER
} from "../../actions/exams/types";

const initialState = [];

export const gradeParameter = (state = initialState, action) => {
  switch (action.type) {
    case GET_GRADE_PARAMETER_INFO:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const gradeParameterSubjects = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBJECTS_FOR_GRADE_PARAMETER:
      return action.payload;
    default:
      return state;
  }
};
