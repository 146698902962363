export const UPDATE_DATA = "update_data";
export const GET_FORMS_LIST = "get_forms_list";
export const GET_DEPARTMENTS = "get_departments";
export const GET_DESIGNATIONS = "get_designations";
export const GET_ACADEMICS = "get_academics";
export const GET_BOOK_AUTHORS = "get_book_authors";
export const GET_BOOK_PUBLISHERS = "get_book_publishers";
export const GET_BOOK_SUBJECTS = "get_book_subjects";
export const GET_BOOK_SUBJECT_ID = "get_book_subject_id";
export const GET_BOOK_TYPE_ID = "get_book_type_id";
export const GET_BOOK_STATUS = "get_book_status";
export const GET_BOOK_ITEMTYPE = "get_book_item_type";
export const GET_BOOK_ITEMTYPE_BY_LIBRARYID = "get_book_item_type_by_libraryid";
export const GET_BOOK_ITEMTYPE_WITH_ITEMCODE =
  "get_book_item_type_with_itemcode";
export const GET_ACCESSION_CODE_BY_ITEM_TYPE_ID =
  "get_accession_code_by_item_type_id";
export const GET_LIBRARIES = "get_libraries";
export const GET_BOOKS = "get_books";
export const GET_BOOKS_BY_SEARCH = "get_books_by_search";
export const GET_BOOK_LANGUAGES = "get_book_languages";
export const GET_BOOK_BINDINGS = "get_book_bindings";
export const GET_BOOK_ACCESS = "get_book_access";
export const GET_BOOK_MAPS = "get_book_maps";
export const GET_DEFINE_RULES = "get_define_rules";
export const GET_BOOK_RETURNS = "get_book_returns";
export const GET_BOOK_RETURNS_BY_SEARCH = "get_book_returns_by_search";
export const GET_EDUBODY = "get_eduBody";
export const GET_QUALIFICATIONS = "get_qualifications";
export const GET_FEESTYPE = "get_feesType";
export const GET_SYLLABUS = "get_syllabus";
export const GET_MEDIUM = "get_medium";
export const GET_SECTION = "get_section";
export const GET_STANDARD = "get_standard";
export const GET_SEMESTER = "get_semester";
export const GET_ACADCOMB_SEMESTER = "GET_ACADCOMB_SEMESTER";
export const GET_STREAMS = "get_streams";
export const GET_DIVISION = "get_division";
export const GET_ACADCOMB_DIVISION = "GET_ACADCOMB_DIVISION";
export const GET_SHIFT = "get_shift";
export const GET_USER = "get_user";
export const GET_ROLES = "get_roles";
export const GET_BANK = "get_bank";
export const GET_FEESQUOTA = "get_feesquota";
export const GET_SUBJECT = "get_subject";
export const GET_ALL_USER = "get_all_user";
export const GET_ADD_STUDENT = "get_add_student";
export const GET_STUDENTS = "get_students";
export const GET_DROPPED_STUDENTS = "get_dropped_students";
export const GET_CAST_CATEGORY = "get_add_student";
export const GET_ALL_COMMON = "get_all_common";
export const GET_ACADEMIC_DETAILS = "get_academic_details";
export const GET_STUDENT_CATEGORY = "get_student_category";
export const GET_STUDENTS_BY_ID = "get_students_by_id";
export const GET_ORGANIZATIONS = "get_organizations";
export const GET_ALLFEES_FREQUENCIES = "get_allfees_frequencies";
export const GET_PAYABLE_BY = "get_payable_by";
export const GET_ROLE_LIST = "get_role_list";
export const GET_ORGANIZATIONTYPE = "get_organization_type";
export const GET_PARENT_ORGANIZATION = "get_parent_organization";
export const GET_STUDENT_FEES_CATEGORY = "get_student_fees_category";
export const GET_EDUTYPE = "get_edutype";
export const GET_PARENT_ORGANIZATION_EDIT = "get_parent_organization_edit";
// export const GET_ADDRESS_BY_USERID = "get_Address_by_userid";
export const GET_USER_BY_USERID = "get_user_by_userid";
export const GET_USER_TYPE = "get_user_type";
export const GET_USER_STAFF = "get_user_staff";
export const GET_SEMESTERDETAILSBY_ORGID = "get_SemesterDetailsBy_OrgId";
export const GET_ACADEMICS_BY_ORGID = "get_academics_by_orgid";
export const GET_ORGMST_BY_ORGTYPEID = "get_orgmst_by_orgtypeid";
export const GET_FEES_DISCOUNT_PAYER = "get_fees_discount_payer";
export const GET_FEES_DISCOUNT_MASTER = "get_fees_discount_master";
export const GET_FEES_DISCOUNTTYPE_MST = "get_fees_discounttype_mst";
export const GET_STUDENT_FEES_ALLOTMENT = "get_student_fees_allotment";
export const GET_FEESQUOTA_BY_ACADTRANID = "get_feesquota_by_acadtranid";
export const GET_SUBJECTTYPE = "get_subjecttype";
export const GET_FEES_CONFIG_DTL_TRAN_BY_FEESQUOTAID =
  "get_fees_config_dtl_tran_by_feesquotaid";
export const GET_STUDENT_BY_ORGID_FEES_TRANSACTION =
  "get_student_by_orgid_fees_transaction";
export const GET_PAYMENT_MODE = "get_payment_mode";
export const GET_STUDENT_BY_STDID = "get_student_by_stdid";
export const GET_FEESDETAIL_BY_STDID = "get_feesdetail_by_stdid";
export const GET_STUDENT_IMPORTHISTORY = "get_student_importhistory";
export const PAYMENT_HISTORY_BY_STUD_ID = "payment_history_by_stud_id";
export const GET_FEES_RECEIPT_TEMPLATE = "get_fees_receipt_template";
export const GET_FEES_INSTALLMENT_MONTHS_BY_ACADTRANID =
  "get_fees_installment_months_by_acadtranid";
export const GET_FEESRECEIPTTRAN_BY_ORGID = "get_feesreceipttran_by_orgid";
export const GET_ALL_FEESRECEIPT = "get_all_feesreceipt";
export const GET_LATE_FEES_CONFIG_BY_ACADTRANID =
  "get_late_fees_config_by_acadtranid";
export const GET_ALL_FEES_RECEIPT_TEMPLATE_BY_ORGID =
  "get_all_fees_receipt_template_by_orgid";
export const GET_STUDENT_FEES_RECEIPT = "get_student_fees_receipt";
export const IMPORT_STUDENT_RESPONSE = "import_student_response";
export const GET_ALL_FEES_CONFIG_DATA = "get_all_fees_config_data";
export const GET_FEES_ALLOTMENT_DATA_BY_PRGSEMDIVID =
  "get_fees_allotment_data_by_prgsemdivid";

export const GET_PROGRAMSUBJETALLOCATION = "get_programsubjectallocation";
export const GET_PROGRAMSUBJECTALLOCATION_BY_ID =
  "get_programsubjectallocation_by_id";
export const CANCEL_FEES_RECEIPT = "cancel_fees_receipt";

export const GET_PROGRAMSUBJETCONFIGURATION = "get_programsubjectconfiguration";
export const GET_PROGRAMSUBJETCONFIGURATION_BY_ID =
  "get_programsubjectconfiguration_by_id";
export const GET_SUBJETAGAINSTCOMBINATION = "get_subjectagainstcombination";
export const GET_ORGANIZATION_BY_PARENT_ORG = "get_organization_by_parent_org";
export const GET_SEMESTER_DETAIL_BY_ACYEARID =
  "get_semester_detail_by_acyearid";

export const GET_RegistrationReregistration = "get_registrationReregistration";
export const GET_STUDENTS_BY_USERID = "get_students_by_userid";
export const GET_STUDENT_ACAD_DETAIL_BY_STDID =
  "get_student_acad_detail_by_stdid";
export const GET_SUBJECT_GROUP = "get_subject_group";

export const GET_DOCUMENT_CHECKLIST = "get_document_checklist";
export const GET_STUDENT_LEAVE_TYPE = "get_student_leave_type";

export const GET_RELIGION = "get_religion";
export const GET_REGISTRATION_SUBJECT = "get_registration_subject";
export const GET_ELECTIVE_REGISTRATION_SUBJECT =
  "get_elective_registration_subject";
export const GET_RE_REGISTRATION_SUBJECT = "get_re_registration_subject";
export const GET_SEMESTERS_FOR_PROGRAM = "get_semesters_for_program";

export const GET_REGISTRATION_CONFIGURATION = "get_registration_configuration";
export const GET_SUBCASTE_CATEGORY = "get_subcaste_category";
export const GET_STUDENT_HOLIDAY = "get_student_holiday";
export const GET_INQUIRY_STATUS = "get_inquiry_status";
export const GET_MERITSUBJECTGROUP_LIST = "get_meritsubjectgroup_list";
export const GET_ADMISSIONQUALIFICATIONSUBJECT =
  "get_admissionqualificationsubject";
export const GET_ENTRYAGAINSTCOMBINATION = "get_entryagainstcombination";
export const GET_REGISTRATIONCONFIGURATION = "get_registrationconfiguration";
export const GET_SUBJECTGROUP_BY_SUBJECTGROUPID =
  "get_subjectgroup_by_subjectgroupid";
export const GET_FORMDOCUMENTCHECKLISTCONFIGURATION_LIST =
  "get_formdocumentchecklistconfiguration_list";
export const GET_FORMGROUPNAME_LIST = "get_formgroupname_list";
export const GET_NOTICE_BOARD = "get_notice_board";
export const GET_APPLICATIONS_BY_DEPARTMENT = "get_application_by_department";
export const IMPORT_STUDENT_DETAIL_RESPONSE = "import_student_detail_response";
export const GET_STUDENT_DETAILS_IMPORTHISTORY =
  "import_student_details_importhistory";
export const GET_ALL_LEAVE_APPROVAL = "get_all_leave_approval";
export const GET_PARTIALREGISTRATION_SUBJECT =
  "get_partialregistration_subject";
export const GET_LEAVE_TYPE = "get_leave_type";
export const GET_LEAVE_APPLICATION = "get_leave_application";
export const GET_ACADEMICS_BY_ORGID_YEAR_END =
  "get_academics_by_orgid_year_end";
export const GET_ALL_DOCUMENT_SHARING = "get_all_document_sharing";
export const GET_ALL_PHYSICAL_CHALLENGE_CATEGORY =
  "get_all_physical_challenge_category";
export const GET_APPOINTMENT_TYPE_MST = "get_appointment_type_mst";
export const GET_DOCUMENT_TYPE_MST = "get_document_type_mst";
export const GET_QUALIFICATION_TYPE_MST = "get_qualification_type_mst";
export const GET_BROAD_DISCIPLINE_GROUP = "get_broad_discipline_group";
export const GET_BROAD_DISCIPLINE_CATEGORY = "get_broad_discipline_category";
export const GET_ADMISSION_TYPE = "get_admission_type";
export const GET_ALL_FEES_CONFIG_DATA_ADVANCE =
  "get_all_fees_config_data_advance";
export const GET_ALL_OTHER_FEES_CONFIG_INFO = "get_all_other_fees_config_info";
export const GET_ALL_FEES_HEAD_TYPE_INFO = "get_all_fees_head_type_info";
export const GET_ALL_OTHER_FEES_TRANSACTION_INFO =
  "get_all_other_fees_transaction_info";
export const GET_ALL_OTHER_FEES_TRANSACTION_PAYMENT_HISTORY_BY_STUD_ID =
  "get_all_other_fees_transaction_payment_history_by_stud_id";
export const GET_ALL_OTHER_FEES_RECEIPT_INFO_BY_RECEIPT_NO =
  "get_all_other_fees_receipt_info_by_receipt_no";
export const GET_ALL_HOSTEL_FEES_RECEIPT_INFO_BY_RECEIPT_NO =
  "get_all_hostel_fees_receipt_info_by_receipt_no";
export const FACULTY_ATTENDANCE_OVERVIEW_DATA =
  "get_all_faculty_attendance_overview_data";
export const ATTENDANCE_REPORT_FOR_ALL_SUBJECTS_LECTURE_WISE =
  "attendance_report_for_all_subjects_lecture_wise";
export const GET_SEMESTER_START_END_DATES_BY_ACAD_TRANID =
  "get_semester_start_end_dates_by_acadComb_acadTran_Id";
export const GET_SUBJECT_INFO_FOR_ATTENDANCE_REPORT =
  "get_subject_info_for_attendance_report";
export const GET_LETTER_CONTENTS = "get_letter_contents_info";
export const GET_ALL_LETTER_LABELS_DATA = "get_all_letter_labels_data";
export const GET_STUD_ATTEND_FOR_LETTER = "get_stud_attendance_for_letter";
export const GET_PAYMENT_VENDORS = "get_all_payment_vendors";
export const GET_ALL_GENDER_LIST_FOR_FORM = "get_all_genderList_forForm";
export const GET_ALL_COUNTRY_STATE_CITY = "get_all_Country_State_City";
export const GET_ORGWISE_PROGRAMS = "get_all_org_wise_programs";
// export const GET_PAYMENT_VENDORS = "get_all_payment-vendors";
export const GET_STUDENT_BY_ORGID_WITHOUT_CONTINOUS =
  "get_student_by_orgid_without_continous";
export const GET_APPLIED_STUDENT_DETAILS =
  "get_applied_student_details_or_convocationFormDetails";
export const GET_ACADEMICYEAR_DETAILS = "get_academicyear_details";
export const GET_STUDPARENTSTAFF_INFO = "get_all_stud_parent_staff_info";
export const GET_PROGRAMSUBJETALLOCATION_SEM2 = "get_programsubjectallocation_sem2";
export const GET_LETTER_CONTENT_HISTORY_INFO =
  "get_all_letter_content_history_info";
