export const UPDATE_DATA = "update_data";
export const GET_ALL_USERS_INFO_FOR_ESTABLISTMENT_MST =
  "get_all_users_info_establishment_master";
export const GET_ALL_ESTABLISHMENT_MST_INFO = "get_all_establishment_info";
export const GET_ALL_ESTAB_SANCTIONED_POST_DETAILS =
  "get_all_estab_sanctioned_post_details";
export const GET_ALL_ESTAB_POLICY_DETAILS = "get_all_estab_policy_details";
export const GET_ALL_ESTAB_SOP_DETAILS = "get_all_estab_sop_details";
export const GET_ALL_ESTAB_DOCUMENT_DETAILS = "get_all_estab_document_details";
export const GET_ALL_ESTAB_POSTS_PRIVILEGES_DETAILS =
  "get_all_estab_post_privileges_details";
export const GET_ALL_ESTAB_SANC_EQUIPMENT_DETAILS =
  "get_all_estab_sanc_equipment_details";
export const GET_ALL_REQ_SANCT_POST_DETAILS =
  "get_all_requisite_sanctPost_details";
export const GET_ALL_REQ_NON_SANCT_POST_DETAILS =
  "get_all_requisite_nonSanctPost_details";
export const GET_ALL_ESTAB_VACANT_POST_DATA = "get_all_estab_vacant_post_data";
