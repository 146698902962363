import {
  GET_ALL_HOSTEL_ALLOTED,
  GET_ALL_USERS_LIST_FOR_ALLOTMENT,
  GET_USERS_FOR_RENEWAL,
  GET_PENALTY_DATA_FOR_RENEWAL
} from "../../actions/hostel/types";

const initialState = [];

export const hostelAllotmentMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HOSTEL_ALLOTED:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const usersListForHostelAllotment = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_LIST_FOR_ALLOTMENT:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const usersListForHostelRenewal = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_FOR_RENEWAL:
      return action.payload;
    default:
      return state;
  }
};

export const penaltyDataForRenewal = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENALTY_DATA_FOR_RENEWAL:
      return action.payload;
    default:
      return state;
  }
};
