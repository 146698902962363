import {
  GET_CO_SCHOLASTIC_REPORT_DATA,
  GET_CO_SCHOLASTIC_REPORT_HEADER
} from "../../actions/types";

let initialState = [];

export const getCoScholasticReportHeader = (state = initialState, action) => {
  switch (action.type) {
    case GET_CO_SCHOLASTIC_REPORT_HEADER:
      return action.payload;
    default:
      return state;
  }
};
export const getCoScholasticReportData = (state = initialState, action) => {
  switch (action.type) {
    case GET_CO_SCHOLASTIC_REPORT_DATA:
      return action.payload;
    default:
      return state;
  }
};
