import {
  GET_MARKS_CONVERSION_EXAM_LIST,
  GET_MARKS_CONVERSION_SUBJECT_LIST,
  GET_ALL_MARKS_CONVERSION_SETTING_LIST
} from "../../actions/exams/types";

const initialState = [];

export const marksConversionExamList = function(state = initialState, action) {
  switch (action.type) {
    case GET_MARKS_CONVERSION_EXAM_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const marksConversionSubjectList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_MARKS_CONVERSION_SUBJECT_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const allMarksConversionSettingList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_MARKS_CONVERSION_SETTING_LIST:
      return action.payload;
    default:
      return state;
  }
};
