import { GET_BROAD_DISCIPLINE_GROUP } from "../../actions/types";

const initialState = [];

export default function(state=initialState, action){
    switch(action.type){
        case GET_BROAD_DISCIPLINE_GROUP:
            return Array.isArray(action.payload) ? action.payload : state;
        default:
            return state;
    }
}