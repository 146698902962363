import { PAYMENT_HISTORY_BY_STUD_ID } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case PAYMENT_HISTORY_BY_STUD_ID:
      return action.payload;
    default:
      return state;
  }
}
