import {
  GET_ALL_ID_SUBJECT_OF_SEMESTER,
  GET_ALL_ID_SUBJECT_PREFRANCE_OF_STUDENT,
  GET_STUDENT_ID,
  GET_ID_STUDENT_DATA,
  GET_ID_ALL_STUDENT
} from "../../actions/types";

const initialState = [];
//const initialState1 = {};

export function getIDSubjectBySemester(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ID_SUBJECT_OF_SEMESTER:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getIDSubjectPrefranceByStudent(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ID_SUBJECT_PREFRANCE_OF_STUDENT:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getStudentId(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_ID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getIDStudentData(state = initialState, action) {
  switch (action.type) {
    case GET_ID_STUDENT_DATA:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getAllIDStudent(state = initialState, action) {
  switch (action.type) {
    case GET_ID_ALL_STUDENT:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
