import { GET_ALL_CONTENT_SHARING_INFO } from "../../actions/document/types";

const initialState = [];

export const contentSharing = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONTENT_SHARING_INFO:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
