import {
  GET_ALL_VEHICLE_MASTER_INFO,
  GET_ALL_FUEL_TYPES_INFO
} from "../../actions/transportation/types";

const initialState = [];

export const vehicleMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VEHICLE_MASTER_INFO:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const fuelTypeMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FUEL_TYPES_INFO:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
