import { PAYU_SETTLEMENT_DETAILS } from "../../actions/types";

const initialState = [];

export default function (state = initialState, action) {
  switch (action.type) {
    case PAYU_SETTLEMENT_DETAILS:
      //   return Array.isArray(action.payload) ? action.payload : state;
      return action.payload;
    default:
      return state;
  }
}
