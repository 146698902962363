import {
  GET_SUBJETAGAINSTCOMBINATION,
  GET_PROGRAMSUBJETCONFIGURATION,
  GET_PROGRAMSUBJETCONFIGURATION_BY_ID
} from "../../actions/types";

//
const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAMSUBJETCONFIGURATION:
      return action.payload;
    default:
      return state;
  }
}

export function allotedSubjectAgainstCombination(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJETAGAINSTCOMBINATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function programSubjectConfigurationById(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAMSUBJETCONFIGURATION_BY_ID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
