import {
  GET_STUDENT_BY_COURSEID,
  GET_PROGRAM_ALLOT_ENROLLNO,
  GET_PROGRAM_ALLOT_ENROLLNO_NEW
} from "../../../actions/masters/Admissions/types";

const initialState = [];
// const initialState1 = {};

export function getStudentByCourseId(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_BY_COURSEID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getProgramAllotEnrollNo(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAM_ALLOT_ENROLLNO:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getProgramAllotEnrollNoNew(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAM_ALLOT_ENROLLNO_NEW:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
