import { GET_SUBJECTS_ALLOTED_TO_PROGRAMS,GET_ID_SUBJECTS_ALLOTED_TO_PROGRAMS } from "../../actions/attendance/types";

const initialState = [];

export const subjectsAllocatedToPrograms = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBJECTS_ALLOTED_TO_PROGRAMS:
      return action.payload;
    default:
      return state;
  }
};

export const idSubjectsAllocatedToPrograms = (state = initialState, action) => {
  switch (action.type) {
    case GET_ID_SUBJECTS_ALLOTED_TO_PROGRAMS:
      return action.payload;
    default:
      return state;
  }
};
