import { GET_USERACADCOMBBY_ORGID_FOR_YEAR_END } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USERACADCOMBBY_ORGID_FOR_YEAR_END:
      return action.payload;
    default:
      return state;
  }
}
