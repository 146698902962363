import { GET_FACULTY_CREDITS_ALLOTED_REPORT,GET_FACULTY_WORKLOAD,GET_FACULTY_ALLOTED_VS_ACTUAL_REPORT,GET_AISHE_REPORT_FORMAT_1 ,GET_AISHE_DETAIL_REPORT} from "../../actions/reports/types";
import * as actionTypes from "../../actions/reports/types";

const initialState = [];

export const faculty_credits_alloted_report = (state = initialState, action) => {
  switch (action.type) {
    case GET_FACULTY_CREDITS_ALLOTED_REPORT:
    //   return Object.assign({},state,{
    //     ...action.payload
    //   });
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export const faculty_workload_report = (state = initialState, action) => {
    switch (action.type) {
      case GET_FACULTY_WORKLOAD:
      //   return Object.assign({},state,{
      //     ...action.payload
      //   });
        return {
          ...state,
          ...action.data
        };
      default:
        return state;
    }
  };

  export const faculty_alloted_vs_actual_report = (state = initialState, action) => {
    switch (action.type) {
      case GET_FACULTY_ALLOTED_VS_ACTUAL_REPORT:
       return {
          ...state,
          ...action.data
        };
      default:
        return state;
    }
  };


  export const AISHEReport_1 = (state = initialState, action) => {
    switch (action.type) {
      case GET_AISHE_REPORT_FORMAT_1:
       return {
          ...state,
          ...action.data
        };
      default:
        return state;
    }
  };


  export const AISHEDetailReport = (state = initialState, action) => {
    switch (action.type) {
      case GET_AISHE_DETAIL_REPORT:
       return {
          ...state,
          ...action.data
        };
      default:
        return state;
    }
  };

export const IDRegistrationReport =  (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ID_SUBJECT_REGISTRATION_REPORT:
     return action.data;
      
    default:
      return state;
  }
};
  