import { INSERT_SECTIONLIST_IN_ADMISSION_FORM_SECTION_CONFIG } from "../../../actions/masters/Admissions/types";

const initialState = {};

/* REDUCER FOR  ADMISSION FORM SECTION CONFIG  */

export default function(state = initialState, action) {
  switch (action.type) {
    case INSERT_SECTIONLIST_IN_ADMISSION_FORM_SECTION_CONFIG:
      return action.payload;
    default:
      return state;
  }
}
