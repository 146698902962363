import { GET_DOCUMENT_VERIFICATION_STATUS_BY_STUDENT } from "../../../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT_VERIFICATION_STATUS_BY_STUDENT:
      return action.payload;
    default:
      return state;
  }
}
