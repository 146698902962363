export const UPDATE_DATA = "update_data";
export const GET_MEMBERSHIP_TYPE_MASTER = "get_all_membership_type_master";
export const GET_PUBLICATION_TYPE_MASTER = "get_all_publication_type_master";
export const GET_JOB_INTERVIEW_APPLICANT_STATUS_LIST =
  "get_all_job_interview_applicant_status_list";
export const GET_ALL_ONLINE_JOB_APPLICANT_REGISTERED_MEMBERS_INFO =
  "get_all_online_job_applicant_registered_member_info";
export const GET_JOB_APP_PERSONAL_INFO = "get_job_applicant_personal_info";
export const GET_JOB_APP_PRESENT_ADDRESS_INFO =
  "get_job_applicant_present_address_info";

export const GET_JOB_APP_PERMANENT_ADDRESS_INFO =
  "get_job_applicant_permanent_address_info";

export const GET_JOB_APP_LANGUAGE_KNOWN_INFO =
  "get_job_applicant_language_known_info";

export const GET_JOB_APP_FAMILY_INFO = "get_job_applicant_family_info";
export const GET_JOB_APP_QUALIFICATION_INFO =
  "get_job_applicant_qualification_info";

export const GET_JOB_APP_WORK_EXPERIENCE_INFO =
  "get_job_applicant_work_experience_info";
export const GET_JOB_APP_RELATIVES_INFO = "get_job_applicant_relatives_info";
export const GET_JOB_APP_COUNCIL_REGISTRATION_INFO =
  "get_job_applicant_council_registration_info";
export const GET_JOB_APP_MEMBERSHIP_INFO = "get_job_applicant_membership_info";
export const GET_JOB_APP_AWARDS_RECOGNITIONS_INFO =
  "get_job_applicant_awards_recognition_info";
export const GET_JOB_APP_PUBLICATIONS_INFO =
  "get_job_applicant_publications_info";
export const GET_JOB_APP_NOTIFICATIONS_HISTORY_INFO =
  "get_job_applicant_notifications_info";
