import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import { connect } from "react-redux";
// import Login from "./login/Login";
// import SuperAdmin from "./superadmin/components/";
// import Client from "./client/components/";
// import FeesReceiptLayout from "./client/components/FeesReceiptLayout";
// import GeneralCashReport from "./client/components/GeneralCashReport";
// import PaymentResponse from "./client/components/payment/PaymentReceipt";
import { checkUserToken } from "./login/auth";
import { loadProgressBar } from "axios-progress-bar";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
//import Guest from "./guest/components";
// import NoAccess from "./client/components/masters/404";
import { NotificationContainer } from "react-notifications";
import "ag-grid-enterprise";

loadProgressBar();

const Login = lazy(() => import("./login/Login"));
const SuperAdmin = lazy(() => import("./superadmin/components/"));
const Client = lazy(() => import("./client/components/"));
const FeesReceiptLayout = lazy(() =>
  import("./client/components/FeesReceiptLayout")
);
const GeneralCashReport = lazy(() =>
  import("./client/components/GeneralCashReport")
);
const PaymentResponse = lazy(() =>
  import("./client/components/payment/PaymentReceipt")
);

const OtherFeesReceipt = lazy(() =>
  import("./client/components/masters/OtherFeesReceipt")
);

const HostelFeesReceipt = lazy(() =>
  import("./client/components/masters/HostelFeesReceipt")
);

const OtherFeesPaymentReceipt = lazy(() =>
  import("./client/components/masters/OtherFeesPaymentReceipt")
);

const MessFeesReceipt = lazy(() =>
  import("./client/components/MessMgmt/MessReceipt")
);

const AlumniFeesReceipt = lazy(() =>
  import("./client/components/Alumni/AlumniFeesReceipt")
);

const NoAccess = lazy(() => import("./client/components/masters/404"));
const Guest = lazy(() => import("./guest/components"));

const SignUp = lazy(() => import("./login/Signup"));

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async /*componentWillMount()*/ componentDidMount() {
    // // LIVE
    // ReactGA.initialize("G-621E2WL94K", { standardImplementation: true });

    // // QA
    // ReactGA.initialize("UA-154879545-1", { standardImplementation: true });

    // LIVE
    ReactGA.initialize("UA-154879545-3", { standardImplementation: true });

    ReactGA.pageview("/client");

    // console.log("localStorage.token", localStorage.token);
    if (localStorage.token !== undefined && localStorage.token !== "")
      await this.props.checkUserToken(localStorage.token, 0);
  }
  render() {
    return (
      <Router history={history}>
        <BrowserRouter>
          <Suspense fallback={<span />}>
            <div className="themeWrapper">
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/signup" component={SignUp} />
                <Route path="/superadmin" component={SuperAdmin} />
                <Route path="/client" component={Client} />
                <Route path="/guest" component={Guest} />
                <Route
                  path="/FeesReceiptLayout/:FeesTranMstId/:showTwoCopies?/:displaySts?/:admissionCancelled?"
                  component={FeesReceiptLayout}
                />
                <Route
                  path="/GeneralCashReport/:Id"
                  component={GeneralCashReport}
                />

                <Route
                  path="/OtherFeesReceipt/:ReceiptNo"
                  component={OtherFeesReceipt}
                />

                <Route
                  path="/HostelFeesReceipt/:ReceiptNo"
                  component={HostelFeesReceipt}
                />

                <Route
                  path="/OtherFeesPaymentReceipt/:ReceiptNo"
                  component={OtherFeesPaymentReceipt}
                />

                <Route
                  path="/MessFeesReceipt/:ReceiptNo"
                  component={MessFeesReceipt}
                />

                <Route
                  path="/AlumniFeesReceipt/:Id"
                  component={AlumniFeesReceipt}
                />

                <Route component={NoAccess} />
              </Switch>
              <Route path="/PaymentResponse" component={PaymentResponse} />

              <NotificationContainer />
            </div>
          </Suspense>
        </BrowserRouter>
      </Router>
    );
  }
}
//export default App;
function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps, { checkUserToken })(App);
