import { combineReducers } from "redux";
import updateData from "./updateData";
import masters from "./masters";
import acConfig from "./acConfig";
import globalData from "./globalData";
import orgDetailsByOrgId from "./organizationDetailsByOrgId";
import clientHeaderImage from "./clientHeaderImage";
import userControls from "./userControls";
import ui from "./UI";
import dashboard from "./dashboard";
import fees from "./fees";
import students from "./student";
import address from "./address";
import attendance from "./attendance";
import subjectAllotment from "./SubjectAllotment";
import subject from "./subject";
import configurations from "./configurations";
import accounts from "./accounts";
import acadDetails from "./acadDetails";
import exams from "./exams";
import Payroll from "./payroll";
import EmployeeManagement from "./EmployeeManagement";
import hostel from "./hostel";
import document from "./document";
import scholasticAndCoScholastics from "./ScholasticAndCoScholastic";
import transportation from "./transportation";
import reports from "./reports";
import mess from "./mess";
import establishment from "./establishment";
import alumni from "./alumni";
import election from "../components/Election/reducer";
import jobPortal from "./jobPortal";
import feedback from "./feedback";
import requestMgmt from "./requestMgmt";
import isrMgmt from "./isrMgmt";
import assignment from "./assignment";
import onlineAdmission from "./onlineAdmission";

export default combineReducers({
  master: masters,
  globalData,
  acConfig,
  userControls,
  ui,
  orgDetailsByOrgId,
  update: updateData,
  dashboard,
  students,
  fees,
  address,
  attendance,
  subjectAllotment,
  subject,
  exams,
  configurations,
  accounts,
  acadDetails,
  Payroll,
  clientHeaderImage,
  EmployeeManagement,
  hostel,
  scholasticAndCoScholastics,
  transportation,
  reports,
  mess,
  establishment,
  alumni,
  election,
  jobPortal,
  feedback,
  requestMgmt,
  document,
  isrMgmt,
  assignment,
  onlineAdmission
});
