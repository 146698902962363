import { GET_CLIENT_SETTINGS } from "../../actions/configurations/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENT_SETTINGS:
      return action.payload;
      // return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
