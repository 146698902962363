import { combineReducers } from "redux";
import roleManagementForm from "./roleManagementForm";
import roleManagementList from "./roleManagementList";
import departmentMaster from "./departmentMaster";
import designationMaster from "./designationMaster";
import academicMaster from "./academicMaster";
import libraryMaster from "./libraryMaster";
import bookMaster from "./bookMaster";
import bookAuthorMaster from "./bookAuthorMaster";
import bookPublisherMaster from "./bookPublisherMaster";
import bookSubjectMaster from "./bookSubjectMaster";
import bookSubjectMasterId from "./bookSubjectMasterId";
import bookTypeMasterId from "./bookTypeMasterId";
import bookStatusMaster from "./bookStatusMaster";
import bookItemTypeMaster from "./bookItemTypeMaster";
import bookItemTypeByLibraryId from "./bookItemTypeByLibraryId";
import bookItemTypeWithItemCode from "./bookItemTypeWithItemCode";
import bookAccessionCodeByItemTypeId from "./bookAccessionCodeByItemTypeId";
import bookLanguageMaster from "./bookLanguageMaster";
import bookBindingMaster from "./bookBindingMaster";
import bookAccessMaster from "./bookAccessMaster";
import bookMapMaster from "./bookMapMaster";
import defineRules from "./defineRules";
import bookBySearch from "./bookBySearch";
import bookReturn from "./bookReturn";
import educationBodyMaster from "./educationBodyMaster";
import educationType from "./educationType";
import qualificationMaster from "./qualificationMaster";
import feesTypeMaster from "./feesTypeMaster";
import syllabusMaster from "./syllabusMaster";
import mediumMaster from "./mediumMaster";
import sectionMaster from "./sectionMaster";
import standardMaster from "./standardMaster";
import semesterMaster, { acadCombinationSemester } from "./semesterMaster";
import streamMaster from "./streamMaster";
import divisionMaster, { acadCombinationDivision } from "./divisionMaster";
import shiftMaster from "./shiftMaster";
import bankNameMaster from "./bankNameMaster";
import feesQuotaMaster from "./feesQuotaMaster";
import subjectMaster from "./subjectMaster";
import studentList from "./studentList";
import droppedStudentList from "./droppedStudentList";
import casteCategory from "./casteCategory";
import common from "./getCommonAll";
import academicDetails from "./academicDetails";
import studentFeesCategory from "./studentFeesCategory";
import studentsById from "./studentsById";
import studentCategoryPayableBy from "./studentCategoryPayableBy";
import feesFrequencies from "./feesFrequenciesMaster";
import organizationType from "./organizationType";
import organizations from "./organizationCreation";
import parentOrg from "./parentOrg";
import orgNameOnEdit from "./getOrgOnEdit";
import userCreate from "./userCreate";
import userStaff from "./userStaff";
import addressUserById from "./addressByUserId";
import userByUserId from "./userByUserId";
import rolePermissions from "./get_role_permissions";
import acadYearByOrg from "./academicYearByOrgId";
import acadYearByOrgForYearEnd from "./academicYearByOrgIdForYearEnd";
import semesterDates from "./semesterDates";
import organizationList from "./organizationList";
import feesDiscountPayerMaster from "./feesDiscountPayerMaster";
import feesDiscountMaster from "./feesDiscountMaster";
import feesDiscountTypeMst from "./feesDiscountTypeMst";
import studentFeesAllotment from "./studentFeesAllotment";
import feesQuotaByAcadTranId from "./feesQuotaByAcadTranId";
import feesConfigTranIdbyFeesQuotaId from "./feesConfigTranIdbyFeesQuotaId";
import getStudentByOrgIdForFeesTransaction from "./feesTransactionGetStudentByOrgId";
import getStudentByOrgIdContinous from "./getStudentByOrgIdContinous";
import paymentMode from "./paymentMode";
import studentByStdId from "./studentByStdId";
import feesDetailById from "./feesDetailById";
import studentImportHistory from "./getImportStudentHistory";
import feesReceiptTemplate from "./feesReceiptTemplate";
import feesInstallmentMonthByAcadTranId from "./feesInstallmentMonthByAcadTranId";
import paymentHistoryByStudId from "./paymentHistoryByStudId";
import feesReceiptGetByOrgId from "./feesReceiptGetByOrgId";
import allFeesReceiptTemplate from "./getAllFeesReceipt";
import lateFeesConfigByAcadTranId from "./lateFeesConfigByAcadTranId";
import feesReceiptTempByOrgId from "./feesReceiptTempByOrgId";
import importStudentResponse from "./importStudentResponse";
import studentFeesDetailReceipt from "./studentFeesDetailReceipt";
import feesConfigData from "./feesConfigData";
import feesAllotmentDataByPrgSemDivId from "./feesAllotmentDataGetByPrgSemDivId";
import programSubjectAllocation, {
  getProgramAllocationById,
  getentryagainistcombination,
  programSubjectAllocationSem
} from "./programSubjectAllocation";

import programsubjectConfiguration, {
  allotedSubjectAgainstCombination,
  programSubjectConfigurationById
} from "./programSubjectConfiguration";
import getOrgByParentOrg from "./getOrgByParentOrg";
import studentAcadDetailsByStudId from "./studentAcadDetailsByStudId";
import {
  StudentInformationByUserId,
  RegistrationSubjectInfo,
  getRegistrationElectiveSubjectInfo,
  RegistrationConfiguration,
  PartialRegisterSubject,
  getStudentSubjectRegisterDataBySubjectOrStudentWise,
  getStudentRegistrationCollection,
  getAllSemestersOfUser,
  getReRegistrationSubjectInfo
} from "./registrationReregistration";
import subjectGroup from "./subjectGroup";
import documentCheckListMaster from "./documentCheckListMaster";
import studentLeaveType from "./studentLeaveTypeMaster";
import religionMaster from "./religionMaster";
import subCasteCategoryMaster from "./subCasteCategoryMaster";
import studentHolidayMaster from "./studentHolidayMaster";
import admissionQualificationSubjects from "./admissionQualificationSubjects";
import addInquiryStatus from "./addInquiryStatus";
import meritSubjectGroups from "./meritSubjectGroups";
import subjectGroupByGroupId from "./subjectGroupByGroupId";
import registrationConfigurationDetail from "./registrationConfiguration";
import Admissions from "./Admissions";
import formDocumentChecklistConfiguration from "./formDocumentChecklistConfiguration1";
import admissionQuotaMaster from "../admission/getAdmissionQuotaMaster";
import languages from "./getAllLanguages";
import achievements from "./getAllAchievements";
import importStudentDetailResponse from "./importStudentDetailResponse";
import studentDetailsImportHistory from "./getStudentDetailsImportHistory";
import noticeBoardMaster from "./noticeBoardMaster";
import leaveApproval from "./leaveApproval";
import leaveType from "./leaveType";
import leaveApplication from "./leaveApplication";
import documentSharing from "./documentSharing";
import yearEndProcessStudentResponse from "./yearEndProcessStudentResponse";
import feesConfigAdvanceData from "./feesConfigAdvanceData";
import phyChallengeCategory from "./phyChallengeCategory";
import appointmentType from "./appointmentType";
import documentTypeMst from "./documentTypeMst";
import qualificationTypeMst from "./qualificationTypeMst";
import broadDisciplineCategory from "./broadDisciplineCategory";
import broadDisciplineGroup from "./broadDisciplineGroup";
import {
  otherFeesConfiguration,
  feesHeadTypes
} from "./otherFeesConfiguration";
import {
  otherFeesTransactionInfo,
  otherFeesTransactionPaymentHistoryByStudIdInfo
} from "./otherFeesTransaction";
import { otherFeesReceiptInfo } from "./otherFeesReceipt";
import { hostelFeesReceiptInfo } from "./hostelFeesReceipt";
import { facultyAttendanceOverviewData } from "./facultyAttendanceOverviewReport";
import {
  attendanceReportForAllSubjLectureWise,
  getSemStartEndDatesByAcadTranId,
  subjectInfoForAttReport
} from "./attendanceReportForAllSubjects";
import {
  letterContentInfo,
  letterLabels,
  studAttendanceForLetter,
  studParentStaffInfo,
  letterContentHistoryDetails
} from "./letterContent";
import { paymentVendors } from "./paymentVendors";
import {
  gender_List,
  countriesStatesCities,
  orgWisePrograms,
  convocationFormDetails
} from "./convocationForm";
import { academicYearDetailsById } from "./academicYearDetailsById";

export default combineReducers({
  roleManagementForm,
  roleManagementList,
  departmentMaster,
  designationMaster,
  academicMaster,
  libraryMaster,
  bookMaster,
  bookAuthorMaster,
  bookPublisherMaster,
  bookSubjectMaster,
  bookSubjectMasterId,
  bookTypeMasterId,
  bookStatusMaster,
  bookItemTypeMaster,
  bookItemTypeByLibraryId,
  bookItemTypeWithItemCode,
  bookAccessionCodeByItemTypeId,
  bookLanguageMaster,
  bookBindingMaster,
  bookAccessMaster,
  bookMapMaster,
  defineRules,
  bookBySearch,
  bookReturn,
  educationBodyMaster,
  qualificationMaster,
  feesTypeMaster,
  syllabusMaster,
  mediumMaster,
  sectionMaster,
  standardMaster,
  semesterMaster,
  streamMaster,
  divisionMaster,
  shiftMaster,
  bankNameMaster,
  feesQuotaMaster,
  subjectMaster,
  casteCategory,
  studentList,
  droppedStudentList,
  common,
  academicDetails,
  studentFeesCategory,
  studentsById,
  studentCategoryPayableBy,
  feesFrequencies,
  organizationType,
  organizations,
  parentOrg,
  educationType,
  orgNameOnEdit,
  userCreate,
  userStaff,
  addressUserById,
  userByUserId,
  rolePermissions,
  semesterDates,
  acadYearByOrg,
  organizationList,
  feesDiscountPayerMaster,
  feesDiscountMaster,
  feesDiscountTypeMst,
  studentFeesAllotment,
  feesQuotaByAcadTranId,
  feesConfigTranIdbyFeesQuotaId,
  getStudentByOrgIdForFeesTransaction,
  getStudentByOrgIdContinous,
  paymentMode,
  studentByStdId,
  studentImportHistory,
  feesReceiptTemplate,
  paymentHistoryByStudId,
  feesInstallmentMonthByAcadTranId,
  feesReceiptGetByOrgId,
  allFeesReceiptTemplate,
  lateFeesConfigByAcadTranId,
  feesReceiptTempByOrgId,
  importStudentResponse,
  studentFeesDetailReceipt,
  feesConfigData,
  feesAllotmentDataByPrgSemDivId,
  feesDetailById,
  programSubjectAllocation,
  getProgramAllocationById,
  allotedSubjectAgainstCombination,
  programsubjectConfiguration,
  programSubjectConfigurationById,
  getOrgByParentOrg,
  studentAcadDetailsByStudId,
  StudentInformationByUserId,
  subjectGroup,
  documentCheckListMaster,
  studentLeaveType,
  religionMaster,
  RegistrationSubjectInfo,
  getRegistrationElectiveSubjectInfo,
  getReRegistrationSubjectInfo,
  RegistrationConfiguration,
  subCasteCategoryMaster,
  studentHolidayMaster,
  addInquiryStatus,
  meritSubjectGroups,
  admissionQualificationSubjects,
  subjectGroupByGroupId,
  getentryagainistcombination,
  programSubjectAllocationSem,
  registrationConfigurationDetail,
  Admissions,
  formDocumentChecklistConfiguration,
  noticeBoardMaster,
  admissionQuotaMaster,
  languages,
  achievements,
  importStudentDetailResponse,
  studentDetailsImportHistory,
  leaveApproval,
  PartialRegisterSubject,
  leaveApplication,
  leaveType,
  PartialRegisterSubject,
  acadCombinationSemester,
  acadCombinationDivision,
  acadYearByOrgForYearEnd,
  yearEndProcessStudentResponse,
  documentSharing,
  feesConfigAdvanceData,
  phyChallengeCategory,
  appointmentType,
  documentTypeMst,
  qualificationTypeMst,
  broadDisciplineCategory,
  broadDisciplineGroup,
  getStudentSubjectRegisterDataBySubjectOrStudentWise,
  getStudentRegistrationCollection,
  getAllSemestersOfUser,
  otherFeesConfiguration,
  feesHeadTypes,
  otherFeesTransactionInfo,
  otherFeesTransactionPaymentHistoryByStudIdInfo,
  otherFeesReceiptInfo,
  hostelFeesReceiptInfo,
  facultyAttendanceOverviewData,
  attendanceReportForAllSubjLectureWise,
  getSemStartEndDatesByAcadTranId,
  subjectInfoForAttReport,
  letterContentInfo,
  letterLabels,
  studAttendanceForLetter,
  paymentVendors,
  gender_List,
  countriesStatesCities,
  orgWisePrograms,
  convocationFormDetails,
  academicYearDetailsById,
  studParentStaffInfo,
  letterContentHistoryDetails
});
