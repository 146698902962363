export const GET_ALL_ORGANIZATION_USERLIST = "get_all_organization_userlist";
export const GET_ALL_ORGANIZATION_STUDENTLIST =
  "get_all_organization_studentlist";
export const GET_ALL_COUNTRIES = "get_all_countries";

export const GET_PHYSICALLY_CHALLENGED_CATEGORY =
  "get_physically_challenged_category";
export const GET_EMPLOYEE_PERSONAL_INFO = "get_employee_personal_info";
export const GET_EMPLOYEE_CONTACTINFO_BY_USERID =
  "get_employee_contactinfo_by_userid";
export const GET_EMPLOYEE_FAMILYDETAIL_BY_USERID =
  "get_employee_familydetail_by_userid";
export const GET_ALL_RELATIONLIST = "get_all_relationlist";
export const GET_EMPLOYEE_QUALIFICATION_BY_USERID =
  "get_employee_qualification_by_userid";
export const GET_EMPLOYEE_EXPERIENCE_BY_USERID =
  "get_employee_experience_by_userid";

export const GET_ALL_QUALIFICATIONTYPE_LIST = "get_all_qualificationtype_list";
export const GET_EMPLOYEE_DOCUMENTDETAILS_BY_USERID =
  "get_employee_documentdetails_by_userid";
export const GET_ALL_DOCUMENTTYPE_LIST = "get_all_documenttype_list";

export const GET_EMPLOYEE_BANKDETAILS_BY_USERID =
  "get_employee_bankdetails_by_userid";

export const GET_EMPLOYEE_VISADETAILS_BY_USERID =
  "get_employee_visadetails_by_userid";

export const GET_ALL_LANGUAGE_LIST = "get_all_language_list";
export const GET_EMPLOYEE_KNOWN_LANGUAGES = "get_employee_known_languages";

export const GET_ALL_APPOINTMENTTYPE_LIST = "get_all_appointmenttype_list";
export const GET_ALL_BROAD_DISCIPLINECATEGORY_LIST =
  "get_all_broad_disciplinecategory_list";
export const GET_ALL_BROAD_DISCIPLINEGROUP_LIST =
  "get_all_broad_disciplinegroup_list";

export const GET_EMPLOYEE_BASICDETAILS_BY_USERID =
  "get_employee_basicdetails_by_userid";
export const GET_ALL_ACADE_EMPLOYEE_BASICDETAILS_BY_USERID =
  "get_all_acade_employee_basicdetails_by_userid";

export const GET_ALL_EMPLOYEE_ACADEMIC_DETAILS =
  "get_all_employee_academic_details";
export const GET_ALL_EMPLOYEE_ACADE_CONFIGURATION =
  "get_all_employee_acade_configurtion";
export const UPDATE_DATA = "update_data";

export const IMPORT_USER_RESPONSE = "import_user_response";
export const GET_USER_IMPORTHISTORY = "get_user_importhistory";

export const GET_ALL_ORGANIZATION_BY_USERID = "get_all_organization_by_userid";

export const GET_ALL_EMPLOYEE_ACADEMIC_TITLE =
  "get_all_employee_academic_title";

export const GET_ALL_EMPLOYEE_ACADEMIC_REPORT =
  "get_all_employee_academic_report";
export const GET_ALL_STAFF_INFO_FOR_ICARD = "get_all_staff_info_for_ICard";
export const GET_ALL_DEPT_DESGN_BY_ORGID_USERTYPEID =
  "get_all_dept_desgn_by_orgId_and_userTypeId";
