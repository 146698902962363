import { GET_ALL_COUNSELLING_STUDENT_BY_STUDENT_ID } from "../../../actions/masters/Admissions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COUNSELLING_STUDENT_BY_STUDENT_ID:
      return action.payload;
    default:
      return state;
  }
}
