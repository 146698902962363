import {
  GET_PROGRAMSUBJETALLOCATION,
  GET_PROGRAMSUBJECTALLOCATION_BY_ID,
  GET_ENTRYAGAINSTCOMBINATION,
  GET_PROGRAMSUBJETALLOCATION_SEM2
} from "../../actions/types";

//
const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAMSUBJETALLOCATION:
      // if (Array.isArray(action.payload)) {
      //   console.log(action.payload);
      // } else {
      //   console.log(state);
      // }
      return action.payload;
    default:
      return state;
  }
}

export function getProgramAllocationById(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAMSUBJECTALLOCATION_BY_ID:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getentryagainistcombination(state = initialState, action) {
  switch (action.type) {
    case GET_ENTRYAGAINSTCOMBINATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}


export function programSubjectAllocationSem(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAMSUBJETALLOCATION_SEM2:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}