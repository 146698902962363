import {
  GET_ATTENDANCE_BY_DATE_WISE,
  GET_HOLIDAY_AGAINST_DATE,
  GET_WEEKOFF_AGAINST_DATE,
  GET_SEMESTERDATE_BY_ACADTRANID
} from "../../actions/attendance/types";

const initialState = [];

export const attendanceByDateWise = (state = initialState, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_BY_DATE_WISE:
      return action.payload;
    default:
      return state;
  }
};

export function holidayAgainstDate(state = initialState, action) {
  switch (action.type) {
    case GET_HOLIDAY_AGAINST_DATE:
      return action.payload;
    default:
      return state;
  }
}

export function weekoffAgainstDate(state = initialState, action) {
  switch (action.type) {
    case GET_WEEKOFF_AGAINST_DATE:
      return action.payload;
    default:
      return state;
  }
}

export function semesterDateByAcadTranId(state = initialState, action) {
  switch (action.type) {
    case GET_SEMESTERDATE_BY_ACADTRANID:
      return action.payload;
    default:
      return state;
  }
}
