import {
  GET_MARKSENTRY_INF0,
  GET_EXAMMASTER_INFO,
  GET_PAPERAGAINSTEXAMID,
  GET_STUDENTAGAINSTPAPER,
  GET_EXAM_BY_COMBINATION,
  GET_SUBJECT_BY_COMBINATION,
  GET_ALL_SPATIALCASE
} from "../../actions/exams/types";

const initialState = [];

export const listMarksEntry = function(state = initialState, action) {
  switch (action.type) {
    case GET_MARKSENTRY_INF0:
      return action.payload;
    default:
      return state;
  }
};

export const listExams = function(state = initialState, action) {
  //console.log(action);
  switch (action.type) {
    case GET_EXAMMASTER_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const listPaperAgainstExam = function(state = initialState, action) {
  switch (action.type) {
    case GET_PAPERAGAINSTEXAMID:
      return action.payload;
    default:
      return state;
  }
};

export const listStudentAgainstPaper = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENTAGAINSTPAPER:
      return action.payload;
    default:
      return state;
  }
};

export const getExamsByCombination = function(state = initialState, action) {
  switch (action.type) {
    case GET_EXAM_BY_COMBINATION:
      return action.payload;
    default:
      return state;
  }
};
export const getSubjectByCombination = function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBJECT_BY_COMBINATION:
      return action.payload;
    default:
      return state;
  }
};

export const getPaperBySubjectOrExam = function(state = initialState, action) {
  switch (action.type) {
    case GET_PAPERAGAINSTEXAMID:
      return action.payload;
    default:
      return state;
  }
};

export const getAllSpatialCase = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SPATIALCASE:
      return action.payload;
    default:
      return state;
  }
};
