import {
  GET_ALL_COUNSELLING_MASTER_DETAILS,
  GET_ALL_STUDENT_OF_COUNSELLER,
  GET_ALL_PROGRAM_OF_COUNSELLER,
  GET_STUDENT_DOCUMENT,
  GET_STUDENT_QUOTA,
  GET_STUDENT_SUBJECTGROUP
} from "../../../actions/types";

const initialState = [];

export const getAllCounsellingMasterDetail = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COUNSELLING_MASTER_DETAILS:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getAllProgramsOfCounseller = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PROGRAM_OF_COUNSELLER:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getAllStudentOfCounseller = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENT_OF_COUNSELLER:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getStudentDocument = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_DOCUMENT:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getStudentQuota = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_QUOTA:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getStudentSubjectGroup = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_SUBJECTGROUP:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

/* get student Document by programId */
