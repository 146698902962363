import { GET_STUDENT_IMPORTHISTORY } from "../../actions/types";

const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_STUDENT_IMPORTHISTORY:
            return Array.isArray(action.payload) ? action.payload : state;
        default:
            return state;
    }
}
