import { combineReducers } from "redux";
import {
  usersListForEstabMst,
  establishmentMaster,
  estSanctPostDetails,
  estPolicyDetails,
  estSOPDetails,
  estDocumentDetails,
  estPostPrivilegesDetails,
  estSanctEquipmentDetails,
  estReqSanctPostDetails,
  estReqNonSanctPostDetails,
  estVacantPostDetails
} from "./establishmentMaster";

export default combineReducers({
  usersListForEstabMst,
  establishmentMaster,
  estSanctPostDetails,
  estPolicyDetails,
  estSOPDetails,
  estDocumentDetails,
  estPostPrivilegesDetails,
  estSanctEquipmentDetails,
  estReqSanctPostDetails,
  estReqNonSanctPostDetails,
  estVacantPostDetails
});
