const ckEditorconfigFull = {
  filebrowserImageBrowseUrl: "/ckfinder/ckfinder.html?type=Images",
  filebrowserImageUploadUrl:
    "/ckfinder/connector?command=QuickUpload&type=Images",
  toolbar: [
    {
      name: "document",
      items: [
        "Source",
        "-",
        "Save",
        "NewPage",
        "Preview",
        "Print",
        "-",
        "Templates"
      ]
    },
    {
      name: "clipboard",
      items: [
        "Cut",
        "Copy",
        "Paste",
        "PasteText",
        "PasteFromWord",
        "-",
        "Undo",
        "Redo"
      ]
    },
    {
      name: "editing",
      items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"]
    },
    {
      name: "forms",
      items: [
        "Form",
        "Checkbox",
        "Radio",
        "TextField",
        "Textarea",
        "Select",
        "Button",
        "ImageButton",
        "HiddenField"
      ]
    },
    "/",
    {
      name: "basicstyles",
      items: [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "Subscript",
        "Superscript",
        "-",
        "CopyFormatting",
        "RemoveFormat"
      ]
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
        "CreateDiv",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
        "-",
        "BidiLtr",
        "BidiRtl",
        "Language"
      ]
    },
    {
      name: "links",
      items: ["Link", "Unlink", "Anchor"]
    },
    {
      name: "insert",
      items: [
        "Image",
        "Flash",
        "Table",
        "HorizontalRule",
        "Smiley",
        "SpecialChar",
        "PageBreak",
        "Iframe",
        "EasyImageUpload"
      ]
    },
    "/",
    {
      name: "styles",
      items: ["Styles", "Format", "Font", "FontSize"]
    },
    {
      name: "colors",
      items: ["TextColor", "BGColor"]
    },
    {
      name: "tools",
      items: ["Maximize", "ShowBlocks"]
    },
    { name: "about", items: ["About"] }
  ],
  easyimage_toolbar: [
    "EasyImageFull",
    "EasyImageSide",
    "EasyImageGradient1",
    "EasyImageGradient2",
    "EasyImageNoGradient",
    "EasyImageAlt"
  ],
  extraPlugins: "simpleImageUpload,imageuploader"
};

const ckEditorconfig = {
  toolbar: [
    {
      name: "document",
      items: ["Source", "-", "Preview", "Print", "-", "Templates"]
    },
    {
      name: "clipboard",
      items: [
        "Cut",
        "Copy",
        "Paste",
        "PasteText",
        "PasteFromWord",
        "-",
        "Undo",
        "Redo"
      ]
    },
    {
      name: "editing",
      items: ["Find", "Replace"]
    },

    {
      name: "basicstyles",
      items: [
        "Bold",
        "Italic",
        "Underline",
        "Strike",
        "Subscript",
        "Superscript",
        "-",
        "CopyFormatting",
        "RemoveFormat"
      ]
    },
    {
      name: "paragraph",
      items: [
        "NumberedList",
        "BulletedList",
        "-",
        "Outdent",
        "Indent",
        "-",
        "Blockquote",
        "-",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock"
      ]
    },
    {
      name: "links",
      items: ["Link", "Unlink", "Anchor"]
    },
    {
      name: "insert",
      items: ["Image", "Table", "HorizontalRule", "Smiley", "SpecialChar"]
    },
    "/",
    {
      name: "styles",
      items: ["Styles", "Format", "Font", "FontSize"]
    },
    {
      name: "colors",
      items: ["TextColor", "BGColor"]
    }
  ]
};

const colorCodes = [
  "#85C1E9",
  "#48C9B0",
  "#52BE80",
  "#58D68D",
  "#F7DC6F",
  "#F8C471",
  "#EB984E",
  "#F0B27A",
  "#CACFD2",
  "#AAB7B8",
  "#99A3A4",
  "#5D6D7E",
  "#566573",
  "#FF66CC",
  "#FFCC99",
  "#339900",
  "#99FF66",
  "#3300FF",
  "#FFFF66",
  "#FFEA9F",
  "#FDDF5B",
  "#B6AD7A",
  "#AEACA0",
  "#FED600",
  "#3B9C9C",
  "#6AA121",
  "#EDDA74",
  "#FBB117",
  "#C7A317",
  "#CD7F32",
  "#E56717",
  "#7E354D"
];
module.exports = {
  // //LIVE
  ServicePath: "http://115.124.113.248:5001",
  // ServicePath: "https://api.controla.in",

  // ServiceIP: "http://115.124.123.147:5000",
  ServiceIP: "http://115.124.113.248:5000",
  
  // authorizedURL: "https://qa.controla.in",
  authorizedURL: "https://sv.controla.in",

  // feedBackURL:"http://feedbackqa.controla.in",
  feedBackURL:"http://feedback.controla.in",

  SaltKey: "cwe8tgq3#$%^&wjerhfjwironman",
  ckeditortoolbarFull: ckEditorconfigFull,
  ckeditortoolbar: ckEditorconfig,
  colorCodes
};
