import { combineReducers } from "redux";
import superAdmin from "../superadmin/reducers";
import client from "../client/reducers";
import {
  forgetPassword,
  auth,
  userDetailOTPForgotPass
} from "../login/authReducer";
//main reducer

export default combineReducers({
  superAdmin,
  client,
  auth,
  forgetPassword,
  userDetailOTPForgotPass
});
