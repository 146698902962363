import { combineReducers } from "redux";
import clientManagement from "./clientManagement";
import moduleMaster from "./moduleMaster";
import formMaster from "./formMaster";
import menuMaster from "./menuMaster";
import reportError from "./reportError";
import clientsById from "./clientsById";

export default combineReducers({
  clientManagement,
  moduleMaster,
  formMaster,
  menuMaster,
  reportError,
  clientsById
});
