import {
    GET_All_ACADEMIC_PROGRAM_CODE,
    GET_All_ACADEMIC_PROGRAM_SEMESTER
  } from "../../actions/types";
  
  const initialState = [];
  
  export const getAllAcademicCombination = (state = initialState, action) =>{
    switch (action.type) {
      case GET_All_ACADEMIC_PROGRAM_CODE:
        return Array.isArray(action.payload) ? action.payload : state;
      default:
        return state;
    }
  };

  export const getAllAcademicCombinationTran = (state = initialState, action) =>{
    switch (action.type) {
      case GET_All_ACADEMIC_PROGRAM_SEMESTER:
        return Array.isArray(action.payload) ? action.payload : state;
      default:
        return state;
    }
  };