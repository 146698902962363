export const UPDATE_DATA = "update_data";
export const GET_ALL_MEAL_TYPE_MST = "get_all_meal_type_mst";
export const GET_ALL_MESS_MASTER_INFO = "get_all_mess_master_info";
export const GET_ALL_PENALTY_CONFIGURATION = "get_all_penalty_configuration";
export const GET_ALL_MESS_FEES_CONFIGURATION =
  "get_all_mess_fees_configuration";
export const GET_ALL_MESS_FEES_TYPES = "get_all_mess_fees_type_info";
export const GET_STUD_STAFF_INFO = "get_all_stud_staff_info";
export const GET_ACAD_YEAR_DETAILS = "get_all_academic_year_details";
export const GET_ALL_FEES_DETAILS = "get_all_fees_details";
export const GET_RECEIPT_INFO = "get_receipt_info";
export const GET_ORG_DETAILS_MESS = "get_org_details_mess";
