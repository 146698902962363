import { GET_ALL_ROOM_OCCUPENCY_MST } from "../../actions/hostel/types";

const initialState = [];

export const roomOccupencyMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ROOM_OCCUPENCY_MST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
