import { GET_MONTHS_BY_SEMID,GET_STUDENTS_BY_ORG,GET_MONTHS_BY_SEMID_ID } from "../../actions/attendance/types";

const initialState = {
  data:[],
  groupeddata:[]
};

export const monthsBySem = (state = initialState, action) => {
  switch (action.type) {
    case GET_MONTHS_BY_SEMID:
      return Object.assign({},state,{
        ...action.payload,
        months:action.payload
      });
      case GET_MONTHS_BY_SEMID_ID:
        return Object.assign([],state,
          action.payload
        );
    default:
      return state;
  }
};

export const students_by_org = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENTS_BY_ORG:
      return Object.assign({},state,{
        data:action.payload,
        groupeddata:action.groupeddata
      });
    default:
      return state;
  }
};
