import { GET_FEESTRANSACTIONDETAIL_REPORT } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FEESTRANSACTIONDETAIL_REPORT:
      // return Array.isArray(action.payload) ? action.payload : state;
      return action.payload;
    default:
      return state;
  }
}
