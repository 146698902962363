import * as actionTypes from "../../actions/feedback/type";

const initialState = {
  feedback_list: [],
  feedback_master_list: [],
  feedback_list_student: [],
  feedback_report: {},
  feedbackSpecialCase_Studentlist: []
};

export const feedback_list = (state = initialState.feedback_list, action) => {
  switch (action.type) {
    case actionTypes.FEEDBACK_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const feedback_master_list = (
  state = initialState.feedback_master_list,
  action
) => {
  switch (action.type) {
    case actionTypes.FEEDBACK_MASTER_LIST:
      return [...action.payload];
    default:
      return state;
  }
};

export const feedback_list_student = (
  state = initialState.feedback_list_student,
  action
) => {
  switch (action.type) {
    case actionTypes.FEEDBACK_LIST_FOR_STUDENTS:
      return [...action.payload];
    default:
      return state;
  }
};

export const feedback_report = (
  state = initialState.feedback_report,
  action
) => {
  switch (action.type) {
    case actionTypes.FEEDBACK_REPORT:
      return action.payload;
    default:
      return state;
  }
};
      
export const feedbackSpecialCase_Studentlist = (
  state = initialState.feedbackSpecialCase_Studentlist,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_FEEDBACK_SPECIAL_CASES_STUD_INFO:
      return [...action.payload];
    default:
      return state;
  }
};
