import { GET_ACADEMIC_DETAILS } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACADEMIC_DETAILS:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
