import { combineReducers } from "redux";
import {
  getattendanceDates,
  admitCardEligibilityDataByStudent,
  admitCardEligibilityData,
  admitCardEligibilityTranDataByMaster,
  calendarSummaryDetails
} from "./attendance";
import { subjectAllotedByFacultyId } from "./subjectAllotedByFacultyId";
import { subjectAllotedByMstId } from "./subjectAllotedByMstId";
import {
  subjectsAllocatedToPrograms,
  idSubjectsAllocatedToPrograms
} from "./subjectsAllocatedToPrograms";
import { studentsByDivId } from "./studentsByDivId";
import {
  attendanceByDateWise,
  holidayAgainstDate,
  weekoffAgainstDate,
  semesterDateByAcadTranId
} from "./attendanceByDateWise";
// import { tranDataByMstId } from "./tranDataByMstId";
import { attendanceLockingConfig } from "./getAttendanceLockingConfig";
import { timeOverLap } from "./timeOverLap";
import { monthsBySem, students_by_org } from "./monthsBySem";
import {
  attendanceReportByCombination,
  consolidatedAttendanceReportByCombination,
  attendanceSummaryReport,
  studentExamEligibility,
  facultyLectureAttendanceReport,
  subjectLectureAttendanceReport,
  allfacultyLecturesSummaryReport,
  allSubjectLecturesSummaryReport,
  academicYearStartDateEndDate,
  attendanceReportByCombinationSubjectwise
} from "./attendanceReport";
import {
  getIdSubjectWithFacultyBySemType,
  getIdSubjectFacultyDetailByMstId,
  getIdSubjectAttendanceByDateWise,
  getStudentsByCombination,
  idSubjectTimeOverLap
} from "./idSubjectAttendance";

import {
  acadCombForSchoolAttendance,
  schoolFacultyInfo,
  allotedClassToFacultyInfo,
  schoolFacultyAllotedByMstId,
  schoolStudAttInfoByDateWise,
  schoolStudentListByDiv,
  schoolStudAttSummaryForCal,
  schStudLeaveTypeMstInfo,
  schoolStudAttenReportByMonthWise,
  studAttSummImportHistory,
  prevStudAttImportHistory,
  importSchoolStudAttResponse,
  importPrevSchoolStudAttResponse,
  uploadedSchoolAttInfo,
  uploadedPrevSchoolAttInfo
} from "./schoolAttendance";

export default combineReducers({
  getattendanceDates,
  subjectAllotedByFacultyId,
  subjectAllotedByMstId,
  subjectsAllocatedToPrograms,
  idSubjectsAllocatedToPrograms,
  studentsByDivId,
  attendanceByDateWise,
  holidayAgainstDate,
  attendanceLockingConfig,
  monthsBySem,
  weekoffAgainstDate,
  semesterDateByAcadTranId,
  timeOverLap,
  attendanceReportByCombination,
  getIdSubjectWithFacultyBySemType,
  getIdSubjectFacultyDetailByMstId,
  getIdSubjectAttendanceByDateWise,
  getStudentsByCombination,
  idSubjectTimeOverLap,
  facultyLectureAttendanceReport,
  consolidatedAttendanceReportByCombination,
  students_by_org,
  attendanceSummaryReport,
  studentExamEligibility,
  subjectLectureAttendanceReport,
  allfacultyLecturesSummaryReport,
  allSubjectLecturesSummaryReport,
  academicYearStartDateEndDate,
  attendanceReportByCombinationSubjectwise,
  admitCardEligibilityDataByStudent,
  admitCardEligibilityData,
  admitCardEligibilityTranDataByMaster,
  calendarSummaryDetails,
  acadCombForSchoolAttendance,
  schoolFacultyInfo,
  allotedClassToFacultyInfo,
  schoolFacultyAllotedByMstId,
  schoolStudAttInfoByDateWise,
  schoolStudentListByDiv,
  schoolStudAttSummaryForCal,
  schStudLeaveTypeMstInfo,
  schoolStudAttenReportByMonthWise,
  studAttSummImportHistory,
  prevStudAttImportHistory,
  importSchoolStudAttResponse,
  importPrevSchoolStudAttResponse,
  uploadedSchoolAttInfo,
  uploadedPrevSchoolAttInfo
});
