export const UPDATE_DATA = "update_data";
export const GET_ALL_HOSTEL_MST_INFO = "get_all_hostel_master_info";
export const GET_ALL_HOSTEL_TYPES = "get_all_hostel_types";
export const GET_ALL_ROOM_TYPE_MST = "get_all_room_type_master";
export const GET_ALL_FLOOR_MST = "get_all_floor_master";
export const GET_ALL_ROOM_OCCUPENCY_MST = "get_all_room_occupency_master";
export const GET_ALL_ASSET_MST = "get_all_asset_master";
export const GET_ALL_AMENITIES_MST = "get_all_amenities_master";
export const GET_ALL_HOSTEL_FEES_CONFIGURATION =
  "get_all_hostel_fees_configuration";
export const GET_ALL_DURATION_FOR_HOSTEL = "get_all_duration_for_hostel";
export const GET_ALL_HOSTEL_CONFIGURATION = "get_all_hostel_configuration";
export const GET_ALL_HOSTEL_ALLOTED = "get_all_hostel_alloted";
export const GET_ALL_USERS_LIST_FOR_ALLOTMENT =
  "get_all_users_list_for_allotment";
export const GET_ALL_USERS_LIST_FOR_HOSTEL_FEES_TRANSACTION =
  "get_all_users_for_hostel_fees_transaction";
export const GET_ALL_HOSTEL_FEES_TRANSACTION =
  "get_all_hostel_fees_transaction";
export const HOSTEL_FEES_PAYMENT_HISTORY_BY_STUD_STAFF_ID =
  "get_all_hostel_fees_payment_history_by_stud_staff_id";
export const GET_PENALTY_CONFIG_INFO = "get_penalty_config_info";
export const GET_USERS_FOR_RENEWAL = "get_users_list_for_renewal";
export const GET_PENALTY_DATA_FOR_RENEWAL = "get_penalty_data_for_renewal";
export const GET_USERS_FOR_HOSTEL_MST = "get_users_for_hostel_mst";
