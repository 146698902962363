import {
  GET_ALL_STAFF_ATTENDANCE,
  GET_STAFF_ATTENDANCE_IMPORT_HISTORY,
  IMPORT_STAFF_ATTENDANCE_RESPONSES,
  STAFF_ATTENDANCE_MONTHLY_REPORT_INFO
} from "../../actions/types";

const initialState = [];

export const getStaffList = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STAFF_ATTENDANCE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const pStaffAttenImportHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAFF_ATTENDANCE_IMPORT_HISTORY:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const importStaffAttenResponse = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_STAFF_ATTENDANCE_RESPONSES:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const staffAttendMonthlyInfo = (state = initialState, action) => {
  switch (action.type) {
    case STAFF_ATTENDANCE_MONTHLY_REPORT_INFO:
      return action.payload;
    default:
      return state;
  }
};
