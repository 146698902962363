import { GET_ALL_EMPLOYEE_ACADE_CONFIGURATION } from "../../actions/EmployeeManagement/types";

const initialState = [];

export const employeeAcadConfiguration = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_EMPLOYEE_ACADE_CONFIGURATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
