import { GET_REPORT_CONFIGURATION } from "../../actions/configurations/types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
}
