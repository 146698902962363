import { GET_ALL_FORMGROUP_PROGRAM } from "../../../actions/types";

const initialState = [];
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_FORMGROUP_PROGRAM:
      return action.payload;
    default:
      return state;
  }
}
