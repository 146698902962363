import { GET_PAYROLL_CONTENT_USER_TYPE } from "../../actions/types";

const initialState = [];

export const contentUserType = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYROLL_CONTENT_USER_TYPE:
      return action.payload;
    default:
      return state;
  }
};