export const UPDATE_DATA = "update_data";
export const GET_SUBJECT_LINKING = "get_subject_linking";
export const GET_ELECTIVESUBJECT_GROUP = "GET_ELECTIVESUBJECT_GROUP";
export const GET_ALL_SUBJECT_GROUP = "get_all_subject_group";
export const GET_ALL_SUBJECT_GROUP_TRAN = "get_all_subject_group_tran";
export const GET_ID_CHOICE_CONFIGURATION = "get_id_choice_configuration";
export const GET_ALL_SUBJECT_BY_SUBGRP_COMBINATIONID =
  "get_all_subject_by_subgrp_combinationid";
export const GET_ALL_SUBJ_GROUP_MST_TRAN_BY_COMB_ID =
  "get_all_subj_group_mst_tran_by_comb_id";
