import React from "react";
import ReactDOM from "react-dom";
import "./css/font-awesome.css";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import { LicenseManager } from "ag-grid-enterprise";
import App from "./App";
import { LOGOUT } from "./login/types";
import axios from "axios";

import "axios-progress-bar/dist/nprogress.css";
import "react-notifications/lib/notifications.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
//import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-select/dist/react-select.css";
import "react-tabs/style/react-tabs.css";
import "react-toggle/style.css";
import "../src/css/ag-theme-clean.css";
//import "../src/css/ag-theme-purple.css";
//import "../src/css/ag-theme-clean.css";
import "./css/bootstrap.min.css";
import "./css/theme.css";
import "./css/icons.css";
import "./css/font-awesome.min.css";
import "./css/custom.css";
import "../src/css/newtheme.css";

LicenseManager.setLicenseKey("sdfgsdfg4234452345kj2g5jh23kj4h5b245");

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// window.console.log = function () {
//   console.error('Sorry , developers tools are blocked here....');
//   //console.log("%cWarning message", "font: 2em sans-serif; color: yellow; background-color: red;");
//   window.console.log = function () {
//     return false;
//   }
// }
// window.console.warn = function () {
//   console.error('Sorry , developers tools are blocked here....');
//   window.console.warn = function () {
//     return false;
//   }
// }
//delete window.console;
// Object.defineProperty(console, '_commandLineAPI',
//   { get: function () { throw 'Nooo!' } })
console.log("%c\n                  ,d\"=≥,.,qOp,\n                 ,7'  ''²$(  )\n                ,7'      '?q$7'\n             ..,$$,.\n   ,.  .,,--***²\"\"²***--,,.  .,\n ²   ,p²''              ''²q,   ²\n:  ,7'                      '7,  :\n ' $      ,db,      ,db,      $ '\n  '$      ²$$²      ²$$²      $'    Using controla at work?.\n  '$                          $'        https://controla.in\n   '$.     .,        ,.     .$'\n    'b,     '²«»«»«»²'     ,d'\n     '²?bn,,          ,,nd?²'\n       ,7$ ''²²²²²²²²'' $7,\n     ,² ²$              $² ²,\n     $  :$              $:  $\n     $   $              $   $\n     'b  q:            :p  d'\n      '²«?$.          .$?»²'\n         'b            d'\n       ,²²'?,.      .,?'²²,\n      ²==--≥²²==--==²²≤--==²\n",'font-weight: bold; font-size: 15px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 0px 4px 0 rgb(226,91,14) , 0px 5px 0 rgb(245,221,8) , 0px 6px 0 rgb(5,148,68) , 0px 7px 0 rgb(2,135,206) , 0px 8px 0 rgb(4,77,145) , 0px 9px 0 rgb(42,21,113)');


    console.log('%cThis is restricted area!!', 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 4px 4px 0 rgb(226,91,14) , 5px 6px 0 rgb(245,221,8) , 6px 6px 0 rgb(5,148,68) , 7px 7px 0 rgb(2,135,206) , 8px 8px 0 rgb(4,77,145) , 9px 9px 0 rgb(42,21,113)');
const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
);
const { dispatch } = store;
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.log("error", error);
    if (error.response.status === 403) {
      window.sessionStorage.clear();
      window.localStorage.clear();
      delete axios.defaults.headers.common["token"];
      const res = {};
      res.redirectToLogin = true;
      res.logOut = true;
      res.redirectToApplication = false;
      res.isLoggedIn = false;
      dispatch({ type: LOGOUT, payload: res });
    } else {
      return Promise.reject(error);
    }
    // return Promise.reject(error);
  }
);
