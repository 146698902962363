import {
  GET_SEMESTER_START_END_DATES_BY_ACAD_TRANID,
  ATTENDANCE_REPORT_FOR_ALL_SUBJECTS_LECTURE_WISE,
  GET_SUBJECT_INFO_FOR_ATTENDANCE_REPORT
} from "../../actions/masters/types";

const initialState = [];

export const attendanceReportForAllSubjLectureWise = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ATTENDANCE_REPORT_FOR_ALL_SUBJECTS_LECTURE_WISE:
      return action.payload;
    default:
      return state;
  }
};

export const getSemStartEndDatesByAcadTranId = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SEMESTER_START_END_DATES_BY_ACAD_TRANID:
      return action.payload;
    default:
      return state;
  }
};

export const subjectInfoForAttReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBJECT_INFO_FOR_ATTENDANCE_REPORT:
      return action.payload;
    default:
      return state;
  }
};
