import { combineReducers } from "redux";
import {
  alumniFeesConfiguration,
  courseInfoForAlumni,
  alumniFeesTypes,
  studDetailsByEnrollNo,
  genderAcadYrList,
  alumniFeedbackParameters,
  alumniRegstInfoById,
  alumniRegisteredMemberInfo,
  alumniCounselNotiHistory,
  alumniAddCourseDetails,
  alumniAddCourseInfoAtOtherOrg,
  alumniPlacementInfo,
  alumniQualExamInfo,
  alumniAchievementsInfo,
  alumniContributionInfo,
  contributionTypes,
  outcomeMasterInfo,
  chkAlumniFeedbackInfo,
  alumniUserDetails,
  alumniFeedbackDetailReport,
  alumniCouncilRegstInfo,
  chkDupctAlumniId
} from "./alumniManagement";

export default combineReducers({
  alumniFeesConfiguration,
  courseInfoForAlumni,
  alumniFeesTypes,
  studDetailsByEnrollNo,
  genderAcadYrList,
  alumniFeedbackParameters,
  alumniRegstInfoById,
  alumniRegisteredMemberInfo,
  alumniCounselNotiHistory,
  alumniAddCourseDetails,
  alumniAddCourseInfoAtOtherOrg,
  alumniPlacementInfo,
  alumniQualExamInfo,
  alumniAchievementsInfo,
  alumniContributionInfo,
  contributionTypes,
  outcomeMasterInfo,
  chkAlumniFeedbackInfo,
  alumniUserDetails,
  alumniFeedbackDetailReport,
  alumniCouncilRegstInfo,
  chkDupctAlumniId
});
