import { GET_STUDENT_HOLIDAY } from "../../actions/types";

const initialState = [];

export default function (state = initialState, action) {

    switch (action.type) {
        case GET_STUDENT_HOLIDAY:
            return Array.isArray(action.payload) ? action.payload : state;
        default:
            return state;
    }
}
