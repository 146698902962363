import { YEAR_END_PROCESS_STUDENT_RESPONSE } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case YEAR_END_PROCESS_STUDENT_RESPONSE:
      return action.payload;
    default:
      return state;
  }
}
