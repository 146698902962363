import { GET_ALL_LEAVE_APPLICATION, GET_PAYROLL_USER_INFO_FOR_LEAVE_APPLICATION } 
from "../../actions/types";

const initialState = [];

export const payrollLeaveApplication = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LEAVE_APPLICATION:
      return action.payload;
    default:
      return state;
  }
};
