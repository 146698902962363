import {
  GET_IDSUBJECT_WITH_FACULTY_BY_SEMTYPE,
  GET_IDSUBJECT_FACULTY_DETAIL_BY_MSTID,
  GET_IDSUBJECT_ATTENDANCE_BY_DATE_WISE,
  GET_STUDENTS_BY_COMBINATION,
  ID_SUBJECT_TIME_OVERLAP
} from "../../actions/attendance/types";

const initialState = [];

export const getIdSubjectWithFacultyBySemType = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_IDSUBJECT_WITH_FACULTY_BY_SEMTYPE:
      return action.payload;
    default:
      return state;
  }
};

export function getIdSubjectFacultyDetailByMstId(state = initialState, action) {
  switch (action.type) {
    case GET_IDSUBJECT_FACULTY_DETAIL_BY_MSTID:
      return action.payload;
    default:
      return state;
  }
}

export function getIdSubjectAttendanceByDateWise(state = initialState, action) {
  switch (action.type) {
    case GET_IDSUBJECT_ATTENDANCE_BY_DATE_WISE:
      return action.payload;
    default:
      return state;
  }
}

export function getStudentsByCombination(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENTS_BY_COMBINATION:
      return action.payload;
    default:
      return state;
  }
}

export function idSubjectTimeOverLap(state = initialState, action) {
  switch (action.type) {
    case ID_SUBJECT_TIME_OVERLAP:
      return action.payload;
    default:
      return state;
  }
}
