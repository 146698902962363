import {
    GET_SUBJECT_EXAM_PAPER,
    GET_STUDENT_MARKS_DETAIL_COUNT,
    GET_STUDENT_MARKS_DETAIL,
    GET_STUDENT_MARKS_HEADER_DETAIL,
    GET_GRADING_DETAIL_LIST,
    GET_PARAMETER_LIST,
    GET_EXAM_PARAMETER_LIST,
    GET_SEMESTER_GRADE_REPORT,
    GET_SEMESTER_GRADE_BACKLOG,
    GET_SEMESTER_RECORD_COUNT,
    GET_STUDENT_BACKLOG,
    GET_BACKLOG_HEADER_DETAIL,
    GET_SEMESTER_STUDENT_MARKS_DETAIL,
    GET_ATKT_SUBJECT_EXAM_PAPER,
    GET_ATKT_STUDENT_MARKS_DETAIL_COUNT,
    GET_ATKT_STUDENT_MARKS_DETAIL,
    GET_ATKT_STUDENT_MARKS_HEADER_DETAIL,
    GET_ATKT_GRADING_DETAIL_LIST,
    GET_ATKT_PARAMETER_LIST,
    GET_ATKT_EXAM_PARAMETER_LIST,
    GET_ATKT_STUDENT_LIST,
    GET_ATKT_SEMESTER_GRADE_REPORT,
    GET_ATKT_SEMESTER_GRADE_BACKLOG,
    GET_ATKT_SEMESTER_RECORD_COUNT,
    GET_ATKT_STUDENT_BACKLOG,
    GET_ATKT_BACKLOG_HEADER_DETAIL,
    GET_ATKT_SEMESTER_STUDENT_MARKS_DETAIL,
    GET_ATKT_STUDENT_DETAIL_GRADE_REPORT,
    GET_STUDENT_DETAIL_GRADE_REPORT,
    GET_STUDENT_EXAM_SEATNO
  } from "../../actions/exams/types";
  
  const initialState = {
    StudentMarksDetailReport :[]
  };

  export const getSemesterSubjectExamPaper = function(state = initialState, action) {
    switch (action.type) {
      case GET_SUBJECT_EXAM_PAPER:
        return action.payload;
      default:
        return state;
    }
  };

  export const getSemesterStudentMarksDetailCount = function(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_STUDENT_MARKS_DETAIL_COUNT:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterStudentMarksDetail = function(state = initialState, action) {
    switch (action.type) {
      case GET_STUDENT_MARKS_DETAIL:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterStudentMarksHeaderDetail = function(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_STUDENT_MARKS_HEADER_DETAIL:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterGradingDetail = function(state = initialState, action) {
    switch (action.type) {
      case GET_GRADING_DETAIL_LIST:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterParameterList = function(state = initialState, action) {
    switch (action.type) {
      case GET_PARAMETER_LIST:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterExamParameterList = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_EXAM_PARAMETER_LIST:
        return action.payload;
      default:
        return state;
    }
  };

  export const getStudentExamSeatNo = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_STUDENT_EXAM_SEATNO:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterGradeReport = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_SEMESTER_GRADE_REPORT:
        return action.payload;
      default:
        return state;
    }
  };

export const getSemesterGradeBacklog = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_SEMESTER_GRADE_BACKLOG:
      return action.payload;
    default:
      return state;
  }
};

  export const getSemesterStudentBacklogCount = function(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_SEMESTER_RECORD_COUNT:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterStudentBacklog = function(state = initialState, action) {
    switch (action.type) {
      case GET_STUDENT_BACKLOG:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getSemesterBacklogHeaderDetail = function(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_BACKLOG_HEADER_DETAIL:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getStudentMarksGradeDetail = function(state = initialState, action) {
    switch (action.type) {
      case GET_SEMESTER_STUDENT_MARKS_DETAIL:
        return action.payload;
      default:
        return state;
    }
  };

  export const getATKTSemesterSubjectExamPaper = function(state = initialState, action) {
    switch (action.type) {
      case GET_ATKT_SUBJECT_EXAM_PAPER:
        return action.payload;
      default:
        return state;
    }
  };

  export const getATKTSemesterStudentMarksDetailCount = function(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ATKT_STUDENT_MARKS_DETAIL_COUNT:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getATKTSemesterStudentMarksDetail = function(state = initialState, action) {
    switch (action.type) {
      case GET_ATKT_STUDENT_MARKS_DETAIL:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getATKTSemesterStudentMarksHeaderDetail = function(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ATKT_STUDENT_MARKS_HEADER_DETAIL:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getATKTSemesterGradingDetail = function(state = initialState, action) {
    switch (action.type) {
      case GET_ATKT_GRADING_DETAIL_LIST:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getATKTSemesterParameterList = function(state = initialState, action) {
    switch (action.type) {
      case GET_ATKT_PARAMETER_LIST:
        return action.payload;
      default:
        return state;
    }
  };
  
  export const getATKTSemesterExamParameterList = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ATKT_EXAM_PARAMETER_LIST:
        return action.payload;
      default:
        return state;
    }
  };

  export const getATKTStudentList = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ATKT_STUDENT_LIST:
        return action.payload;
      default:
        return state;
    }
  };

  export const getATKTSemesterGradeReport = function (
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_ATKT_SEMESTER_GRADE_REPORT:
        return action.payload;
      default:
        return state;
    }
  };

export const getATKTSemesterGradeBacklog = function (
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ATKT_SEMESTER_GRADE_BACKLOG:
      return action.payload;
    default:
      return state;
  }
};

export const getATKTSemesterStudentBacklogCount = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ATKT_SEMESTER_RECORD_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export const getATKTSemesterStudentBacklog = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATKT_STUDENT_BACKLOG:
      return action.payload;
    default:
      return state;
  }
};

export const getATKTSemesterBacklogHeaderDetail = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ATKT_BACKLOG_HEADER_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getATKTStudentMarksGradeDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATKT_SEMESTER_STUDENT_MARKS_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getATKTStudentDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATKT_STUDENT_DETAIL_GRADE_REPORT:
      return action.payload;
    default:
      return state;
  }
};

export const getRegularStudentDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_DETAIL_GRADE_REPORT:
      return action.payload;
    default:
      return state;
  }
};