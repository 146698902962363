import { GET_ELECTIVESUBJECT_GROUP } from "../../actions/subject/types";

const initialState = [];

export const listElectiveSubjectGroup = function(state = initialState, action) {
  switch (action.type) {
    case GET_ELECTIVESUBJECT_GROUP:
      return action.payload;
    default:
      return state;
  }
};
