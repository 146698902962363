import {
  GET_ALL_ISR_TYPES_OF_ACTIVITIES_INFO,
  GET_ALL_ISR_TYPES_OF_PARTICIPANTS_DETAILS,
  GET_ALL_ISR_MEMBERS_INVOLVED_IN_ACTIVITY_DETAILS,
  GET_ALL_ISR_FACULTY_PARTICIPANTS_DETAILS,
  GET_ALL_ISR_ACTIVITIES_DETAILS,
  GET_ALL_ISR_ACT_HUMAN_RESOURCES_COST_DETAILS
} from "../../actions/types";

const initialState = [];

export const isrTypesOfActivityInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ISR_TYPES_OF_ACTIVITIES_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const isrTypesOfParticipantsInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ISR_TYPES_OF_PARTICIPANTS_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const isrMemberInActivityInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ISR_MEMBERS_INVOLVED_IN_ACTIVITY_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const isrFacultyParticipantsInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ISR_FACULTY_PARTICIPANTS_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const isrActivityMstInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ISR_ACTIVITIES_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const isrAct_HumanResourceCostInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ISR_ACT_HUMAN_RESOURCES_COST_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
