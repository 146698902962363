import {
  GET_ACCOMBINATION_BY_ID,
  GET_ACCOMBINATION_BY_ACAD_ID
} from "../../actions/types";

const initialState = { data: false };
const initState = [];
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOMBINATION_BY_ID:
      return action.payload;
    default:
      return state;
  }
}

export function acadCombByAcadId(state = initState, action) {
  switch (action.type) {
    case GET_ACCOMBINATION_BY_ACAD_ID:
      return action.payload;
    default:
      return state;
  }
}
