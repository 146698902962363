import reportConfiguration from "./reportConfiguration";
import languageConfiguration from "./languageConfiguration";
import { combineReducers } from "redux";
import systemLanguageConfig from "./systemLanguageConfig";
import clientSettings from "./clientSettings";
import clientSettingsSmsProvider from "./clientSettingsSmsProvider";

export default combineReducers({
  reportConfiguration,
  languageConfiguration,
  systemLanguageConfig,
  clientSettings,
  clientSettingsSmsProvider
});
