import * as actionTypes from "../action/type";

const initialState = {
  electionList: [],
  electionPositions: [],
  electionParties: [],
  participatedCandidates: [],
  electionCandidates: [],
  insert: {
    message: "",
  },
  isVotingdone: 0,
};

export const election = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ELECTION_LIST:
      return {
        ...state,
        electionList: [...action.payload],
      };
    case actionTypes.ELECTION_POSITION_LIST:
      return {
        ...state,
        electionPositions: [...action.payload],
      };
    case actionTypes.ELECTION_PARTIES:
      return {
        ...state,
        electionParties: [...action.payload],
      };
    case actionTypes.PARTICIPATED_CANDIDATE_LIST:
      return {
        ...state,
        participatedCandidates: [...action.payload],
      };
    case actionTypes.ELECTION_CANDIDATES:
      return {
        ...state,
        electionCandidates: [...action.payload],
      };
    case actionTypes.VOTING_DETAILS:
      return {
        ...state,
        isVotingdone: action.payload,
      };
    default:
      return state;
  }
};
