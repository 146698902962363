import { combineReducers } from "redux";
//import paperMaster from "./paperMaster";
import { listPaperMaster } from "./paperMaster";
import { examType } from "./examType";
import {
  examTypeDetail,
  withoutReExamTypeDetail,
  withReExamTypeDetail
} from "./examType";

import {
  examMaster,
  getExamByProgramCode,
  atktExamMstInfo,
  marksheetConfigInfo,
  studExamEligibilityInfo
} from "./examMaster";
import { reExam, getAllReExam } from "./reExam";
import {
  listExams,
  semesterTypes,
  paperListOfExams,
  atktExamMstList,
  subjectListForAtktPapers,
  atktExamPaperInfo
} from "./paperMaster";

import {
  marksConversionExamList,
  marksConversionSubjectList,
  allMarksConversionSettingList
} from "./marksConversionSetting";

import {
  listMarksEntry,
  listPaperAgainstExam,
  listStudentAgainstPaper,
  getExamsByCombination,
  getPaperBySubjectOrExam,
  getSubjectByCombination,
  getAllSpatialCase
} from "./examMarksEntry";
import {
  getIdExamMasterData,
  getIdExamDataBySemester,
  getAllIdPaperData,
  deletePaperFromGrid,
  getAllDropDownDataOfIDPaperMarks,
  getAllStudentsForIDMarksEntry,
  getAllGradeForSPCase
} from "./idExamMaster";

import { getAllPayrollGrading } from "./gradingMaster";
import { programGrading } from "./programGrading";
import { programGradingPolicyDetails } from "./programGradingPolicyDetails";
import { payrollProgramGrades } from "./payrollProgramGrades";

import { examWeightageConfiguration } from "./examWeightageConfiguration";
import {
  getExamByCombinationOrExamType,
  getPaperByCombinationOrExamTypeOrExamId,
  getDataByCombinationForLockOrUnlock
} from "./examLocking";

import {
  getIdSemesterType,
  getIdSubject,
  getIdPaper,
  getIdStudentMarksDetailCount,
  getIdStudentMarksHeaderDetail,
  getIdStudentMarksDetail,
  getIdProgramGradeList
} from "./idSubjectMarksReport";

import {
  getSubjectExamPaper,
  getStudentMarksDetail,
  getStudentMarksHeaderDetail,
  getGradingDetail,
  getSubjectConfigDetail,
  getParameterList,
  getStudentGradeDetail,
  getProgramGradeDetail,
  getExamGradeSubjectList,
  getStudentMarksDetailCount,
  getCGPSubjectCreditList,
  getStudentSemesterList,
  getStudentSubjectGradeList,
  getStudentSubjectWiseList,
  getSemesterPassFailSummary,
  getPassFailAnalysisDetail,
  getStudentMarksGradeCount,
  getStudentMarksGradeDetailList,
  getStudentSubjectDetailList,
  getStudentSubjectExamTypeCount,
  getStudentMarksExamTypeList,
  getStudentSubjectExamTypeList,
  getStudentSubjectExamTypeTotalMarks,
  getExamParameter,
  getExamParameterList,
  getAcademicSubjectList,
  getStudentTranscriptGradeList,
  StudentMarksDetailReport,
  formatted_StudentMarksDetailReport,
  getOddEvenExamTypeCount,
  getOddEvenExamTypeList,
  getOddEvenExamTypeHeader,
  getOddEvenExamTypes,
  getSemesterMarksheet,
  getStudentSubjectGroupList,
  getSignDetail,
  getSubList,
  getFeedBackList,
  getSubjectFeedBackList
} from "./subjectWiseMarksReport";

import {
  getSemesterSubjectExamPaper,
  getSemesterStudentMarksDetailCount,
  getSemesterStudentMarksDetail,
  getSemesterStudentMarksHeaderDetail,
  getSemesterGradingDetail,
  getSemesterParameterList,
  getSemesterExamParameterList,
  getStudentExamSeatNo,
  getSemesterGradeReport,
  getSemesterGradeBacklog,
  getSemesterStudentBacklogCount,
  getSemesterStudentBacklog,
  getSemesterBacklogHeaderDetail,
  getStudentMarksGradeDetail,
  getATKTSemesterSubjectExamPaper,
  getATKTSemesterStudentMarksDetailCount,
  getATKTSemesterStudentMarksDetail,
  getATKTSemesterStudentMarksHeaderDetail,
  getATKTSemesterGradingDetail,
  getATKTSemesterParameterList,
  getATKTSemesterExamParameterList,
  getATKTStudentList,
  getATKTSemesterGradeReport,
  getATKTSemesterGradeBacklog,
  getATKTSemesterStudentBacklogCount,
  getATKTSemesterStudentBacklog,
  getATKTSemesterBacklogHeaderDetail,
  getATKTStudentMarksGradeDetail,
  getATKTStudentDetail,
  getRegularStudentDetail
} from "./semesterSubjectMarksWiseGrade";

import {
  getPassOutStudentSubjectList,
  getPassOutStudentGradeList,
  getAcademicERPData
} from "./PassOutStudentGradeEntry";
import { gradeParameter, gradeParameterSubjects } from "./gradeParameter";

import {
  getStudentSubjectGrade,
  getReRegStudentSubjectGrade,
  getStudentSemesterDetail,
  getSubjectReExamList,
  getStudentReExamList,
  getStudentSubjectReExamList,
  reExamRegstConfigurationInfo
} from "./reExamRegistration";
import { getSubjectiveRemarks } from "./subjectiveRemarks";
import { getAllReportCardData } from "./reportCardResult";
export default combineReducers({
  //paperMaster,
  listPaperMaster,
  examType,
  examTypeDetail,
  examMaster,
  withoutReExamTypeDetail,
  withReExamTypeDetail,
  reExam,
  listExams,
  listMarksEntry,
  listPaperAgainstExam,
  listStudentAgainstPaper,
  getIdExamMasterData,
  getIdExamDataBySemester,
  getAllIdPaperData,
  marksConversionExamList,
  marksConversionSubjectList,
  allMarksConversionSettingList,
  deletePaperFromGrid,
  getAllDropDownDataOfIDPaperMarks,
  getAllStudentsForIDMarksEntry,
  getAllGradeForSPCase,
  getExamsByCombination,
  getPaperBySubjectOrExam,
  getSubjectByCombination,
  getAllPayrollGrading,
  programGrading,
  programGradingPolicyDetails,
  payrollProgramGrades,
  examWeightageConfiguration,
  getExamByCombinationOrExamType,
  getPaperByCombinationOrExamTypeOrExamId,
  getDataByCombinationForLockOrUnlock,
  getSubjectExamPaper,
  getStudentMarksDetail,
  getStudentMarksHeaderDetail,
  getGradingDetail,
  getSubjectConfigDetail,
  getParameterList,
  getAllSpatialCase,
  gradeParameter,
  gradeParameterSubjects,
  getStudentSubjectGrade,
  getReRegStudentSubjectGrade,
  getAllReExam,
  getSubjectiveRemarks,
  getAllReportCardData,
  getStudentGradeDetail,
  getProgramGradeDetail,
  getExamByProgramCode,
  getExamGradeSubjectList,
  getStudentMarksDetailCount,
  getCGPSubjectCreditList,
  getPassOutStudentSubjectList,
  getPassOutStudentGradeList,
  getStudentSemesterList,
  getStudentSubjectGradeList,
  getStudentSubjectWiseList,
  getSemesterPassFailSummary,
  getPassFailAnalysisDetail,
  getStudentMarksGradeCount,
  getStudentMarksGradeDetailList,
  getStudentSubjectDetailList,
  getStudentSubjectExamTypeCount,
  getStudentMarksExamTypeList,
  getStudentSubjectExamTypeList,
  getStudentSubjectExamTypeTotalMarks,
  getExamParameter,
  getIdSemesterType,
  getIdSubject,
  getIdPaper,
  semesterTypes,
  paperListOfExams,
  getIdStudentMarksDetailCount,
  getIdStudentMarksHeaderDetail,
  getIdStudentMarksDetail,
  getIdProgramGradeList,
  getExamParameterList,
  getAcademicERPData,
  getAcademicSubjectList,
  getStudentTranscriptGradeList,
  StudentMarksDetailReport,
  formatted_StudentMarksDetailReport,
  getOddEvenExamTypeCount,
  getOddEvenExamTypeList,
  getOddEvenExamTypeHeader,
  getOddEvenExamTypes,
  getStudentSemesterDetail,
  getSubjectReExamList,
  getStudentReExamList,
  getStudentSubjectReExamList,
  getStudentSubjectGroupList,
  getSemesterSubjectExamPaper,
  getSemesterStudentMarksDetailCount,
  getSemesterStudentMarksDetail,
  getSemesterStudentMarksHeaderDetail,
  getSemesterGradingDetail,
  getSemesterParameterList,
  getSemesterExamParameterList,
  getStudentExamSeatNo,
  getSemesterGradeReport,
  getSemesterGradeBacklog,
  getSemesterBacklogHeaderDetail,
  getStudentMarksGradeDetail,
  getSemesterStudentBacklogCount,
  getSemesterStudentBacklog,
  atktExamMstInfo,
  atktExamMstList,
  subjectListForAtktPapers,
  atktExamPaperInfo,
  getSemesterMarksheet,
  reExamRegstConfigurationInfo,
  getATKTSemesterSubjectExamPaper,
  getATKTSemesterStudentMarksDetailCount,
  getATKTSemesterStudentMarksDetail,
  getATKTSemesterStudentMarksHeaderDetail,
  getATKTSemesterGradingDetail,
  getATKTSemesterParameterList,
  getATKTSemesterExamParameterList,
  getATKTStudentList,
  getATKTStudentDetail,
  getATKTSemesterGradeReport,
  getATKTSemesterGradeBacklog,
  getATKTSemesterStudentBacklogCount,
  getATKTSemesterStudentBacklog,
  getATKTSemesterBacklogHeaderDetail,
  getATKTStudentMarksGradeDetail,
  getRegularStudentDetail,
  marksheetConfigInfo,
  studExamEligibilityInfo,
  getSignDetail,
  getSubList,
  getFeedBackList,
  getSubjectFeedBackList
});
