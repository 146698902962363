export const UPDATE_DATA = "update_data";
export const GET_ALL_ALUMNI_FEES_CONFIGURATION =
  "get_all_alumni_fees_configuration";
export const GET_ALL_COURSE_INFO = "get_all_course_info";
export const GET_ALL_ALUMNI_FEES_TYPES = "get_all_alumni_fees_types";
export const GET_STUD_INFO_BY_ENROLLNO = "get_all_student_details_by_enroll_no";
export const GET_ACADYEAR_AND_GENDER_LIST =
  "get_all_academic_year_and_gender_list";
export const GET_ALUMNI_FEEDBACK_PARAMETERS =
  "get_all_alumni_feedback_parameters";
export const GET_ALUMNI_REGISTRATION_DATA_BY_ID =
  "get_all_alumni_registration_data_by_id";
export const GET_ALUMNI_REGISTERED_MEMBERS_INFO =
  "get_all_alumni_registered_members_info";
export const GET_COUNSEL_NOTIFICATION_FOR_ALUMNI_MGMT =
  "get_all_counsel_notification_form_alumni_mgmt";
export const GET_ALUMNI_ADDITIONAL_COURSE_DETAILS_AT_SUMANVIDYAP =
  "get_alumni_additional_course_details_at_sumandeep_vidyap";
export const GET_ALUMNI_ADD_COURSE_DETAILS_AT_OTHER_ORG =
  "get_alumni_additional_course_details_at_other_org";
export const GET_ALUMNI_PLACEMENT_DETAILS = "get_alumni_placement_details";
export const GET_ALUMNI_QUALIFYING_EXAM_DETAILS =
  "get_alumni_qualifying_examination_details";
export const GET_ALUMNI_ACHIEVEMENT_DETAILS = "get_alumni_achievement_details";
export const GET_ALUMNI_CONTRIBUTION_DETAILS =
  "get_alumni_contribution_details";
export const GET_CONTRIBUTION_TYPES = "get_all_contribution_types";
export const GET_OUTCOME_MASTER_INFO =
  "get_all_outcome_master_info_for_qual_exam";
export const CHECK_ALUMNI_FEEDBACK_INFO = "check_alumni_feedback_information";
export const GET_ALUMNI_DETAILS = "get_alumni_details_by_un";
export const GET_ALUMNI_FEEDBACK_INFO_REPORT =
  "get_all_alumni_feedback_info_for_report";
export const GET_ALUMNI_COUNCIL_REGISTRATION_CERTIFICATES_DETAILS =
  "get_all_alumni_council_regst_certificate_details";
export const CHECK_DUPLICATE_ALUMNI_ID_ADMIN =
  "check_duplicate alumni_id_admin";
