import {
  GET_ALL_ACAD_COMB_BY_ACYR_ORGID,
  GET_ALL_CLASS_FACULTY_INFO,
  GET_ALLOTED_ACADCOMB_TO_FACULTY_INFO,
  GET_ALLOTED_CLASS_FACULTY_INFO_BY_MST_ID,
  GET_SCHOOL_STUD_ATTENDANCE_INFO_BY_DATE_WISE,
  GET_SCHOOL_STUD_LIST_BY_DIV_DATE_WISE,
  GET_SCHOOL_STUD_ATT_SUMMARY_FOR_CALENDER,
  GET_SCHOOL_STUD_LEAVE_TYPE_MST_INFO,
  GET_SCHOOL_STUD_ATT_REPORT_BY_MONTH_WISE,
  IMPORT_SCHOOL_STUD_ATTENDANCE_SUMMARY_RESPONSE,
  IMPORT_STUD_PREV_SCHOOL_ATTENDANCE_RESPONSE,
  GET_SCHOOL_STUD_ATTEN_IMPORT_HISTORY,
  GET_STUD_PREV_SCHOOL_ATTEN_IMPORT_HISTORY,
  GET_ALL_UPLOADED_PREVIOUS_SCHOOL_ATT_SUMM,
  GET_ALL_UPLOADED_SCHOOL_ATT_SUMMARY_INFO
} from "../../actions/attendance/types";

const initialState = [];

export const acadCombForSchoolAttendance = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ACAD_COMB_BY_ACYR_ORGID:
      return action.payload;
    default:
      return state;
  }
};

export const schoolFacultyInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLASS_FACULTY_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const allotedClassToFacultyInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOTED_ACADCOMB_TO_FACULTY_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const schoolFacultyAllotedByMstId = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALLOTED_CLASS_FACULTY_INFO_BY_MST_ID:
      return action.payload;
    default:
      return state;
  }
};

export const schoolStudAttInfoByDateWise = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_STUD_ATTENDANCE_INFO_BY_DATE_WISE:
      return action.payload;
    default:
      return state;
  }
};

export const schoolStudentListByDiv = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_STUD_LIST_BY_DIV_DATE_WISE:
      return action.payload;
    default:
      return state;
  }
};

export const schoolStudAttSummaryForCal = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_STUD_ATT_SUMMARY_FOR_CALENDER:
      return action.payload;
    default:
      return state;
  }
};

export const schStudLeaveTypeMstInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_STUD_LEAVE_TYPE_MST_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const schoolStudAttenReportByMonthWise = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_SCHOOL_STUD_ATT_REPORT_BY_MONTH_WISE:
      return action.payload;
    default:
      return state;
  }
};

export const studAttSummImportHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCHOOL_STUD_ATTEN_IMPORT_HISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const prevStudAttImportHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUD_PREV_SCHOOL_ATTEN_IMPORT_HISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const importSchoolStudAttResponse = (state = initialState, action) => {
  switch (action.type) {
    case IMPORT_SCHOOL_STUD_ATTENDANCE_SUMMARY_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

export const importPrevSchoolStudAttResponse = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case IMPORT_STUD_PREV_SCHOOL_ATTENDANCE_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

export const uploadedSchoolAttInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_UPLOADED_SCHOOL_ATT_SUMMARY_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const uploadedPrevSchoolAttInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_UPLOADED_PREVIOUS_SCHOOL_ATT_SUMM:
      return action.payload;
    default:
      return state;
  }
};
