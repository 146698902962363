import { combineReducers } from "redux";
import allotedSubjectByStudentId from "./allotedSubjectByStudentId";
import configSubjectAgainstComb from "./configSubjectAgainstComb";
import studentByCombination from "./studentByCombination";
import facultyList from "./getAllFaculty";
import getIdSubject from "./getIdSubject";
import {
  getFacultyList,
  getProgramList,
  getIDSubjectBySemType
} from "./iDSubjectAllotFaculty";
import {
  getIDSubjectBySemester,
  getIDSubjectPrefranceByStudent,
  getStudentId,
  getIDStudentData,
  getAllIDStudent
} from "./IdSubjectAllotStudent";

export default combineReducers({
  allotedSubjectByStudentId,
  configSubjectAgainstComb,
  studentByCombination,
  facultyList,
  getIdSubject,
  getFacultyList,
  getProgramList,
  getIDSubjectBySemType,
  getIDSubjectBySemester,
  getIDSubjectPrefranceByStudent,
  getStudentId,
  getIDStudentData,
  getAllIDStudent
});
