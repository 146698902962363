export const UPDATE_DATA = "update_data";
export const GET_SCHOLASTIC_COSCHOLASTIC_ACTIVITY_MASTER =
  "get_scholastic_coscholastic_activity_master";
export const GET_SCHOLASTIC_COSCHOLASTIC_GROUPACTIVITY_MASTER =
  "get_scholastic_coscholastic_groupactivity_master";
export const GET_SCHOLASTIC_COSCHOLASTIC_SUBJECT_INDICATOR =
  "get_scholastic_coscholastic_subject_indicator";
export const GET_CO_SCHOLASTIC_REPORT_HEADER =
  "get_co_scholastic_report_header";
export const GET_CO_SCHOLASTIC_REPORT_DATA = "get_co_scholastic_report_data";
export const GET_DEFINED_REMARKS_CRITERIA_INFO =
  "get_all_define_remarks_criteria_information";
export const GET_DEFINED_REMARKS_CRITERIA_IMPORT_HISTORY =
  "get_defined_remarks_criteria_import_history";
export const IMPORT_DEFINED_REMARKS_CRITERIA_RESPONSE =
  "import_defined_remarks_criteria_response";
export const GET_ALL_STUDENTS_REPORT_CARD_REMARK_INFO =
  "get_all_students_report_card_remarks_info";
export const GET_ALL_REPORT_CARD_MST_INFO = "get_all_report_card_master_info";
