import {
  GET_PAPERMASTER_INFO,
  GET_EXAMMASTER_INFO,
  SEMESTER_TYPE,
  PAPER_LIST_OF_EXAM,
  GET_ATKT_EXAM_MST_DETAILS,
  GET_RE_REGISTERED_SUBJECTS_FOR_ATKT_PAPERS,
  GET_ATKT_EXAM_PAPER_INFO
} from "../../actions/exams/types";

const initialState = [];

export const listPaperMaster = function(state = initialState, action) {
  switch (action.type) {
    case GET_PAPERMASTER_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const semesterTypes = function(state = initialState, action) {
  switch (action.type) {
    case SEMESTER_TYPE:
      return action.payload;
    default:
      return state;
  }
};

export const paperListOfExams = function(state = initialState, action) {
  switch (action.type) {
    case PAPER_LIST_OF_EXAM:
      return action.payload;
    default:
      return state;
  }
};

export const listExams = function(state = initialState, action) {
  switch (action.type) {
    case GET_EXAMMASTER_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const atktExamMstList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATKT_EXAM_MST_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const subjectListForAtktPapers = function(state = initialState, action) {
  switch (action.type) {
    case GET_RE_REGISTERED_SUBJECTS_FOR_ATKT_PAPERS:
      return action.payload;
    default:
      return state;
  }
};

export const atktExamPaperInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_ATKT_EXAM_PAPER_INFO:
      return action.payload;
    default:
      return state;
  }
};
