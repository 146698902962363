import { combineReducers } from "redux";
import admissionConfig from "./admissionConfig";
import admissionConfigList from "./admissionConfigList";
import admissionConfigById from "./admissionConfigById";
import admissionQualifConfigMaster from "./admissionQualifConfigMaster";
import admissionQualiMaster from "./admissionQualiMaster";
import common from "../getCommonAll";
import achivementCategory from "./AchivementCategory";
import qualificationConfiguration from "./qualificationConfiguration";
import admissionMeritConfig from "./admissionMeritConfig";
import admissionMeritConfigById from "./admissionMeritConfigById";
import getSection from "./getSection";
import getCourseBySectionId from "./getCourseBySectionId";
import enquiryFollowup from "./enquiryFollowup";
import enquiryFollowupEntry from "./enquiryFollowupEntry";
import enquiryFollowupById from "./enquiryFollowupById";
import getAllAdvertisement from "./getAllAdvertisement";
import getStatusMasterDetails from "./getStatusMasterDetails";
import admissionFormGroupList from "./admissionFormGroupList";
import subjectList from "./subjectList";
import admissionQualMaster from "./admissionQualMaster";
import qualificationConfig from "./qualificationConfig";
import admissionQuotaMasterList from "./admissionQuotamaster";
import academicYearList from "./academicYearList";
import seatAvaliableList from "./seatAvaliableList";
import counselingStagesMaster from "./counselingStagesMaster";
import admissionInfoByFormGroup from "./admissionInfoByFormGroupId";
import getAllEnquiryList from "./getAllEnquiryList";
import admissionFormSectionList from "./admissionFormSectionConfig_SectionList";
import studentVisiableDetail from "./admissionFormSectionConfig_Insert_SectionList";
import getAdmissionFormConfigDetails from "./admissionFormSectionConfig_getConfigDetail";
import formDocumentChecklistConfiguration from "./formDocumentChecklistConfiguration";
import commonEntranceTest from "./commonEntranceTest";
import qualificationByFormGroup from "./getQualificationByFromGroup";
import {
  getCoursesByFormGroupId,
  getTotalAppliedStdsByCourseId,
  getSeatsQuotaByCourseId,
  getCETMaster
} from "./admissionMeritTypeConfirmation";
import admissionBoardMaster from "./admissionBoardMaster";
import graceTypeMaster from "./graceTypeMaster";
import { getStudentsByFormGroupIdAndCourseId } from "./admissionCETMarksEntry";
import documentVerificationStudentList from "./admissionStudentDetailList";
import documentVerificationFormGroupList from "./admissionFormGroupDetailList";
import documentVerificationCourseList from "./admissionCourseDetailList";
import documentVerificationDocumentList from "./admissionDocumentDetailList";
import documentVerificationQuotaList from "./admissionQuotaDetailList";
import documentVerificationAdmissionList from "./admissionAdmissionDetailList";
import documentVerificationMaster from "./AdmissionDocumentVerifyMasterList";
import documentVerificationDetail from "./admissionDocumentVerifyDetail";
import { getCourse } from "./courseMaster";
import studentByProgram from "./getAdmissionStudentProgramWise";
import availableSeatsAndAppliedApplicants from "./getAvailableSeatsAndAppliedApplicants";
import admissionMeritApplication from "./getAdmissionMeritApplication";
import getStagesOfCounselling from "./CounsellingAllocation_getStagesOfCounselling";
import getCounsellingAllocationData from "./CounsellingAllocation_getCounsellingAllocationData";
import formGroupProgram from "./getFormGroupProgram";
import allformGroupProgram from "./getAllFormGroupProgram";

import {
  getStudentListForNotification,
  getCounselList,
  getCounselingNotificationHistory,
  getCounselDetail
} from "./counselingNotification";
import {
  getAllCounsellingMasterDetail,
  getAllProgramsOfCounseller,
  getAllStudentOfCounseller,
  getStudentDocument,
  getStudentQuota,
  getStudentSubjectGroup
} from "./counsellingMaster";
import sendCraidencialByEnquityId from "./sendCraidencialByEnquiryId";
import studentAdmission from "./studentAdmission";
import {
  getAdmissionType,
  getProgramByAcYearId
} from "./enrollmentNoConfiguration";
import {
  getStudentByCourseId,
  getProgramAllotEnrollNo,
  getProgramAllotEnrollNoNew
} from "./enrollNoAllotStudent";
import counselingStudents from "./getCounsellingStudent";
import counselingStudentByStudId from "./getCounsellingStudentByStudId";

import getOveralAdmissionReportData from "./overalAdmissionReport";
import admissionTypeMaster from "./admissionTypeMaster";

import cancelledTermFeesReport from "./getCancelledTermFeesReport";

export default combineReducers({
  common,
  admissionConfig,
  admissionConfigList,
  admissionConfigById,
  achivementCategory,
  admissionQualiMaster,
  admissionQualifConfigMaster,
  qualificationConfiguration,
  admissionMeritConfig,
  admissionMeritConfigById,
  getSection,
  getCourseBySectionId,
  enquiryFollowup,
  enquiryFollowupEntry,
  enquiryFollowupById,
  getAllAdvertisement,
  getStatusMasterDetails,
  admissionFormGroupList,
  subjectList,
  admissionQualMaster,
  qualificationConfig,
  admissionQuotaMasterList,
  academicYearList,
  seatAvaliableList,
  counselingStagesMaster,
  getAllEnquiryList,
  formDocumentChecklistConfiguration,
  getAdmissionFormConfigDetails,
  commonEntranceTest,
  admissionInfoByFormGroup,
  seatAvaliableList,
  getAllEnquiryList,
  formDocumentChecklistConfiguration,
  admissionFormSectionList,
  studentVisiableDetail,
  getAdmissionFormConfigDetails,
  qualificationByFormGroup,
  getCoursesByFormGroupId,
  getTotalAppliedStdsByCourseId,
  getSeatsQuotaByCourseId,
  getCETMaster,
  admissionBoardMaster,
  graceTypeMaster,
  getStudentsByFormGroupIdAndCourseId,
  documentVerificationStudentList,
  documentVerificationFormGroupList,
  documentVerificationCourseList,
  documentVerificationDocumentList,
  documentVerificationQuotaList,
  documentVerificationAdmissionList,
  documentVerificationMaster,
  documentVerificationDetail,
  getCourse,
  studentByProgram,
  availableSeatsAndAppliedApplicants,
  admissionMeritApplication,
  getStagesOfCounselling,
  getCounsellingAllocationData,
  formGroupProgram,
  allformGroupProgram,
  getStudentListForNotification,
  getCounselList,
  getCounselingNotificationHistory,
  getCounselDetail,
  getAllCounsellingMasterDetail,
  getAllProgramsOfCounseller,
  getAllStudentOfCounseller,
  sendCraidencialByEnquityId,
  sendCraidencialByEnquityId,
  studentAdmission,
  getAdmissionType,
  getProgramByAcYearId,
  getStudentDocument,
  getStudentQuota,
  getStudentSubjectGroup,
  getStudentByCourseId,
  getProgramAllotEnrollNo,
  getProgramAllotEnrollNoNew,
  counselingStudents,
  counselingStudentByStudId,
  getOveralAdmissionReportData,
  admissionTypeMaster,
  cancelledTermFeesReport
});
