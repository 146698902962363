import { combineReducers } from "redux";
//import mediumConfig from "./mediumConfig";
import addressDetails from "./getAllAddressByUserId";
import otherDetails from "./getOtherDetailsByUserid";
import experience from "./getExperienceByUserId";
import familyDetails from "./getFamilyDetailByUserId";
import specialization from "./getSpecializationByUserId";
import qualifications from "./getQualificationsByUserId";
export default combineReducers({
  //mediumConfig,
  addressDetails,
  otherDetails,
  experience,
  familyDetails,
  specialization,
  qualifications
});
