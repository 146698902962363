import { GET_ALL_PAYROLL_GRADES_FOR_PROGRAMS } from "../../actions/types";

const initialState = [];

export const payrollProgramGrades = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PAYROLL_GRADES_FOR_PROGRAMS:
      return action.payload;
    default:
      return state;
  }
};
