export const FETCH_USER = "fetch_user";
export const LOGOUT = "logout";
export const FETCH_PROGRAMS = "fetch_programs";
export const FETCH_APPLICATIONLIST = "fetch_applicationlist";
export const CHECK_TOKEN = "check_token";
export const UPDATE_DATA = "update_data";
export const GET_DEPARTMENTS = "get_departments";
export const CLEAR_UPDATE = "clear_update";
export const GET_MERITSUBJECTGROUP_LIST = "get_meritsubjectgroup_list";
export const GET_FORMDOCUMENTCHECKLISTCONFIGURATION_LIST = "get_formdocumentlistconfiguration_list";
