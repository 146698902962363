export const GET_APPLICANT_DETAILS = "get_applicant_details";
export const GET_CURRENCY_TYPE = "get_currency_type";
export const GET_DESIGNATIONS_LIST = "get_designations_list";
export const GET_NEW_RECEIPTNO = "get_new_receiptno";
export const GET_ALL_APPLICANTS = "get_all_applicants";
export const GET_GENERAL_CASH_RECEIPT_DETAIL =
  "get_general_cash_receipt_detail";
export const GET_ADMISSION_STUDENT_LIST = "get_admission_student_list";
export const GET_GENERAL_CASH_RECEIPT_COURSE_DETAIL =
  "get_general_cash_receipt_course_detail";
export const GET_STUDENT_FORM_FEES = "get_student_form_fees";
export const GET_STUDENT_PAID_FORM_FEES = "get_student_paid_form_fees";
export const GET_ORGANIZATION_DETAILS = "get_organization_details";
