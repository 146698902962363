import { combineReducers } from "redux";
import { getAllPayrollHead } from "./payrollHead";
import { getAllPayrollSlab } from "./payrollSlab";
import { getAllLeaveTypeMaster } from "./leaveTypeMaster";
import { getUserType } from "./getUserType";
import { leaveConfiguration } from "./leaveConfiguration";
import { getAllPayrollPayType } from "./payrollPayType";
import { getAllPayrollCategory } from "./payrollCategory";
import {
  getStaffList,
  pStaffAttenImportHistory,
  importStaffAttenResponse,
  staffAttendMonthlyInfo
} from "./staffAttendance";
import { getAllPayrollShift } from "./payrollShift";
import { payrollContentType } from "./payrollContentType";
import { contentUserType } from "./payrollContentUserType";

import { getAllPayrollFormula } from "./payrollFormula";
import { getAllPayrollStructure } from "./payrollStructure";
import { getStaffWeekOffMaster } from "./staffWeekOffMaster";
import { payrollLeaveApplication } from "./payrollLeaveApplication";
import { payrollEmpListForLeaveApp } from "./payrollEmpListForLeaveApp";
import { leaveStatus } from "./leaveStatus";

export default combineReducers({
  getAllPayrollHead,
  getAllPayrollSlab,
  getAllLeaveTypeMaster,
  getUserType,
  leaveConfiguration,
  getAllPayrollCategory,
  getAllPayrollPayType,
  getStaffList,
  getAllPayrollShift,
  payrollContentType,
  contentUserType,

  getAllPayrollFormula,
  getAllPayrollStructure,
  getStaffWeekOffMaster,

  payrollLeaveApplication,
  payrollEmpListForLeaveApp,
  leaveStatus,

  pStaffAttenImportHistory,
  importStaffAttenResponse,
  staffAttendMonthlyInfo
});
