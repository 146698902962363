import {
  GET_FACULTY_LIST,
  GET_PROGRAM_LIST,
  GET_IDSUBJECT_BY_SEMTYPE
} from "../../actions/types";

const initialState = [];
//const initialState1 = {};

export function getFacultyList(state = initialState, action) {
  switch (action.type) {
    case GET_FACULTY_LIST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getProgramList(state = initialState, action) {
  switch (action.type) {
    case GET_PROGRAM_LIST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getIDSubjectBySemType(state = initialState, action) {
  switch (action.type) {
    case GET_IDSUBJECT_BY_SEMTYPE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}
