export const UPDATE_DATA = "update_data";
export const GET_ALLOTED_SUBJECT_BY_STUDENTID =
  "get_alloted_subject_by_studentid";
export const GET_CONFIG_SUBJECT = "get_config_subject";
export const GET_STUDENT_BY_COMBINATION = "get_student_by_combination";
export const GET_ALL_FACULTY = "get_all_faculty";
export const GET_ALL_IDSUBJECT = "get_all_idsubject";
export const GET_IDSUBJECT_BY_SEMTYPE = "get_idsubject_by_semtype";
export const GET_FACULTY_LIST = "get_faculty_list";
export const GET_PROGRAM_LIST = "get_program_list";
export const GET_ALL_ID_SUBJECT_OF_SEMESTER = "get_all_id_subject_of_semester";
export const GET_ALL_ID_SUBJECT_PREFRANCE_OF_STUDENT =
  "get_all_id_subject_prefrance_of_student";
export const GET_STUDENT_ID = "get_student_Id";
export const GET_ID_STUDENT_DATA = "get_id_student_data";
export const GET_ID_ALL_STUDENT = "get_id_all_student";
