export const GET_FEES_RECONCILIATION_ONLYCHQ =
  "get_fees_reconciliation_onlychq";
export const GET_CHEQUEMIS_IMPORTHISTORY = "get_chequemis_importhistory";
export const IMPORT_CHEQUEMIS_RESPONSE = "import_chequemis_response";
export const GET_FEESTRANSACTIONDETAIL_REPORT =
  "get_feestransactiondetail_report";
export const GET_PROSPECTORDETAIL = "get_ProspectorDetail";
export const GET_NEXTPROSPECTOR_NO = "get_NextProspector_No";
export const PAYU_SETTLEMENT_DETAILS = "payu_settlement_details";
export const PAYMENT_SETTLED_DETAILS = "payment_settled_details";
export const PAYU_SETTLEMENT_RECONCILIATION = "payu_settlement_reconciliation";
export const ONLINE_TRANSACTION_DETAILS = "online_transaction_details";
export const GET_FEESTRANSACTION_IMPORTFILEHISTORY =
  "get_feestransaction_importfilehistory";
export const IMPORT_FEESTRANSACTION_RESPONSE =
  "import_feestransaction_response";
