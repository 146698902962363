export const UPDATE_DATA = "update_data";
export const SAVE_MEDIUM_CONFIG = "save_medium_config";
export const SAVE_SECTION_CONFIG = "save_section_config";
export const SAVE_SYLLABUS_CONFIG = "save_syllabus_config";
export const SAVE_STREAM_CONFIG = "save_stream_config";
export const SAVE_YEAR_CONFIG = "save_year_config";
export const SAVE_SEMESTER_CONFIG = "save_semester_config";
export const SAVE_SHIFT_CONFIG = "save_shift_config";
export const SAVE_DIVISION_CONFIG = "save_division_config";
export const GET_ACCOMBINATION_BY_ID = "get_accombination_by_id";
export const GET_ACCOMBINATION_BY_ACAD_ID = "get_accombination_by_acad_id";

export const GET_ALL_PROGRAMCODE = "GET_ALL_PROGRAMCODE";
