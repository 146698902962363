import { combineReducers } from "redux";
import applicantDetail from "./getApplicantDetail";
import currencyType from "./getCurrencyType";
import designationList from "./getDesignationsList";
import newReceiptNo from "./getReceiptNo";
import allapplicants from "./getAllApplicants";
import getgeneralcashreport from "./getgeneralcashreceipt";
import getadmissionstudentlist from "./getAdmissionStudentList";
import getgeneralcashreportcourselist from "./getgeneralcashreceiptcourse";
import getStudentformfees from "./getstudentformfees";
import getStudentPaidformfees from "./getstudentpaidformfees";
import getOrganizationDetails from "./getOrganizationDetails";
export default combineReducers({
  //mediumConfig,
  applicantDetail,
  currencyType,
  designationList,
  newReceiptNo,
  allapplicants,
  getgeneralcashreport,
  getadmissionstudentlist,
  getgeneralcashreportcourselist,
  getStudentformfees,
  getStudentPaidformfees,
  getOrganizationDetails
});
