import {
  GET_ALL_HOSTEL_FEES_CONFIGURATION,
  GET_ALL_DURATION_FOR_HOSTEL
} from "../../actions/hostel/types";

const initialState = [];

export const hostelFeesConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HOSTEL_FEES_CONFIGURATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const durationForHostel = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DURATION_FOR_HOSTEL:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
