import { GET_ALL_COUNSELLING_STUDENT } from "../../../actions/masters/Admissions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COUNSELLING_STUDENT:
      return action.payload;
    default:
      return state;
  }
}
