import { GET_STUDENT_BY_COMBINATION } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_BY_COMBINATION:
      return action.payload;
    default:
      return state;
  }
}
