import { GET_ALL_PAYROLL_PAY_TYPE } from "../../actions/types";

const initialState = [];

export const getAllPayrollPayType = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PAYROLL_PAY_TYPE:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
