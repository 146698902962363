import {
  FETCH_USER,
  LOGOUT,
  CHECK_TOKEN,
  FORGET_PASSWORD,
  USER_DETAIL_OTP_FORGOTPASS
} from "./types";

const initialState = {
  isLoggedIn: true
};

export let auth = function(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
    case CHECK_TOKEN:
      return action.payload;
    case LOGOUT:
      // console.log("Logout", action.payload);
      return action.payload || false;
    default:
      return state;
  }
};

const initialStateForgetassword = {
  success: false,
  message: "",
  phone: "",
  email: "",
  username: ""
};

export let forgetPassword = function(
  state = initialStateForgetassword,
  action
) {
  switch (action.type) {
    case FORGET_PASSWORD:
      return action.payload || false;
    default:
      return state;
  }
};

export let userDetailOTPForgotPass = function(state = {}, action) {
  switch (action.type) {
    case USER_DETAIL_OTP_FORGOTPASS:
      return action.payload;
    default:
      return state;
  }
};
