import { GET_SEMESTER, GET_ACADCOMB_SEMESTER } from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SEMESTER:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export const acadCombinationSemester = function(state = initialState, action) {
  switch (action.type) {
    case GET_ACADCOMB_SEMESTER:
      return action.payload;
    default:
      return state;
  }
};
