import { GET_SUBJECT_ALLOTED_BY_MSTID } from "../../actions/attendance/types";

const initialState = {};

export const subjectAllotedByMstId = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBJECT_ALLOTED_BY_MSTID:
      return action.payload;
    default:
      return state;
  }
};
