import { combineReducers } from "redux";
//import mediumConfig from "./mediumConfig";
import feesReConciliationOnlyChq from "./feesReConciliationOnlyChq";
import chequeMISDataImportDetails from "./getChequeMISDataImportDetails";
import importMISDataResponse from "./importMISDataResponse";
import feesTransactionImportDetails from "./getFeesTransactionImportDetails";
import importFeesTransactionResponse from "./importFeesTransactionResponse";
import FeesTransactionDetailReport from "./getFeesTransactionDetailReport";
import ProspectorDetail from "./ProspectorDetail";
import nextProspectorNoDetails from "./nextProspectorNoDetails";
import payuSettlementDetails from "./payuSettlementDetails";
import payuSettlementReconciliation from "./payuSettlementReconciliation";
import paymentSettledDetails from "./paymentSettledDetails";
import onlineTransactionDetails from "./onlineTransactionDetails";

export default combineReducers({
  feesReConciliationOnlyChq,
  chequeMISDataImportDetails,
  importMISDataResponse,
  feesTransactionImportDetails,
  importFeesTransactionResponse,
  FeesTransactionDetailReport,
  ProspectorDetail,
  nextProspectorNoDetails,
  payuSettlementDetails,
  paymentSettledDetails,
  payuSettlementReconciliation,
  onlineTransactionDetails,
});
