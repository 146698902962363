import { GET_APPOINTMENT_TYPE_MST } from "../../actions/types";

const initialState = [];

export default function(state=initialState, action){
    switch(action.type){
        case GET_APPOINTMENT_TYPE_MST: 
            return Array.isArray(action.payload) ? action.payload : state;
        default:
            return state;
    }
}