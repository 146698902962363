import { combineReducers } from "redux";
import {
  onlineAdmCourseInfo,
  onlineAdmSectionInfo,
  onlineAdmissionConfigInfo,
  onlineAdmFormDetails,
  onlineAdmProgramDetails,
  onlineAdmDocumentDetails,
  onlineAdmRegstMemberInfo,
  onlineAdmNotificationInfo,
  admProgAppliedThroughInfo,
  acadCombListForOnlineAdm,
  studInfoForOnlineAdm
} from "./onlineCandidateForm";

export default combineReducers({
  onlineAdmCourseInfo,
  onlineAdmSectionInfo,
  onlineAdmissionConfigInfo,
  onlineAdmFormDetails,
  onlineAdmProgramDetails,
  onlineAdmDocumentDetails,
  onlineAdmRegstMemberInfo,
  onlineAdmNotificationInfo,
  admProgAppliedThroughInfo,
  acadCombListForOnlineAdm,
  studInfoForOnlineAdm
});
