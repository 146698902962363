import { GET_EXAM_WEIGHTAGE_CONFIGURATION } from "../../actions/types";

const initialState = [];

export const examWeightageConfiguration = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_EXAM_WEIGHTAGE_CONFIGURATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
