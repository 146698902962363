import {
  GET_ALL_ORGANIZATION_USERLIST,
  GET_ALL_ORGANIZATION_STUDENTLIST,
  GET_ALL_COUNTRIES,
  GET_PHYSICALLY_CHALLENGED_CATEGORY,
  GET_EMPLOYEE_PERSONAL_INFO,
  GET_EMPLOYEE_CONTACTINFO_BY_USERID,
  GET_EMPLOYEE_FAMILYDETAIL_BY_USERID,
  GET_ALL_RELATIONLIST,
  GET_EMPLOYEE_QUALIFICATION_BY_USERID,
  GET_EMPLOYEE_EXPERIENCE_BY_USERID,
  GET_ALL_QUALIFICATIONTYPE_LIST,
  GET_EMPLOYEE_DOCUMENTDETAILS_BY_USERID,
  GET_ALL_DOCUMENTTYPE_LIST,
  GET_EMPLOYEE_BANKDETAILS_BY_USERID,
  GET_EMPLOYEE_VISADETAILS_BY_USERID,
  GET_ALL_LANGUAGE_LIST,
  GET_EMPLOYEE_KNOWN_LANGUAGES,
  GET_ALL_APPOINTMENTTYPE_LIST,
  GET_ALL_BROAD_DISCIPLINECATEGORY_LIST,
  GET_ALL_BROAD_DISCIPLINEGROUP_LIST,
  GET_EMPLOYEE_BASICDETAILS_BY_USERID,
  GET_ALL_ACADE_EMPLOYEE_BASICDETAILS_BY_USERID,
  GET_USER_IMPORTHISTORY,
  IMPORT_USER_RESPONSE,
  GET_ALL_STAFF_INFO_FOR_ICARD,
  GET_ALL_DEPT_DESGN_BY_ORGID_USERTYPEID
} from "../../actions/types";

const initialState = [];

export const allOrganizationUserList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ORGANIZATION_USERLIST:
      return action.payload;
    default:
      return state;
  }
};

export const allOrganizationStudentList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_ORGANIZATION_STUDENTLIST:
      return action.payload;
    default:
      return state;
  }
};

export const allCountryList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COUNTRIES:
      return action.payload;
    default:
      return state;
  }
};

export const physicalChallengeCategoryList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_PHYSICALLY_CHALLENGED_CATEGORY:
      return action.payload;
    default:
      return state;
  }
};

export const employeePersonalInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_PERSONAL_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const employeeContactInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_CONTACTINFO_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const employeeFamilyInfo = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_FAMILYDETAIL_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const relationList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_RELATIONLIST:
      return action.payload;
    default:
      return state;
  }
};

export const employeeQualificationsDetails = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_EMPLOYEE_QUALIFICATION_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const qualificationTypeList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_QUALIFICATIONTYPE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const employeeExperienceDetails = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_EMPLOYEE_EXPERIENCE_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const employeeDocumentDetails = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_DOCUMENTDETAILS_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const documentTypeList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_DOCUMENTTYPE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const employeeBankDetails = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_BANKDETAILS_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const employeeVisaDetails = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_VISADETAILS_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const allLanguageList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LANGUAGE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const employeeLanguageDetails = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_KNOWN_LANGUAGES:
      return action.payload;
    default:
      return state;
  }
};

export const appointmentTypeList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_APPOINTMENTTYPE_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const broadDisciplineCategoryList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_BROAD_DISCIPLINECATEGORY_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const broadDisciplineGroupList = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BROAD_DISCIPLINEGROUP_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const employeeBasicDetails = function(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_BASICDETAILS_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};
export const employeeAcadeBasicDetails = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ALL_ACADE_EMPLOYEE_BASICDETAILS_BY_USERID:
      return action.payload;
    default:
      return state;
  }
};

export const userImportHistory = function(state = initialState, action) {
  switch (action.type) {
    case GET_USER_IMPORTHISTORY:
      return action.payload;
    default:
      return state;
  }
};

export const importUserResponse = function(state = initialState, action) {
  switch (action.type) {
    case IMPORT_USER_RESPONSE:
      return action.payload;
    default:
      return state;
  }
};

export const staffListForIcard = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STAFF_INFO_FOR_ICARD:
      return action.payload;
    default:
      return state;
  }
};

export const deptDesgn_ByOrgIdUserTypeId = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DEPT_DESGN_BY_ORGID_USERTYPEID:
      return action.payload;
    default:
      return state;
  }
};
