import {
  GET_ID_SEMESTERTYPE,
  GET_ID_SUBJECT,
  GET_ID_PAPER,
  GET_ID_STUDENT_MARKS_DETAIL_COUNT,
  GET_ID_STUDENT_MARKS_DETAIL,
  GET_ID_STUDENT_MARKS_HEADER_DETAIL,
  GET_ID_PROGRAM_GRADE_LIST
} from "../../actions/exams/types";

const initialState = {};

export const getIdSemesterType = function(state = initialState, action) {
  switch (action.type) {
    case GET_ID_SEMESTERTYPE:
      return action.payload;
    default:
      return state;
  }
};

export const getIdSubject = function(state = initialState, action) {
  switch (action.type) {
    case GET_ID_SUBJECT:
      return action.payload;
    default:
      return state;
  }
};

export const getIdPaper = function(state = initialState, action) {
  switch (action.type) {
    case GET_ID_PAPER:
      return action.payload;
    default:
      return state;
  }
};

export const getIdStudentMarksDetailCount = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ID_STUDENT_MARKS_DETAIL_COUNT:
      return action.payload;
    default:
      return state;
  }
};

export const getIdStudentMarksDetail = function(state = initialState, action) {
  switch (action.type) {
    case GET_ID_STUDENT_MARKS_DETAIL:
      return action.payload;
    default:
      return state;
  }
};

export const getIdStudentMarksHeaderDetail = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ID_STUDENT_MARKS_HEADER_DETAIL:
      return action.payload;
    default:
      return state;
  }
};


export const getIdProgramGradeList = function(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_ID_PROGRAM_GRADE_LIST:
      return action.payload;
    default:
      return state;
  }
};