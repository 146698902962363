import {
  GET_STUDENT_LIST_FOR_NOTIFICATION,
  GET_COUNSEL_LIST,
  GET_COUNSEL_NOTIF_HISTORY,
  GET_COUNSEL_DETAIL
} from "../../../actions/masters/Admissions/types";

const initialState = [];
//const initialState1 = {};

export function getStudentListForNotification(state = initialState, action) {
  switch (action.type) {
    case GET_STUDENT_LIST_FOR_NOTIFICATION:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getCounselList(state = initialState, action) {
  switch (action.type) {
    case GET_COUNSEL_LIST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getCounselingNotificationHistory(state = initialState, action) {
  switch (action.type) {
    case GET_COUNSEL_NOTIF_HISTORY:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
}

export function getCounselDetail(state = initialState, action) {
  switch (action.type) {
    case GET_COUNSEL_DETAIL:
      return action.payload;
    default:
      return state;
  }
}
