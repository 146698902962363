import { TIME_OVERLAP } from "../../actions/attendance/types";

const initialState = {};

export const timeOverLap = (state = initialState, action) => {
  switch (action.type) {
    case TIME_OVERLAP:
      return action.payload;
    default:
      return state;
  }
};
