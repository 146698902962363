import {
  GET_ALL_ALUMNI_FEES_CONFIGURATION,
  GET_ALL_COURSE_INFO,
  GET_ALL_ALUMNI_FEES_TYPES,
  GET_STUD_INFO_BY_ENROLLNO,
  GET_ACADYEAR_AND_GENDER_LIST,
  GET_ALUMNI_FEEDBACK_PARAMETERS,
  GET_ALUMNI_REGISTRATION_DATA_BY_ID,
  GET_ALUMNI_REGISTERED_MEMBERS_INFO,
  GET_COUNSEL_NOTIFICATION_FOR_ALUMNI_MGMT,
  GET_ALUMNI_ADDITIONAL_COURSE_DETAILS_AT_SUMANVIDYAP,
  GET_ALUMNI_ADD_COURSE_DETAILS_AT_OTHER_ORG,
  GET_ALUMNI_PLACEMENT_DETAILS,
  GET_ALUMNI_QUALIFYING_EXAM_DETAILS,
  GET_ALUMNI_ACHIEVEMENT_DETAILS,
  GET_ALUMNI_CONTRIBUTION_DETAILS,
  GET_CONTRIBUTION_TYPES,
  GET_OUTCOME_MASTER_INFO,
  CHECK_ALUMNI_FEEDBACK_INFO,
  GET_ALUMNI_DETAILS,
  GET_ALUMNI_FEEDBACK_INFO_REPORT,
  GET_ALUMNI_COUNCIL_REGISTRATION_CERTIFICATES_DETAILS,
  CHECK_DUPLICATE_ALUMNI_ID_ADMIN
} from "../../actions/types";

const initialState = [];

export const alumniFeesConfiguration = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ALUMNI_FEES_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
};

export const courseInfoForAlumni = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COURSE_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const alumniFeesTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ALUMNI_FEES_TYPES:
      return action.payload;
    default:
      return state;
  }
};

export const studDetailsByEnrollNo = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUD_INFO_BY_ENROLLNO:
      return action.payload;
    default:
      return state;
  }
};

export const genderAcadYrList = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACADYEAR_AND_GENDER_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const alumniFeedbackParameters = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_FEEDBACK_PARAMETERS:
      return action.payload;
    default:
      return state;
  }
};

export const alumniRegstInfoById = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_REGISTRATION_DATA_BY_ID:
      return action.payload;
    default:
      return state;
  }
};

export const alumniRegisteredMemberInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_REGISTERED_MEMBERS_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const alumniCounselNotiHistory = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNSEL_NOTIFICATION_FOR_ALUMNI_MGMT:
      return action.payload;
    default:
      return state;
  }
};

export const alumniAddCourseDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_ADDITIONAL_COURSE_DETAILS_AT_SUMANVIDYAP:
      return action.payload;
    default:
      return state;
  }
};

export const alumniAddCourseInfoAtOtherOrg = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_ADD_COURSE_DETAILS_AT_OTHER_ORG:
      return action.payload;
    default:
      return state;
  }
};

export const alumniPlacementInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_PLACEMENT_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const alumniQualExamInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_QUALIFYING_EXAM_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const alumniAchievementsInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_ACHIEVEMENT_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const alumniContributionInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_CONTRIBUTION_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const contributionTypes = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRIBUTION_TYPES:
      return action.payload;
    default:
      return state;
  }
};

export const outcomeMasterInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTCOME_MASTER_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const chkAlumniFeedbackInfo = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_ALUMNI_FEEDBACK_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const alumniUserDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const alumniFeedbackDetailReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_FEEDBACK_INFO_REPORT:
      return action.payload;
    default:
      return state;
  }
};

export const alumniCouncilRegstInfo = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALUMNI_COUNCIL_REGISTRATION_CERTIFICATES_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const chkDupctAlumniId = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_DUPLICATE_ALUMNI_ID_ADMIN:
      return action.payload;
    default:
      return state;
  }
};
