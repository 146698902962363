import { combineReducers } from "redux";
import update from "./updateData";
import masters from "./masters";
import commons from "./commons";

export default combineReducers({
  update,
  commons,
  masters
});
