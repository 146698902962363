import { GET_REEXAM_LIST, GET_ALL_REEXAM } from "../../actions/exams/types";

const initialState = [];

export const reExam = function(state = initialState, action) {
  switch (action.type) {
    case GET_REEXAM_LIST:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};

export const getAllReExam = function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REEXAM:
      return Array.isArray(action.payload) ? action.payload : state;
    default:
      return state;
  }
};
