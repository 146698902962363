import {
  GET_ALL_USERS_INFO_FOR_ESTABLISTMENT_MST,
  GET_ALL_ESTABLISHMENT_MST_INFO,
  GET_ALL_ESTAB_SANCTIONED_POST_DETAILS,
  GET_ALL_ESTAB_POLICY_DETAILS,
  GET_ALL_ESTAB_SOP_DETAILS,
  GET_ALL_ESTAB_DOCUMENT_DETAILS,
  GET_ALL_ESTAB_POSTS_PRIVILEGES_DETAILS,
  GET_ALL_ESTAB_SANC_EQUIPMENT_DETAILS,
  GET_ALL_REQ_SANCT_POST_DETAILS,
  GET_ALL_REQ_NON_SANCT_POST_DETAILS,
  GET_ALL_ESTAB_VACANT_POST_DATA
} from "../../actions/types";

const initialState = [];

export const usersListForEstabMst = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_INFO_FOR_ESTABLISTMENT_MST:
      return action.payload;
    default:
      return state;
  }
};

export const establishmentMaster = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTABLISHMENT_MST_INFO:
      return action.payload;
    default:
      return state;
  }
};

export const estSanctPostDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTAB_SANCTIONED_POST_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estPolicyDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTAB_POLICY_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estSOPDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTAB_SOP_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estDocumentDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTAB_DOCUMENT_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estPostPrivilegesDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTAB_POSTS_PRIVILEGES_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estSanctEquipmentDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTAB_SANC_EQUIPMENT_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estReqSanctPostDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQ_SANCT_POST_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estReqNonSanctPostDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REQ_NON_SANCT_POST_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const estVacantPostDetails = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ESTAB_VACANT_POST_DATA:
      return action.payload;
    default:
      return state;
  }
};
